import React, { memo, useState, useEffect } from 'react';
import { Card, List, Popover, Typography } from "antd";
import IntlMessages from "../../../util/IntlMessages";

import Settings from "../../../assets/svg/Settings";
import StyledTooltip from "../../../components/StyledTooltip";
import StyledMenuOptions from "../../../components/StyledMenuOptions";
import Edit from "../../../assets/svg/Edit";
import Delete from "../../../assets/svg/Delete";

import { CardItem } from './CardItem';


const { Text } = Typography;

const SideCard = (props) => {

  const { items, title, style, children, activeAgents, onClick } = props;
  const [filteredListItems, setFilteredListItems] = useState([])

  useEffect(() => {

    if (items) {

      setFilteredListItems(items.map(item => {
        let isActive = false;
        let agentInfo = {};
  
        if (activeAgents && activeAgents.hasOwnProperty(item.device_access_token)) {
  
          isActive = activeAgents[item.device_access_token]?.apps?.find(app => {
            return app.title === item.product
          })
          agentInfo = activeAgents[item.device_access_token];
        }
  
        return {
          ...item,
          ...agentInfo,
          status: isActive? "pcOn": false,
        }
      }))
    }
   
  }, [items, activeAgents])


  const menuOptions = (
    <StyledMenuOptions
      options={[
        {
          icon: <Edit />,
          label: <IntlMessages id="home.pc.edit" />,
        },
        {
          icon: <Delete />,
          label: <IntlMessages id="home.pc.delete" />
        }
      ]}
      onClick={(option) => {
        console.log('option', option)
      }}
    />
  );

  const MoreComponent = memo(({ }) => (
    <div style={{
      cursor: 'pointer',
    }}>

      <StyledTooltip content={menuOptions}>
        <Settings />
      </StyledTooltip>
      {/*<a data-tooltip-id="my-tooltip">*/}
      {/*  <Settings />*/}
      {/*</a>*/}
      {/*<Tooltip*/}
      {/*  id={"my-tooltip"}*/}
      {/*  border="1px solid #B9B9B9"*/}
      {/*  place={'bottom'}*/}
      {/*  openOnClick*/}
      {/*  closeEvents={{*/}
      {/*    click: true,*/}
      {/*  }}*/}
      {/*  globalCloseEvents={{*/}
      {/*    escape: true,*/}
      {/*    clickOutsideAnchor: true,*/}
      {/*    scroll: true,*/}
      {/*    resize: true,*/}
      {/*  }}*/}
      {/*  style={{*/}
      {/*    backgroundColor: '#FFFFFF',*/}
      {/*    color: '#4F4F4F',*/}
      {/*  }}*/}
      {/*  // content={menuOptions}*/}
      {/*  // trigger="click">*/}
      {/*>*/}
      {/*  {menuOptions}*/}
      {/*</Tooltip>*/}
    </div>
  ))

  return (
    <Card
      // hoverable
      bordered
      style={{
        borderRadius: 6,
        height: 136,
        backgroundColor: '#FFFFFF',
        padding: 10,
        paddingLeft: 14,
        ...style,
      }}
      bodyStyle={{ padding: 0, height: "100%" }}
    >

      <div style={{
        display: "flex",
        // flex: 1,
        flexDirection: "column",
        height: "100%",
      }}>
        <div style={{ display: "flex", height: 44, justifyContent: "space-between" }}>
          <Text
            style={{
              fontSize: 20,
              color: '#181818',
              paddingRight: 32,
            }}
          >
            {title}
          </Text>
          {/* <MoreComponent /> */}
        </div>
        <div style={{ display: "flex", flex: 1, flexDirection: "column", height: "calc(100% - 44px)", overflow: "auto" }}>
          {filteredListItems.map((item, idx) => {
            return <CardItem key={"cardItem"+idx} data={item} onClick={onClick}/>
          })}
        </div>
        {/* 
          <ConnectButtonComponent agent={item}/> */}
      </div>
    </Card>
  );
};

export default SideCard;
