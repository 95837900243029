import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Layout} from "antd";

import SidebarContent from "./SidebarContent";
import {
  NAV_STYLE_MINI_SIDEBAR
} from "../../constants/ThemeSetting";

const {Sider} = Layout;

const Sidebar = () => {
  let [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const {navStyle} = useSelector(({settings}) => settings);

  useEffect(() => {
    setSidebarCollapsed(navStyle === NAV_STYLE_MINI_SIDEBAR)
  }, [navStyle])

  return (
    <Sider
      trigger={null}
      theme="light"
      width={264}
      collapsedWidth={40}
      collapsed={sidebarCollapsed}
      collapsible
      style={{boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)"}}
      >
        <SidebarContent sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
    </Sider>
    )
};
export default Sidebar;
