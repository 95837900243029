import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.1578 4.31547C11.5512 3.70105 12.4488 3.70105 12.8422 4.31547L14.852 7.45464C14.9876 7.66653 15.1982 7.8195 15.4416 7.88304L19.0482 8.82438C19.7541 9.00863 20.0315 9.86234 19.5687 10.4263L17.2042 13.3078C17.0446 13.5023 16.9642 13.7498 16.979 14.0009L17.1982 17.7219C17.2411 18.4502 16.5149 18.9778 15.8355 18.7119L12.3644 17.3536C12.1301 17.2619 11.8699 17.2619 11.6356 17.3536L8.16449 18.7119C7.4851 18.9778 6.75889 18.4502 6.8018 17.7219L7.02103 14.0009C7.03583 13.7498 6.95541 13.5023 6.79581 13.3078L4.43132 10.4263C3.96853 9.86234 4.24592 9.00863 4.95182 8.82438L8.55841 7.88304C8.80185 7.8195 9.01239 7.66653 9.14805 7.45464L11.1578 4.31547Z"
      fill="#F9C135"
    />
  </svg>
);
export default SVGComponent;