import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Row, Col, Input, Space, Select, Tag, Form, DatePicker, Button } from "antd";
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

import AtomicPopup from "src/components/AtomicPopup";
import CircularProgress3 from 'src/components/CircularProgress3';
import StyledUserCardItem from 'src/components/StyledUserCardItem';

import beApis from "src/util/apis/be";
import IntlMessages from "src/util/IntlMessages";
import AtomicForm from 'src/components/AtomicForm';
import AtomicButton from 'src/components/AtomicButton';
import { DATE_FORMAT, getProductTagColors } from 'src/util/common';
import { usePopup } from "src/util/use-popup";

const AccountPopup = ({ selectedUser, permissionList, onOk, onCancel }) => {

  const csSelected = useSelector(({ cs }) => cs.csSelected);
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [form] = Form.useForm();
  const { confirmPopup, infoPopup } = usePopup();

  dayjs.extend(weekday);
  dayjs.extend(localeData);

  useEffect(() => {
    const clonedInfo = Object.assign({}, selectedUser);

    if (selectedUser.name) {

      clonedInfo.start_date = dayjs(selectedUser.start_date);
      clonedInfo.end_date = dayjs(selectedUser.end_date);
    }

    form.setFieldsValue(clonedInfo)
  }, [selectedUser])

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color={getProductTagColors(value)}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
        }}
      >
        {label}
      </Tag>
    );
  };

  const handleAdd = () => {
    form.validateFields().then((values) => {
      const SEND_FORMAT = "YYYY.MM.DD";
      values.start_date = values.start_date.format(SEND_FORMAT);
      values.end_date = values.end_date.format(SEND_FORMAT);
      beApis.addSubUser(csSelected.host, values).then((resp) => {
        infoPopup(intl.formatMessage({id: "vlink.common.added"}), () => onOk())
      }).catch((e) => {
        console.log("에러발생", e)
      })
    }).catch((errorInfo) => {});
  

  }
  const handleUpdate = () => {
    form.validateFields().then((values) => {
      const SEND_FORMAT = "YYYY.MM.DD";
      values.start_date = values.start_date.format(SEND_FORMAT);
      values.end_date = values.end_date.format(SEND_FORMAT);
      beApis.updateSubUser(csSelected.host, selectedUser.id, values).then((resp) => {
        infoPopup(intl.formatMessage({id: "vlink.common.updated"}), () => onOk())
      }).catch((e) => {
        console.log("에러발생", e)
      })
  
    }).catch((errorInfo) => {});
  }
  const handleDelete = () => {
    confirmPopup(intl.formatMessage({id: "vlink.common.confirm.delete"}), () => {
      beApis.deleteSubUser(csSelected.host, selectedUser.id).then((resp) => {
        infoPopup(intl.formatMessage({id: "vlink.common.deleted"}), () => onOk())
      }).catch((e) => {
        console.log("에러발생", e)
      })
    } )
   
  }

  const handleReset = () => {
    confirmPopup(intl.formatMessage({id: "vlink.common.confirm.reset"}), () => {
      beApis.resetPinSubUser(csSelected.host, selectedUser.id).then((resp) => {
        infoPopup(intl.formatMessage({id: "vlink.common.reset"}), () => onOk())
      }).catch((e) => {
        console.log("에러발생", e)
      })
    } )

  }

  return <AtomicPopup
    title={
      <span>
        {selectedUser?.name ?
          <IntlMessages id="vlink.settings.account.update" />
          :
          <IntlMessages id="vlink.settings.account.add" />

        }
      </span>
    }
    open={true}
    onOk={() => {
      onOk && onOk();
    }}
    onCancel={() => {
      onCancel && onCancel()
    }}
    centered
    footer={null}
    width={575}
  >
    <div style={{ padding: "16px 0 24px 0" }}>
      <AtomicForm form={form} disabled={selectedUser?.is_host}>
        <Row className='row-has-1cols'>
          <Col >
            <Form.Item label={intl.formatMessage({ id: "vlink.settings.account.name" })} name="name" rules={[{required: true}]}>
              <Input style={{ height: 36 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row className='row-has-1cols'>
          <Col >
            <Form.Item label={intl.formatMessage({ id: "vlink.settings.account.permission" })} name="product_permissions" rules={[{required: true}]} >
              <Select style={{ height: 36 }} options={permissionList.map((item) => ({ label: item, value: item }))} mode="multiple" tagRender={tagRender} />
            </Form.Item>
          </Col>
        </Row>

        <Row className='row-has-1cols'>
          <Col >
            <Form.Item label={intl.formatMessage({ id: "vlink.settings.account.hasPin" })} >
              <AtomicButton disabled={!selectedUser.has_pin} 
                type="default" 
                label={<IntlMessages id="vlink.settings.account.reset"/>} 
                onClick={handleReset}
                />
                  
            </Form.Item>
          </Col>
        </Row>

        <Row className='row-has-1cols'>
          <Col >
            <Form.Item label={intl.formatMessage({ id: "vlink.settings.account.startDate2" })} name="start_date" rules={[{required: true}]}>
              <DatePicker format={DATE_FORMAT} style={{ height: 36 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row className='row-has-1cols'>
          <Col >
            <Form.Item label={intl.formatMessage({ id: "vlink.settings.account.endDate2" })} name="end_date" rules={[{required: true}]}>
              <DatePicker style={{ height: 36 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ justifyContent: "flex-end", marginTop: 20 }} className="popup-button-row">
          {selectedUser?.name ?
            <Space>
              <AtomicButton onClick={handleDelete} label={intl.formatMessage({ id: "vlink.button.delete" })} type="default" />
              <AtomicButton onClick={handleUpdate} label={intl.formatMessage({ id: "vlink.button.update" })} type="default" />
            </Space>
            :
            <Space>
              <AtomicButton onClick={onCancel} label={intl.formatMessage({ id: "vlink.button.cancel" })} type="default" />
              <AtomicButton onClick={handleAdd} label={intl.formatMessage({ id: "vlink.button.add" })} type="primary" />
            </Space>

          }
        </Row>

      </AtomicForm>
      {isLoading && <CircularProgress3 />}
    </div>


  </AtomicPopup>
}

export default AccountPopup;
