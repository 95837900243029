import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, Checkbox, Flex, Form, Input, Typography, Modal, Row, Steps } from "antd";
import { useIntl } from "react-intl";
import { Buffer } from 'buffer';

import IntlMessages from "src/util/IntlMessages";
import { useAuth } from "src/authentication";
import CircularProgress from "src/components/CircularProgress";
import beApis from "../../../util/apis/be";
import CircularProgress2 from "src/components/CircularProgress2";
import { usePopup } from "src/util/use-popup";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import "./index.css";
import { setXdata } from "src/appRedux/actions";

const { info, error } = Modal;

const SignUpProcess = (props) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const locale = useSelector(({ settings }) => settings.locale);
  const xData = useSelector(({ common }) => common.xData);

  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [focus, setFocus] = useState(false);

  const [agreementIds, setAgreementIds] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [validateUUID, setValidateUUID] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const [step, setStep] = useState(0);

  const csSelected = useSelector(({ cs }) => cs.csSelected);
  const navigate = useNavigate();
  const { errorPopup, infoPopup } = usePopup();

  const location = useLocation();
  const param = useParams();

  useEffect(() => {
    setFocus(true);
  }, [])

  useEffect(() => {
    if (focus && xData) {
      if (xData && xData.etc) {
        setUserInfo(xData);

        form.setFieldsValue({
          ...xData,
          clinic_name: xData.etc.clinic_name

        })
        if (xData.email) {
          setValidateUUID(true);
        }

        dispatch(setXdata({}));
      }
    }
  }, [focus])




  const handleSave = (values) => {

    const req = {
      ...values,
      agreements: agreementIds
    }

    beApis.signUp(csSelected.host, req).then((resp) => {
      setStep(2);
      // infoPopup(intl.formatMessage({ id: "vlink.signup.successFinal" }), () => navigate("/user/signin"))
    }).catch((e) => {
      errorPopup(intl.formatMessage({ id: "vlink.signup.failFinal" }), () => navigate("/user/signin"))

    })
  }

  const stepItems = [
    { title: intl.formatMessage({ id: "vlink.signup.step1" }) },
    { title: intl.formatMessage({ id: "vlink.signup.step2" }) },
    { title: intl.formatMessage({ id: "vlink.signup.step3" }) },
  ]



  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container" >
        {!validateUUID ?
          <></>
          :
          <div className="gx-app-login-main-content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 678, padding: "70px 0" }}>
            <Steps items={stepItems} current={step} labelPlacement="vertical" style={{ padding: "0 50px" }} />
            {step == 0 ?
              <Step1 onOk={(terms, privacy) => { setAgreementIds([{ id: terms }, { id: privacy }]); setStep(1) }} />
              :
              step == 1 ?
                <Step2 userInfo={userInfo} onOk={handleSave} onCancel={() => setStep(0)} />
                :
                <Step3 email={userInfo.email} />

            }
          </div>
        }
      </div>
    </div>
  );
};

export default SignUpProcess;
