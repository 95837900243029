import React from "react";
import "./Cursor.css";
import useMousePosition from "../../util/useMousePosition";

const Cursor = (props) => {
  const { x, y } = useMousePosition(props.parentRef);

  return (
      <div
        className={"dot"}
        style={{ left: `${x}px`, top: `${y}px` }}
      ></div>
  );
};

export default Cursor;
