import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={2.5} y={2.5} width={15} height={15} rx={7.5} stroke="#DF043A" />
    <path
      d="M9.99984 1.66699C14.6023 1.66699 18.3332 5.39783 18.3332 10.0003C18.3332 14.6028 14.6023 18.3337 9.99984 18.3337C5.39734 18.3337 1.6665 14.6028 1.6665 10.0003C1.6665 5.39783 5.39734 1.66699 9.99984 1.66699ZM9.9915 8.33366H9.1665C8.9541 8.33389 8.74981 8.41522 8.59536 8.56103C8.44092 8.70684 8.34797 8.90612 8.33553 9.11815C8.32308 9.33019 8.39207 9.53897 8.52839 9.70185C8.66472 9.86473 8.85809 9.96941 9.069 9.99449L9.1665 10.0003V14.1587C9.1665 14.592 9.49484 14.9503 9.9165 14.9953L10.0082 15.0003H10.4165C10.5918 15.0003 10.7626 14.9451 10.9046 14.8424C11.0467 14.7398 11.1527 14.5949 11.2077 14.4285C11.2628 14.2621 11.2639 14.0826 11.211 13.9155C11.1581 13.7484 11.0539 13.6023 10.9132 13.4978L10.8332 13.4453V9.17533C10.8332 8.74199 10.5048 8.38366 10.0832 8.33866L9.9915 8.33366ZM9.99984 5.83366C9.77882 5.83366 9.56686 5.92146 9.41058 6.07774C9.2543 6.23402 9.1665 6.44598 9.1665 6.66699C9.1665 6.88801 9.2543 7.09997 9.41058 7.25625C9.56686 7.41253 9.77882 7.50033 9.99984 7.50033C10.2209 7.50033 10.4328 7.41253 10.5891 7.25625C10.7454 7.09997 10.8332 6.88801 10.8332 6.66699C10.8332 6.44598 10.7454 6.23402 10.5891 6.07774C10.4328 5.92146 10.2209 5.83366 9.99984 5.83366Z"
      fill="#DF043A"
    />
  </svg>
);
export default SVGComponent;
