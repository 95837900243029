import enLang from "./entries/en_US";
import arLang from "./entries/ar_EG";
import zhCnLang from "./entries/zh_CN";
import zhHkLang from "./entries/zh_HK";
import frLang from "./entries/fr_FR";
import deLang from "./entries/de_DE";
import itLang from "./entries/it_IT";
import jpLang from "./entries/ja_JP";
import krLang from "./entries/ko_KR";
import ptLang from "./entries/pt_BR";
import ruLang from "./entries/ru_RU";
import esLang from "./entries/es_ES";

const AppLocale = {
  'en-US': enLang,
  'ar-EG': arLang,
  'zh-CN': zhCnLang,
  'zh-HK': zhHkLang,
  'fr-FR': frLang,
  'de-DE': deLang,
  'it-IT': itLang,
  'ja-JP': jpLang,
  'ko-KR': krLang,
  'pt-BR': ptLang,
  'ru-RU': ruLang,
  'es-ES': esLang,
};

export default AppLocale;
