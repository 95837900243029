import React, {Fragment, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  updateAgentApps,
  updateAgentConnections,
  updateDataChannels,
  updatePermissionResult,
  updateResolution,
  updateSubscribeAgentApps,
  updateSubscribeDataChannels,
  updateSwitchApp,
  updateWsSend,
} from "../../../appRedux/actions";
// import {updateWsSend} from "redux/actions/WS";
// import {getPreciseStringValue} from "../../../../util/common";
import {v4} from "uuid";
import {getPreciseStringValue, sendDC} from "../../../util/common";


const DataChannel = ({
                       agent,
                       agentIndex,
                       onClose,
                       onOpenSC,
                     }) => {

  const csSelected = useSelector(({cs}) => cs.csSelected);
  const dataChannels = useSelector(({webrtc}) => webrtc.dataChannels);
  const agentApps = useSelector(({webrtc}) => webrtc.agentApps);
  const agentConnections = useSelector(({webrtc}) => webrtc.agentConnections);
  const wsReceive = useSelector(({ws}) => ws.wsReceive);

  const peerRef = useRef(undefined);
  const dispatch = useDispatch();
  const refOpponent = useRef({
    opponentDAT: '',
    dataConnection: undefined,
    streamConnection: undefined,
    dataChannel: undefined,
    isMouseDown: false,
    dataChannelLabel: '',
    iceServers: {},
    chunk: undefined,
  })
  const refApps = useRef({})
  const refDataChannels = useRef({})

  useEffect(() => {

    // updateWsSend({
    //     data: {
    //         category: 'ws',
    //         service: 'StartNegotiation',
    //         access_token: session.access_token,
    //         channel_type: 'data',
    //         label: label,
    //         device_access_token: session.device_access_token,
    //         opponent_dat: opponentDat,
    //     },
    // });
    // console.log('DataChannel - SendWS', agent)

    const access_info = JSON.parse(sessionStorage.getItem('access_info'));
    let accessToken = '';
    let deviceAccessToken = '';
    if (access_info) {
      accessToken = access_info.access_token;
      deviceAccessToken = access_info.device_access_token
    }
    refOpponent.current.opponentDAT = agent.device_access_token

    // 다른 에이전트들과 동시에 실행되서 사라질 수 있음.
    // 웹소켓 데이터를 동시에 2개 업데이트하면서 하나가 사라짐
    // TODO: 근본적인 해결책은 없을까? Message Queue 같은 거...
    // 일단 임시방편으로 동시 실행하는 애들은 setTimeout 을 통해서 각자 딜레이 타임을 주도록 한다.

    setTimeout(() => {
      sendWS({
        data: {
          category: 'ws',
          service: 'StartToLive',
          access_token: accessToken,
          device_access_token: deviceAccessToken,
          opponent_dat: refOpponent.current.opponentDAT,
          channel_type: "data",
        }
      });
    }, agentIndex * 100)
    return () => {
      // console.log('SWS-DEBUG-DataChannel disappeared', agent.name)
    }
  }, [])

  useEffect(() => {

    // console.log('DataChannel - 001', agent.name)

  }, [agent]);

  useEffect(() => {
    const payload = wsReceive
    // console.log("SWS-DEBUG-wsReceive", wsReceive);

    if (payload) {
      const service = payload.service;
      // console.log("SWS-DEBUG-onmessage", payload);
      if (service === 'Answer' && payload.device_access_token === refOpponent.current.opponentDAT) {
        // console.log("onmessageWS - 2",  payload);
        if (payload.channel_type === 'stream') {
          onAnswerStream(payload.sdp);
        } else {
          onAnswerData(payload.sdp);
        }
      } else if (service === 'Candidate' && payload.device_access_token === refOpponent.current.opponentDAT) {
        if (payload.channel_type === 'stream') {
          setTimeout(function () {
            onCandidateStream(payload.candidate.candidate);
          }, 1000);
        } else {
          setTimeout(function () {
            onCandidateData(payload.candidate.candidate);
          }, 1000);
        }
      } else if (service === 'Offer' && payload.opponent_dat === refOpponent.current.opponentDAT) {
        createAnswerData(payload.sdp, payload.label);
      }
    }
  }, [wsReceive])

  useEffect(() => {
    if (csSelected) {
      refOpponent.current.iceServers = {
        iceServers: csSelected.ice_servers.reduce((acc, cur, idx) => {
          acc.push({
            ...cur,
            credential: process.env.REACT_APP_ICE_SERVER_CREDENTIAL,
          })
          return acc
        }, [])
      }
    }
  }, [csSelected])

  useEffect(() => {
    // console.log('agentApps', agentApps)
    Object.keys(agentApps).map(k => {
      refApps.current[k] = agentApps[k]
    })
    if (refApps.current) {
      dispatch(updateSubscribeAgentApps({
        ...refApps.current,
      }))
    }
    // if (refApps.current) {
    //     dispatch(updateSubscribeAgentApps({
    //         ...agentApps,
    //         [refOpponent.current.opponentDAT]: refApps.current,
    //     }))
    // }
  }, [agentApps])

  useEffect(() => {
    Object.keys(dataChannels).map(k => {
      refDataChannels.current[k] = dataChannels[k]
    })
    if (refDataChannels.current) {
      dispatch(updateSubscribeDataChannels({
        ...refDataChannels.current,
      }))
    }
  }, [dataChannels])

  const sendWS = (msg) => {
    // console.log('SWS-DEBUG-updateWsSend', msg)
    setTimeout(() => {
      dispatch(updateWsSend(msg))
    }, 55)
  }

  const setupPeerConnection = () => {
    const iceServers = refOpponent.current.iceServers;
    const access_info = JSON.parse(sessionStorage.getItem('access_info'));
    let accessToken = '';
    let deviceAccessToken = '';
    if (access_info) {
      accessToken = access_info.access_token;
      deviceAccessToken = access_info.device_access_token;
    }

    refOpponent.current.streamConnection = new RTCPeerConnection(iceServers)
    refDataChannels.current[refOpponent.current.opponentDAT].streamConnection = refOpponent.current.streamConnection

    // let ua = navigator.userAgent.toLowerCase();
    // console.log(ua);
    // if (ua.indexOf('safari') !== -1) {
    //     if (ua.indexOf('chrome') > -1) {
    //         // Chrome
    //         this.streamConnection = new RTCPeerConnection(iceServers)
    //     } else {
    //         // Safari
    //         this.streamConnection = new RTCPeerConnection({
    //             iceServers: [
    //                 {
    //                     "urls": "stun:stun.l.google.com:19302"
    //                 }
    //             ]
    //         });
    //     }
    // } else {
    //     this.streamConnection = new RTCPeerConnection(iceServers)
    // }
    // console.log('SWS', 'stream', this.streamConnection);

    refOpponent.current.streamConnection.onclose = (e) => {
      // console.log('[streamConnection]', 'onclose', agent.name);
    };
    refOpponent.current.streamConnection.ontrack = (e) => {
      const base = document.getElementById('viewer-base');
      const video = document.getElementById('app-video');
      if (video.srcObject !== e.streams[0]) {
        video.srcObject = e.streams[0];
        // console.log('received remote stream');
        video.addEventListener('loadedmetadata', function () {
          // console.log(`Remote video videoWidth: ${this.videoWidth}px,  videoHeight: ${this.videoHeight}px`);
        });
        video.onresize = () => {
          // console.log(`Remote video size changed to ${video.videoWidth}x${video.videoHeight}`);
          // We'll use the first onsize callback as an indication that video has started
          // playing out.
        };
        video.addEventListener('mousedown', function (event) {
          const rect = video.getBoundingClientRect()
          const x = event.clientX - rect.left;
          const y = event.clientY - rect.top;
          // console.log("mousedown", "x: " + x + " y: " + y);
          // console.log("width: " + rect.width + " height: " + rect.height);
          if (event.button === 0) {
            const message = JSON.stringify({
              command: 'mouse_down',
              x: getPreciseStringValue(x / rect.width),
              y: getPreciseStringValue(y / rect.height),
            });
            // console.log(message);
            refOpponent.current.dataChannel && refOpponent.current.dataChannel.send(message);
            refOpponent.current.isMouseDown = true
          }
        });
        video.addEventListener('mouseup', function (event) {
          const rect = video.getBoundingClientRect()
          const x = event.clientX - rect.left;
          const y = event.clientY - rect.top;
          // console.log("mouseup", "x: " + x + " y: " + y);
          // console.log("width: " + rect.width + " height: " + rect.height);
          if (event.button === 0) {
            const message = JSON.stringify({
              command: 'mouse_up',
              x: getPreciseStringValue(x / rect.width),
              y: getPreciseStringValue(y / rect.height) + '',
            });
            // console.log(message);
            refOpponent.current.dataChannel.send(message);
          }
        });
        video.addEventListener('mousemove', function (event) {
          const rect = video.getBoundingClientRect()
          // console.log(rect, event.clientX, event.clientY, event)
          const x = event.clientX - rect.left;
          const y = event.clientY - rect.top;
          // if (refOpponent.current.isMouseDown) {
          const message = JSON.stringify({
            command: 'mouse_move',
            x: getPreciseStringValue(x / rect.width),
            y: getPreciseStringValue(y / (rect.height)),
          });
          // console.log(message);
          refOpponent.current.dataChannel.send(message);
          // }
          // console.log("mousemove", "x: " + x + " y: " + y);
          // console.log("width: " + rect.width + " height: " + rect.height);
        });
        document.addEventListener('mouseup', function (event) {
          refOpponent.current.isMouseDown = false;
        })
        base.addEventListener('mousemove', function (event) {
          // console.log("fab mousemove:", fab.style.top, fab.style.left, event.clientX, event.clientY);
        });
      }

      onOpenSC && onOpenSC(true);

    };
    refOpponent.current.streamConnection.onnegotiationneeded = () => {
      // setOpenedWebRTCRemote(true)
    };
    refOpponent.current.streamConnection.onicecandidate = function (e) {
      // console.log('SWS', 'stream candidate', e);
      if (e.candidate) {
        refOpponent.current.dataChannel.send(JSON.stringify({
          data: {
            category: 'ws',
            service: 'Candidate',
            access_token: accessToken,
            opponent_dat: deviceAccessToken,
            channel_type: "stream",
            candidate: {
              type: 'candidate',
              candidate: e.candidate,
            },
          }
        }));
      }
    };
  };

  const createAnswerSC = (offer, opponentDAT, streamLabel, handle) => {
    const channelLabel = v4();
    const access_info = JSON.parse(sessionStorage.getItem('access_info'));
    let accessToken = '';
    let deviceAccessToken = '';
    if (access_info) {
      accessToken = access_info.access_token;
      deviceAccessToken = access_info.device_access_token
    }
    let ua = navigator.userAgent.toLowerCase();

    setupPeerConnection();

    // setConnected(true)
    refOpponent.current.streamConnection.setRemoteDescription(new RTCSessionDescription(offer));
    refOpponent.current.streamConnection.createAnswer().then(function (answer) {
      // console.log('--------------> 3', opponentDAT, thisComponent.nodes[opponentDAT].dc)
      // console.log('sc', 'createAnswer', deviceAccessToken, JSON.stringify(answer));
      refOpponent.current.dataChannel.send(JSON.stringify({
        data: {
          category: 'ws',
          service: 'Answer',
          channel_type: 'stream',
          opponent_dat: deviceAccessToken,
          source: ua,
          handle: handle,
          label: channelLabel,
          sdp: answer,
        },
      }));
      refOpponent.current.streamConnection.setLocalDescription(answer);
    }).catch(function (error) {
      console.log('SWS', 'An error has occurred.', error);
    });
  };
  const onAnswerStream = (answer) => {
    refOpponent.current.streamConnection.setRemoteDescription(new RTCSessionDescription(answer));
  };
  const onCandidateStream = (candidate) => {
    refOpponent.current.streamConnection.addIceCandidate(new RTCIceCandidate(candidate));
  };
  const onCandidateSC = (candidate) => {
    // console.log('candidate', refOpponent.current.streamConnection);
    if (candidate.candidate && candidate.candidate.length > 0) {
      refOpponent.current.streamConnection.addIceCandidate(candidate).catch(e => {
        console.log('Failure during addIceCandidate(): ' + e.name);
      });
    }

  };
  const createAnswerData = (offer, label) => {
    const iceServers = refOpponent.current.iceServers;
    refOpponent.current.dataChannelLabel = label;
    refOpponent.current.dataConnection = new RTCPeerConnection(iceServers)
    // let ua = navigator.userAgent.toLowerCase();
    // console.log(ua);
    // if (ua.indexOf('safari') !== -1) {
    //     if (ua.indexOf('chrome') > -1) {
    //         // Chrome
    //         console.log('SWS', 'Safari Test 1');
    //         refOpponent.current.dataConnection = new RTCPeerConnection(iceServers)
    //     } else {
    //         // Safari
    //         console.log('SWS', 'Safari Test 2');
    //         refOpponent.current.dataConnection = new RTCPeerConnection({
    //             iceServers: [
    //                 {
    //                     "urls": "stun:stun.l.google.com:19302"
    //                 }
    //             ]
    //         });
    //     }
    // } else {
    //     console.log('SWS', 'Safari Test 3');
    //     refOpponent.current.dataConnection = new RTCPeerConnection(iceServers)
    // }

    // console.log('SWS', this.dataConnection);

    const access_info = JSON.parse(sessionStorage.getItem('access_info'));
    let accessToken = '';
    let deviceAccessToken = '';
    if (access_info) {
      accessToken = access_info.access_token;
      deviceAccessToken = access_info.device_access_token
    }

    // console.log(offer);
    // const d = JSON.parse(offer);
    refOpponent.current.dataConnection.setRemoteDescription(new RTCSessionDescription(offer));
    refOpponent.current.dataConnection.createAnswer(function (answer) {
      refOpponent.current.dataConnection.setLocalDescription(answer);
      let payload = {
        data: {
          category: 'ws',
          service: 'Answer',
          access_token: accessToken,
          opponent_dat: refOpponent.current.opponentDAT,
          label: refOpponent.current.dataChannelLabel,
          channel_type: "data",
          sdp: answer,
        }
      };
      sendWS(payload)
    }, function (error) {
      // console.log('createAnswer', error);
    });
    refOpponent.current.dataConnection.oniceconnectionstatechange = e => {
      // console.log('[DataChannel oniceconnectionstatechange]',
      //   agent.name,
      //   refOpponent.current.opponentDAT,
      //   e.target.iceConnectionState,
      //   e.target.iceGatheringState);
    };
    refOpponent.current.dataConnection.onicecandidate = function (e) {
      if (e.candidate) {
        setTimeout(() => {
          sendWS({
            data: {
              category: 'ws',
              service: 'Candidate',
              access_token: accessToken,
              opponent_dat: refOpponent.current.opponentDAT,
              label: refOpponent.current.dataChannelLabel,
              channel_type: 'data',
              candidate: {
                type: 'candidate',
                candidate: e.candidate,
              },
            }
          });
        }, 100);
      }
    };
    refOpponent.current.dataConnection.onnegotiationneeded = e => {
      // console.log('onnegotiationneeded', e);
    };
    refOpponent.current.dataConnection.ondatachannel = (e) => {
      // console.log('[DataChannel ondatachannel]', agent.name, refOpponent.current.opponentDAT, Object.keys(dataChannels).length);
      refOpponent.current.dataChannel = e.channel;
      dispatch(updateDataChannels({
        ...dataChannels,
        [refOpponent.current.opponentDAT]: e.channel,
      }))
      refOpponent.current.dataChannel.onclose = () => {
        // console.log('[DataChannel has closed]', agent.name, refOpponent.current.opponentDAT);
        onClose && onClose()
        dispatch(updateDataChannels({
          ...dataChannels,
          [refOpponent.current.opponentDAT]: undefined,
        }))
      };
      refOpponent.current.dataChannel.onopen = () => {
        // console.log('[DataChannel has opened]', agent.name, refOpponent.current.opponentDAT);
        // setOpenedDataChannel(true)
        refOpponent.current.dataChannel.send(JSON.stringify({
          command: 'get_apps',
        }));
        // TEST-CODE
        // setTimeout(() => {
        //   refOpponent.current.dataChannel.send(JSON.stringify({
        //     command: 'get_connections',
        //   }));
        // }, 3000)
      };
      refOpponent.current.dataChannel.onmessage = e => {
        let d;
        // console.log('SWS-DEBUG-9099-----------------', e.data);
        if (e.data === "@PIKABU-CHUNK-START") {
          refOpponent.current.chunk = "";
          return;
        } else if (refOpponent.current.chunk !== null && refOpponent.current.chunk !== undefined) {
          if (e.data === "@PIKABU-CHUNK-END") {
            d = JSON.parse(refOpponent.current.chunk);
            refOpponent.current.chunk = null;
          } else {
            refOpponent.current.chunk += e.data;
            return;
          }
        } else {
          d = JSON.parse(e.data);
        }
        // console.log('SWS-DEBUG-9099', d);
        if (d.data) {
          // console.log('SWS-DEBUG-9098', d.data);
          if (d.data.service === 'Offer') {
            // console.log('Offer', d.data);
            if (refOpponent.current.streamConnection) {
              if (refOpponent.current.streamConnection) {
                // console.log('sc close');
                refOpponent.current.streamConnection.close();
              }
            }
            createAnswerSC(d.data.sdp, d.data.opponent_dat, d.data.label, d.data.handle);
          } else if (d.data.service === 'Candidate') {
            onCandidateSC(d.data.candidate.candidate, d.data.opponent_dat, d.data.source);

            // setTimeout(() => {
            //     onCandidateSC(d.data.candidate.candidate, d.data.opponent_dat, d.data.source);
            // }, 100);
          } else if (d.data.service === 'AccessAuthorized') {
            dispatch(updatePermissionResult(d.data))
          } else if (d.data.service === 'AccessDenied' || d.data.service === 'SessionTimeout' || d.data.service === 'AppClosed') {
            dispatch(updatePermissionResult(d.data))
          } else if (d.data.service === 'AppSwitch') {
            dispatch(updateSwitchApp(d.data.switch_app))
          }
        } else {
          if (d.command === 'apps') {
            dispatch(updateAgentApps({
              ...agentApps,
              [refOpponent.current.opponentDAT]: d,
            }))

            refOpponent.current.dataChannel.send(JSON.stringify({
              command: 'get_connections',
            }));
          } else if (d.command === 'connections') {

            // console.log("connections", d)

            dispatch(updateAgentConnections({
              ...agentConnections,
              [refOpponent.current.opponentDAT]: d,
            }))
          } else if (d.command === 'resolution') {

            // console.log('resolution', JSON.stringify(d))
            dispatch(updateResolution(d))

            setTimeout(() => {
              sendDC(refOpponent.current.dataChannel, {
                command: 'get_connections',
              });
            }, 500)
          }
        }
      };
    }

    // dataConnection.createOffer()
    //     .then(function (offer) {
    //         // console.log('thisComponent.opponentDAT', thisComponent.opponentDAT);
    //         let payload = {
    //             data: {
    //                 category: 'ws',
    //                 service: 'Offer',
    //                 access_token: accessToken,
    //                 channel_type: "data",
    //                 opponent_dat: thisComponent.opponentDAT,
    //                 label: deviceAccessToken,
    //                 sdp: offer,
    //             }
    //         };
    //         thisComponent.sendWS(payload);
    //         dataConnection.setLocalDescription(offer);
    //     }).catch(function (error) {
    //     console.log('SWS', 'An error has occurred.', error);
    // });
  };
  const onAnswerData = (answer) => {
    refOpponent.current.dataConnection?.setRemoteDescription(new RTCSessionDescription(answer));
  };
  const onCandidateData = (candidate) => {
    refOpponent.current.dataConnection?.addIceCandidate(new RTCIceCandidate(candidate));
  };

  // console.log('DataChannel - refDataChannels: ', agent.name, Object.keys(refDataChannels.current).length)

  return <Fragment/>;
};

export default DataChannel;

// const mapStateToProps = ({settings, webrtc, websocket}) => {
//   const {agents, dataChannels, dataMessages, agentApps, agentConnections} = webrtc;
//   const {wsReceive} = websocket;
//   const {CS} = settings;
//
//   return {CS, agents, dataChannels, dataMessages, agentApps, agentConnections, wsReceive};
// };
//
// export default connect(mapStateToProps, {
//   updateWsSend,
//   updateAgentApps,
//   updateAgentConnections,
//   updateDataMessages,
//   updateDataChannels,
//   updateSubscribeAgentApps,
//   updateSubscribeDataChannels,
// })(DataChannel);
