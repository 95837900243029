import React, {useEffect, useState, useRef} from 'react';
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Typography} from "antd";


import Alert from "src/assets/svg/Alert";
import CardViewActive from "src/assets/svg/CardViewActive";
import ListViewInactive from "src/assets/svg/ListViewInactive";
import CardViewInactive from "src/assets/svg/CardViewInactive";
import ListViewActive from "src/assets/svg/ListViewActive";
import {updateSubUserList} from "src/appRedux/actions";
import {useAuth} from "src/authentication";
import AtomicButton from "src/components/AtomicButton";
import beApis from "src/util/apis/be";
import IntlMessages from "src/util/IntlMessages";
import {getLoginFormData} from "src/util/common";

import UserListView from "./UserListView";
import UserCardView from "./UserCardView";
import PinPopup from "./PinPopup";

import './index.css';


const {Text} = Typography;

const SelectUser = () => {
  const {authUser, subUser, userLoginSub, userLoginSubPinFree, userSignOut} = useAuth();
  const csSelected = useSelector(({cs}) => cs.csSelected);
  const subUserList = useSelector(({cs}) => cs.subUserList);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [refresh, setRefresh] = useState(false);
  const [activeTab, setActiveTab] = useState('card')

  const [showPin, setShowPin] = useState(false);
  const refSelectedUser = useRef();

  useEffect(() => {

  }, [])


  useEffect(() => {
    // console.log('-------------------->', refresh, authUser?.id, props.subUser?.id)
    if (authUser && authUser.id !== subUser?.id) {
      let clearCache = undefined
      if (refresh) {
        clearCache = 'true'
      }
      beApis.GetSubUsers(csSelected.host, clearCache).then(resp => {
        setRefresh(false)
        dispatch(updateSubUserList(resp))
      })
    }
  }, [authUser, refresh]);

  const handleClick = (selectedUser) => {
    console.log('handleClick', selectedUser)
    refSelectedUser.current =  selectedUser;

    if (selectedUser.has_pin) {
      setShowPin(selectedUser);
    }
    else {
      userLoginSubPinFree(
        getLoginFormData({
          email: selectedUser.username,
          password: selectedUser.username,
        }), (ok) => {
        // if (ok) {
        //     navigate('/home');
        // }
      })
    }
  }

  const handlePinSuccess = () => {
      setShowPin(false);
      userLoginSubPinFree(
      getLoginFormData({
        email: refSelectedUser.current.username,
        password: refSelectedUser.current.username,
      }), (ok) => {

      // if (ok) {
      //     navigate('/home');
      // }
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: 40,
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          // height: 128,
        }}>
        <div
          style={{
            flex: 2,
          }}>
          <Text ellipsis style={{fontSize: 24, fontWeight: 700, color: '#181818'}}>
            <IntlMessages id="vlink.page.selectUser.selectUser"/>
          </Text>
        </div>
        <div
          style={{
            marginTop: 4,
            display: 'flex',
          }}>
          <div
            style={{
              flex: 1,
              display: 'flex',
            }}>
            <Alert style={{minWidth: 20}}/>
            <Text ellipsis style={{fontSize: 14, lineHeight: '22px', marginLeft: 4, color: '#4F4F4F'}}>
              <IntlMessages id="vlink.page.selectUser.description"/>
            </Text>
          </div>
          {/*탭 버튼*/}
          <div style={{
            position: 'relative',
            top: -10,
            display: 'flex',
            alignItems: 'center',
          }}>
            <AtomicButton
              style={{
                margin: '4px',
                top: activeTab === 'card' ? '4px' : undefined,
                padding: '16px',
              }}
              // style={{
              //   padding: activeTab === 'card' ? '28px 24px 20px 24px' : '24px',
              // }}
              type="text"
              icon={activeTab === 'card' ? <CardViewActive/> : <CardViewInactive/>}
              onClick={() => {
                setActiveTab('card')
              }}
            />
            <AtomicButton
              style={{
                margin: '4px',
                top: activeTab === 'list' ? '4px' : undefined,
                padding: '16px',
              }}
              // style={{
              //   padding: activeTab === 'list' ? '28px 24px 20px 24px' : '24px',
              // }}
              type="text"
              icon={activeTab === 'list' ? <ListViewActive/> : <ListViewInactive/>}
              onClick={() => {
                setActiveTab('list')
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          backgroundColor: activeTab === 'card' ? '#ffffff' : 'transparent',
          padding: 20,
          borderRadius: 8,
        }}>
        {activeTab === 'card' ? <UserCardView onClick={handleClick}/> : <UserListView onClick={handleClick}/>}
      </div>     
      {showPin && <PinPopup selectedUser={showPin} onCancel={() => setShowPin(false)} onOk={handlePinSuccess}/> }
   </div>
  )

}

export default SelectUser;
