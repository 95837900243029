import {WEBSOCKET_CONNECTED, WEBSOCKET_RECEIVE, WEBSOCKET_SEND} from "../../constants/ActionTypes";


export function updateWsReceive(wsReceive) {
  return (dispatch) => {
    dispatch({type: WEBSOCKET_RECEIVE, wsReceive});
  }
}

export function updateWsSend(wsSend) {
  return (dispatch) => {
    dispatch({type: WEBSOCKET_SEND, wsSend});
  }
}

export function updateWsConnected(wsConnected) {
  return (dispatch) => {
    dispatch({type: WEBSOCKET_CONNECTED, wsConnected});
  }
}
