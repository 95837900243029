import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {httpClient} from "util/Api";
import beApis from "util/apis/be";
import {updateSubUserList} from "src/appRedux/actions";


export const useProvideAuth = () => {
  const [authUser, setAuthUser] = useState(null);
  const [subUser, setSubUser] = useState(null);
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [gwBaseUrl, setGWBaseUrl] = useState('');
  const [csBaseUrl, setCSBaseUrl] = useState('');

  const csSelected = useSelector(({cs}) => cs.csSelected);
  const dispatch = useDispatch();

  useEffect(() => {

    if (csSelected) {
      // const cookies = new Cookies();
      // const token = cookies.get("token");
      const access_info = localStorage.getItem('access_info');
      if (access_info) {
        const ai = JSON.parse(access_info)
        if (ai.access_token.length > 0) {
          httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + ai.access_token;
          if (ai.sub_user) {
            getAuthUser((user) => {
              setSubUser(user)
            })
          } else {
            getAuthUser()
          }
        }
      } else {
        setLoadingUser(false)
      }
    }
  }, [csSelected]);

  const fetchStart = () => {
    setLoading(true);
    setError('');
  }

  const fetchSuccess = () => {
    setLoading(false);
    setError('');
  }

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
  }

  const userLoginSub = (data, callbackFun) => {
    fetchStart();
    setLoadingUser(true)
    httpClient.post('oauth/sub-token/', data, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
      .then(({data}) => {
        if (data.access_token) {
          fetchSuccess();
          httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
          // const cookies = new Cookies();
          // cookies.set('token', data.access_token)
          const access_info = {
            access_token: data.access_token,
          }
          localStorage.setItem('access_info', JSON.stringify(access_info));
          // const decoded = jwt_decode(data.access_token)
          // cookies.set('gw', process.env.REACT_APP_GW_HOST)

          // const cookies = new Cookies();
          // const now = dayjs()
          // TODO: PIN Expires At
          // cookies.set('pin_expires_at', now.add(30, 'day').format())

          // invalidateAuthUser([]);

          // getAuthUser();
          if (callbackFun) callbackFun(true, data);
        } else {
          fetchError(data.error);
          if (callbackFun) callbackFun(false, data.err);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
        if (callbackFun) callbackFun(false, error.message);
      }).finally(() => {

      setLoadingUser(false)
    });
  };

  //20231027 - pin없이 로그인 서비스추가 - 추후제거예정
  const userLoginSubPinFree = (data, callbackFun) => {
    fetchStart();
    setLoadingUser(true)
    httpClient.post(
      'oauth/sub-token-pin-free/',
      data,
      {
        baseURL: csSelected.host,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },
    )
      .then(({data}) => {
        if (data.access_token) {
          fetchSuccess();
          httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;

          const access_info = {
            access_token: data.access_token,
            sub_user: true,
          }
          localStorage.setItem('access_info', JSON.stringify(access_info));
          // const now = dayjs()
          // TODO: PIN Expires At
          // cookies.set('pin_expires_at', now.add(30, 'day').format())

          const fcmToken = localStorage.getItem('fcm_token');
          // const fcmToken = cookies.get('fcmToken');
          // console.log('fcmToken', fcmToken)

          if (fcmToken) {
            beApis.RegisterFcmToken(csSelected.host, {fcm_token: fcmToken})
            .then((resp) => {
              console.log("firebase-token register success", resp)
            })
            .catch((e) => {
              console.log("firebase-token register error", e)

            })
          }
  

          getAuthUser((user) => {
            setSubUser(user)
          });

          if (callbackFun) callbackFun(true, data);
        } else {
          setLoadingUser(false)
          fetchError(data.error);
          if (callbackFun) callbackFun(false, data.err);
        }

      })
      .catch(function (error) {
        setLoadingUser(false)

        fetchError(error.message);
        if (callbackFun) callbackFun(false, error.message);
      }).finally(() => {

    });
  };

  const userLogin = (data, callbackFun, errorCallbackFun) => {
    fetchStart();
    httpClient.post('oauth/token/', data, {
      baseURL: csSelected.host,
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
      .then(({data}) => {
        if (data.access_token) {
          fetchSuccess();
          httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
          // const cookies = new Cookies();
          // cookies.set('token', data.access_token)
          const access_info = {
            access_token: data.access_token,
          }
          localStorage.setItem('access_info', JSON.stringify(access_info));
          // const decoded = jwt_decode(data.access_token)
          // cookies.set('gw', process.env.REACT_APP_GW_HOST)
          // console.log("userLogin process.env", process.env)

          // invalidateAuthUser([]);

          getAuthUser();
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
        errorCallbackFun && errorCallbackFun(error);
      });
  };
  const userSignup = (data, callbackFun) => {
    fetchStart();
    httpClient.post('v1/gw/sign-up/', data)
      .then(({data}) => {
        if (data.id) {
          fetchSuccess();
          // httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
          // const cookies = new Cookies();
          // cookies.set('token', data.token.access_token);
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const userSignOut = (callbackFun) => {
    const fcmToken = localStorage.getItem('fcm_token');

    const formData = new FormData();
    formData.append("fcm_token", fcmToken);

    fetchStart();
    httpClient.post(
      `oauth/logout/`,
      formData, {
        baseURL: csSelected.host,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }
    )
      .then(({data}) => {
        if (data.result) {
          fetchSuccess();
          setAuthUser(false);
          setSubUser(undefined);
          dispatch(updateSubUserList([]))

          httpClient.defaults.headers.common['Authorization'] = '';
          // const cookies = new Cookies();
          // cookies.remove('token');
          // cookies.remove('pin_expires_at');
          // cookies.remove('sub_user_id');
          // cookies.remove('gw');
          localStorage.removeItem('fcm_token')
          localStorage.removeItem('access_info')
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const clearUserFcmToken = (callbackFun) => {
    const fcmToken = localStorage.getItem('fcm_token');

    const formData = new FormData();
    formData.append("fcm_token", fcmToken);

    fetchStart();
    httpClient.post(
      `oauth/clear-fcm-token/`,
      formData, {
        baseURL: csSelected.host,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }
    )
      .then(({data}) => {
        if (data.result) {
          fetchSuccess();
          setSubUser(undefined)
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const invalidateAuthUser = (userIds) => {
    getAuthUser()
    // const cookies = new Cookies();
    // const gwBaseUrl = cookies.get('gw')
    // // fetchStart();
    // httpClient.post("v1/bm/invalidate/", {ids: userIds}, {baseURL: gwBaseUrl}).then(({data}) => {
    //   if (data.username) {
    //     // fetchSuccess();
    //     getAuthUser()
    //   }
    // }).catch(function (error) {
    //   // fetchError(error.message);
    //   setLoadingUser(false);
    // });
  }
  const getAuthUser = (subUserCallback) => {
    if (csSelected) {

      setLoadingUser(true);
      // const gwBaseUrl = cookies.get('gw')
      // fetchStart();

      httpClient.get(
        "/v1/cs/profile/",
        {
          baseURL: csSelected.host,
          headers: {
            'Clear-Cache': 'true'
          }
        },
      ).then(({data}) => {
        if (data.username) {
          // if (authUser?.id !== data.id) {
            setAuthUser(data);
          // }
          subUserCallback && subUserCallback(data)
          setLoadingUser(false);
        }
      }).catch(function (error) {
        localStorage.removeItem('access_info')
        httpClient.defaults.headers.common['Authorization'] = '';
        setLoadingUser(false);
        // fetchError(error.message);
      }).finally(() => {
        setLoadingUser(false);
      });
    }
  }
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  // useEffect(() => {
  //     const cookies = new Cookies();
  //     const token = cookies.get("token");
  //     if (token) {
  //       httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  //       invalidateAuthUser()
  //     }
  //     // httpClient.get("v1/bm/profile/").then(({data}) => {
  //     //   if (data.username) {
  //     //     setAuthUser(data);
  //     //   }
  //     //   setLoadingUser(false);
  //     // }).catch(function (error) {
  //     //   cookies.remove('token');
  //     //   httpClient.defaults.headers.common['Authorization'] = '';
  //     //   setLoadingUser(false);
  //     // });
  //   }, []);

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    subUser,
    gwBaseUrl,
    csBaseUrl,
    error,
    setAuthUser,
    invalidateAuthUser,
    getAuthUser,
    userLoginSub,
    userLoginSubPinFree,
    userLogin,
    userSignup,
    userSignOut,
  };
}

export const isUnRestrictedRoute = (pathname) => {
  return (pathname === '/user/signin' ||
    pathname === '/user/signup' ||
    pathname === '/user/forgot-password' ||
    pathname === '/user/reset-password' ||
    pathname === '/terms' ||
    pathname === '/404');
}

export const isRestrictedRoute = (pathname) => {
  return (
    pathname === '/' ||
    pathname === '/user/select-user' ||
    pathname === '/user/pin');
}
