import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, Input, Select, Space, Switch} from "antd";


import {useAuth} from "src/authentication";
import StyledFirstCircle from 'src/components/StyledFirstCircle';
import StyledHostTag from 'src/components/StyledHostTag';
import csApis from 'src/util/apis/cs';
import IntlMessages from "src/util/IntlMessages";
import {switchLanguage} from 'src/appRedux/actions';

const PersonalSettings = () => {
  const {authUser, isLoadingUser, getAuthUser} = useAuth();
  const dispatch = useDispatch();

  const csSelected = useSelector(({cs}) => cs.csSelected)
  const {locale} = useSelector(({settings}) => settings.locale)


  // console.log("authUSer", authUser)
  const [username, setUsername] = useState();
  const [phone, setPhone] = useState();
  const [useNotification, setUseNotification] = useState();

  useEffect(() => {

    // console.log("authUSer changed", isLoadingUser, authUser)
    if (authUser) {
      setUsername(authUser.name);
      setPhone(authUser.profile?.phone);
      setUseNotification(authUser.settings?.use_notification);
    }

  }, [isLoadingUser, authUser])

  const makeFormItem = (label, control, children, labelStyle) => {
    return <div style={{height: 70, display: "flex", alignItems: "center"}}>
      <div style={{width: 200, height: 32, lineHeight: "32px", fontSize: 20, fontWeight: 700}}>{label}</div>
      <div style={{flex: 1}}>

        {children}
      </div>
      <div style={{width: 60, height: 32, display: "flex", justifyContent: "flex-end"}}>
        {control}
      </div>
    </div>
  }

  const handleSaveName = () => {

    csApis.UpdateSubUser(csSelected.host, authUser.id, {name: username}).then((resp) => {
      getAuthUser();
    })

  }

  const handleSavePhone = () => {

    csApis.UpdateSubUser(csSelected.host, authUser.id, {phone}).then((resp) => {
      getAuthUser();
    })

  }

  const handleUseNotification = (e) => {
    setUseNotification(e);


    csApis.UpdateSubUser(csSelected.host, authUser.id, {settings: {use_notification: e}}).then((resp) => {
      getAuthUser();
    })
  }

  // const AppLocale = {
  //   'en-US': enLang,
  //   'ar-EG': arLang,
  //   'zh-CN': zhCnLang,
  //   'zh-HK': zhHkLang,
  //   'fr-FR': frLang,
  //   'de-DE': deLang,
  //   'it-IT': itLang,
  //   'jp': jpLang,
  //   'kr': krLang,
  //   'pt-BR': ptLang,
  //   'ru-RU': ruLang,
  //   'es-ES': esLang,
  // };

  const langOptions = [
    {value: "ko-KR", label: <IntlMessages id="vlink.common.language.kr"/>},
    {value: "en-US", label: <IntlMessages id="vlink.common.language.en"/>},
    {value: "ja-JP", label: <IntlMessages id="vlink.common.language.jp"/>}]


  const handleLocale = (value) => {
    dispatch(switchLanguage({locale: value}));
    localStorage.setItem("locale", value);

    csApis.UpdateSubUser(csSelected.host, authUser.id, {settings: {locale: value}}).then((resp) => {
      getAuthUser();
    })
  }


  return (
    <div style={{display: "flex", flexDirection: "column", gap: 20, height: "100%"}}>
      <div style={{
        backgroundColor: "#fff",
        borderRadius: 8,
        height: 126,
        display: "flex",
        padding: 31,
        paddingLeft: 40,
        gap: 20
      }}>
        <div>
          <StyledFirstCircle text={authUser?.name || ''} size={64} fontSize={36}/>
        </div>
        <div style={{display: "flex", flexDirection: "column"}}>
          <Space style={{flex: 1}}>
            <StyledHostTag isHost={authUser?.is_host} style={{fontSize: 16, margin: 0}}/>
            <span style={{fontSize: 20}}>{authUser?.name}</span>
          </Space>
          <Space style={{flex: 1}}>
            <span style={{fontSize: 14}}>클리닉 정보</span>
          </Space>
        </div>
      </div>

      <Card title={<IntlMessages id="vlink.settings.personal"/>} className="settings-card">
        <div style={{display: "flex", flexDirection: "column"}}>
          {makeFormItem(<IntlMessages id="vlink.settings.personal.name"/>,
            <Button onClick={handleSaveName} disabled={username == authUser?.name}><IntlMessages
              id="vlink.common.save"/></Button>,
            <Input style={{width: 300, height: 32}} value={username} onChange={(e) => setUsername(e.target.value)}/>)}
          {makeFormItem(<IntlMessages id="vlink.settings.personal.phone"/>,
            <Button onClick={handleSavePhone} disabled={phone == authUser?.profile?.phone}><IntlMessages
              id="vlink.common.save"/></Button>,
            <Input style={{width: 300, height: 32}} value={phone} onChange={(e) => setPhone(e.target.value)}/>)}
          {makeFormItem(<IntlMessages id="vlink.settings.personal.language"/>,
            <></>,
            <Select options={langOptions} style={{width: 150}} value={locale} onChange={handleLocale}/>,
            {fontSize: 20, fontWeight: 700}
          )}
          {makeFormItem(<IntlMessages id="vlink.settings.personal.notify"/>,
            <Switch value={useNotification} onChange={handleUseNotification}/>,
            <div><IntlMessages id="vlink.settings.personal.notify.description"/></div>,
            {fontSize: 20, fontWeight: 700}
          )}
        </div>

      </Card>
    </div>
  )

}

export default PersonalSettings;
