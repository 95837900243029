import antdAR from "antd/locale/ar_EG";
import saMessages from "../locales/ar_EG.json";

const arLang = {
  messages: {
    ...saMessages
  },
  antd: antdAR,
  locale: 'ar-EG',
};
export default arLang;
