import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2862 4.3903C12.7944 4.03564 12.1006 3.86978 11.4183 4.11985C9.9553 4.65608 8.61322 5.44117 7.4429 6.42316C6.8868 6.88977 6.68341 7.57349 6.74376 8.17703C6.84428 9.18252 6.66712 10.1533 6.18482 10.9901C5.7023 11.8274 4.95109 12.4667 4.03096 12.8822C3.47796 13.1319 2.98749 13.6517 2.86318 14.3694C2.7338 15.1167 2.6665 15.8843 2.6665 16.6668C2.6665 17.449 2.7338 18.2168 2.8632 18.964C2.9875 19.6817 3.47797 20.2014 4.03097 20.4512C4.95109 20.8667 5.7023 21.5061 6.18482 22.3433C6.6671 23.1801 6.84426 24.1509 6.74376 25.1564C6.68342 25.7599 6.88681 26.4437 7.4429 26.9102C8.6132 27.8922 9.95524 28.6773 11.4182 29.2134C12.1005 29.4636 12.7943 29.2977 13.2862 28.943C14.1057 28.3521 15.0344 28.0197 15.9998 28.0197C16.9653 28.0197 17.894 28.3521 18.7136 28.943C19.2054 29.2977 19.8992 29.4636 20.5814 29.2134C22.0444 28.6773 23.3864 27.8922 24.5566 26.9103C25.1128 26.4437 25.3161 25.7599 25.2558 25.1564C25.1553 24.1509 25.3324 23.1801 25.8146 22.3433C26.2972 21.5061 27.0485 20.8667 27.9686 20.4512C28.5217 20.2014 29.0122 19.6817 29.1365 18.964C29.2658 18.2168 29.3332 17.449 29.3332 16.6668C29.3332 15.8845 29.2658 15.1169 29.1365 14.3697C29.0122 13.6519 28.5217 13.1321 27.9686 12.8824C27.0485 12.4669 26.2972 11.8275 25.8146 10.9903C25.3324 10.1535 25.1553 9.1826 25.2558 8.17704C25.3162 7.57346 25.1128 6.88971 24.5566 6.42307C23.3864 5.44113 22.0444 4.65607 20.5813 4.11985C19.899 3.86979 19.2053 4.03564 18.7134 4.3903C17.894 4.98122 16.9653 5.31353 15.9998 5.31353C15.0344 5.31353 14.1057 4.98122 13.2862 4.3903ZM11.9998 16.6666C11.9998 14.4536 13.7906 12.6596 15.9998 12.6596C18.209 12.6596 19.9998 14.4536 19.9998 16.6666C19.9998 18.8797 18.209 20.6736 15.9998 20.6736C13.7906 20.6736 11.9998 18.8797 11.9998 16.6666Z"
      fill="#B9B8B9"
    />
  </svg>
);
export default SVGComponent;
