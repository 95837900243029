import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, Checkbox, Flex, Form, Input, Typography, Modal, Row } from "antd";
import { useIntl } from "react-intl";
import { Buffer } from 'buffer';

import IntlMessages from "src/util/IntlMessages";
import { useAuth } from "src/authentication";
import CircularProgress from "src/components/CircularProgress";
import beApis from "../../../util/apis/be";
import CircularProgress2 from "src/components/CircularProgress2";
import { usePopup } from "src/util/use-popup";

import "./index.css";

const { info, error } = Modal;

const Step2 = (props) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const locale = useSelector(({ settings }) => settings.locale);

  const [email, setEmail] = useState();
  const [focus, setFocus] = useState(false);

  const [privacy, setPrivacy] = useState({});
  const [terms, setTerms] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [validateUUID, setValidateUUID] = useState(true);

  const csSelected = useSelector(({ cs }) => cs.csSelected);
  const navigate = useNavigate();
  const { errorPopup, infoPopup } = usePopup();

  const location = useLocation();
  const param = useParams();

  useEffect(() => {
    setFocus(true);
  }, [])



  useEffect(() => {
    if (focus) {
      form.setFieldsValue({
        ...props.userInfo,
        clinic_name: props.userInfo.etc.clinic_name
      })
    }
  }, [focus])

  const onFinish = (values) => {
    const req = {
      ...values,
      profile: {
        phone: values.phone,
      },
      password: Buffer.from(values.password).toString('Base64'),
      waiting_id: props.userInfo.waiting_id,
    }

    setIsLoading(true);
    props.onOk(req);
  }


  return (
    <>
      {/* 
      <Flex justify="center" align="center" style={{ height: 86 }}>
        <Typography.Title style={{ color: "#df043a" }}><IntlMessages id="vlink.signup.addInfo" /></Typography.Title>
      </Flex> */}

      <div className="gx-app-login-content" style={{ width: "80%" }}>
        <div style={{ height: 380, display: "flex", alignItems: "center" }}>
          <Form
            form={form}
            initialValues={{ rememberMe: true }}
            name="basic"
            onFinish={onFinish}
            className="gx-signin-form gx-form-row0"
            style={{ marginBottom: 7, flex: 1 }}>
            <Form.Item
              initialValue=""
              rules={[{ required: true }]}
              name="clinic_name"
              label={intl.formatMessage({ id: 'vlink.signup.clinicName' })}
              labelCol={{ span: 10 }}
            // style={{ marginBottom: 30 }}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              initialValue=""
              rules={[{ required: true }]}
              name="email"
              label={intl.formatMessage({ id: 'vlink.signup.email' })}
              labelCol={{ span: 10 }}
            // style={{ marginBottom: 30 }}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              initialValue=""
              rules={[{ required: true }]}
              name="name"
              label={intl.formatMessage({ id: 'vlink.signup.hostName' })}
              labelCol={{ span: 10 }}
            // style={{ marginBottom: 30 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              initialValue=""
              rules={[
                { required: true },
              ]}
              name="password"
              label={intl.formatMessage({ id: 'vlink.signup.password' })}
              labelCol={{ span: 10 }}
            // style={{ marginBottom: 30 }}
            >
              <Input type="password"/>
            </Form.Item>
            <Form.Item
              initialValue=""
              rules={[{ required: true },
              {
                validator: (_, value) => {
                  if (form.getFieldValue("password") !== value && value.length) {
                    return Promise.reject(new Error(intl.formatMessage({id: "vlink.signup.passwordNotMatch"})));
                  }
                  else {
                    return Promise.resolve();
                  }
                }
              }
              ]}
              dependencies={['password']}
              name="password_confirm"
              label={intl.formatMessage({ id: 'vlink.signup.passwordConfirm' })}
              labelCol={{ span: 10 }}
            // style={{ marginBottom: 30 }}
            >
              <Input type="password"/>
            </Form.Item>
            <Form.Item
              initialValue=""
              name="phone"
              label={intl.formatMessage({ id: 'vlink.signup.phone' })}
              labelCol={{ span: 10 }}
              style={{ marginBottom: 30 }}
            >
              <Input />
            </Form.Item>

          </Form>
        </div>

        <Row style={{ gap: "10px" }}>
          <Button className="gx-mb-0"
            disabled={isLoading}
            style={{
              height: 46,
              flex: 1,
              borderRadius: 4,
              // backgroundColor: '#DF043A',
              fontSize: 18,
              fontWeight: 700,
            }}
            onClick={() => props.onCancel()}
          >

            <IntlMessages id="vlink.button.prev" />
          </Button>
          <Button type="primary" className="gx-mb-0"
            disabled={isLoading}
            style={{
              height: 46,
              flex: 1,
              borderRadius: 4,
              backgroundColor: '#DF043A',
              fontSize: 18,
              fontWeight: 700,
            }}
            onClick={() => form.submit()}
          >

            {!isLoading ? <IntlMessages id="vlink.signup.save" /> : <CircularProgress2 />}
          </Button>
        </Row>
        <Form.Item >
          {/* <Button type="link"
                        style={{
                          width: '100%',
                          color: '#7E7E7E',
                        }}
                        >
                </Button> */}
        </Form.Item>

        {/*<Row style={{borderBottom: '1px solid #5e5e5e'}}/>*/}
        {/* <span
                className="gx-text-light gx-fs-sm"> demo user email: 'demo@example.com' and password: 'demo#123'</span> */}
      </div>
    </>
  );
};

export default Step2;
