import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 6.1499V4.1499C14 3.04533 13.1046 2.1499 12 2.1499H5C3.89543 2.1499 3 3.04533 3 4.1499V16.1499C3 17.2545 3.89543 18.1499 5 18.1499H12C13.1046 18.1499 14 17.2545 14 16.1499V14.1499"
      stroke="#4F4F4F"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M9 10.1499H17"
      stroke="#4F4F4F"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M16 8.1499L17.2929 9.4428C17.6834 9.83332 17.6834 10.4665 17.2929 10.857L16 12.1499"
      stroke="#4F4F4F"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);
export default SVGComponent;
