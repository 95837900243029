import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={2} y={4} width={16} height={12} rx={1} stroke="#2F2F2F" />
    <path
      d="M5 7.5H7"
      stroke="#2F2F2F"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
    <path
      d="M5 10H7"
      stroke="#2F2F2F"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
    <path
      d="M5 13H15"
      stroke="#2F2F2F"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
    <path
      d="M9 7.5H11"
      stroke="#2F2F2F"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
    <path
      d="M9 10H11"
      stroke="#2F2F2F"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
    <path
      d="M13 7.5H15"
      stroke="#2F2F2F"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
    <path
      d="M13 10H15"
      stroke="#2F2F2F"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
  </svg>
);
export default SVGComponent;
