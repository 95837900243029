import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Row, Col, Input, Space, Select, Tag, Form, TimePicker, Checkbox } from "antd";
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";

import AtomicPopup from "src/components/AtomicPopup";
import CircularProgress3 from 'src/components/CircularProgress3';
import StyledUserCardItem from 'src/components/StyledUserCardItem';

import beApis from "src/util/apis/be";
import IntlMessages from "src/util/IntlMessages";
import AtomicForm from 'src/components/AtomicForm';
import AtomicButton from 'src/components/AtomicButton';
import { DATE_FORMAT, getProductTagColors, SESSION_OPTIONS } from 'src/util/common';
import { usePopup } from "src/util/use-popup";

const RemoteAgentPopup = ({ selectedUser, permissionList, onOk, onCancel }) => {

  const csSelected = useSelector(({ cs }) => cs.csSelected);
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [form] = Form.useForm();
  const { confirmPopup, infoPopup } = usePopup();

  const [sessionTime, setSessionTime] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [useAccessTime, setUseAccessTime] = useState(true);
  const [useSessionTime, setUseSessionTime] = useState(true);

  dayjs.extend(weekday);
  dayjs.extend(localeData);
  dayjs.extend(customParseFormat);


  useEffect(() => {
    const clonedInfo = Object.assign({}, selectedUser);

    // console.log("remote agent", clonedInfo)

    if (selectedUser.name) {
      setUseAccessTime(selectedUser.settings?.use_accessible_time? true: false);
      if (selectedUser.settings?.use_accessible_time) {
        clonedInfo.accessibleTime = [dayjs(selectedUser.settings.accessible_start_time, "HH"), dayjs(selectedUser.settings.accessible_end_time, "HH")]
      }
      else {
        clonedInfo.accessibleTime = [null,null];
      }

      // console.log("accessTime", selectedUser.settings?.accessible_start_time, dayjs(selectedUser.settings?.accessible_start_time, "HH"), clonedInfo.accessibleTime )


      if (!selectedUser.settings?.session_expired_time) {
        setUseSessionTime(false);
        clonedInfo.sessionTime = null;
      }
      else {
        setUseSessionTime(true);
        clonedInfo.sessionTime = selectedUser.settings?.session_expired_time ;

      }

    }

    form.setFieldsValue(clonedInfo)
  }, [selectedUser])

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color={getProductTagColors(value)}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
        }}
      >
        {label}
      </Tag>
    );
  };

  const handleAdd = () => {
    form.validateFields().then((values) => {
      const SEND_FORMAT = "YYYY.MM.DD";
      values.start_date = values.start_date.format(SEND_FORMAT);
      values.end_date = values.end_date.format(SEND_FORMAT);
      beApis.addSubUser(csSelected.host, values).then((resp) => {
        infoPopup(intl.formatMessage({ id: "vlink.common.added" }), () => onOk())
      }).catch((e) => {
        console.log("에러발생", e)
      })
    }).catch((errorInfo) => { });


  }
  const handleUpdate = () => {
    form.validateFields().then((values) => {
      console.log("handleUpdate values", values)
      const SEND_FORMAT = "HH:00";

      const req = {
        device_access_token: selectedUser.device_access_token,
        settings: {}
      };

      req.settings.use_accessible_time = useAccessTime;
      if (useAccessTime) {
        req.settings.accessible_start_time = values.accessibleTime[0].format(SEND_FORMAT);
        req.settings.accessible_end_time = values.accessibleTime[1].format(SEND_FORMAT);
      }

      if (useSessionTime) {
        req.settings.session_expired_time = values.sessionTime;
      } else {
        req.settings.session_expired_time = 0;
      }
      beApis.patchAgent(csSelected.host, req).then((resp) => {
        infoPopup(intl.formatMessage({ id: "vlink.common.updated" }), () => onOk())
      }).catch((e) => {
        console.log("에러발생", e)
      })

    }).catch((errorInfo) => { });
  }

  const TIME_OPTIONS = Array.from({ length: 23 }, (_, i) => {
    const number = (i + 1).toString().padStart(2, '0');
    return { value: number, label: number }
  });




  const handleSessionTime = (value) => {

    // updateSettings({ settings: { session_expired_time: value } });
    setSessionTime(value);
  }

  const handleCheckAccessTime = (e) => {
    form.setFieldValue('accessibleTime', null);
    setUseAccessTime(!e.target.checked)
  }

  const handleCheckSessionTime = (e) => {
    form.setFieldValue('sessionTime', null)
    setUseSessionTime(!e.target.checked)
  }
  return <AtomicPopup
    title={
      <span>
        {selectedUser?.name ?
          <IntlMessages id="vlink.settings.remoteAgent.update" />
          :
          <IntlMessages id="vlink.settings.account.add" />

        }
      </span>
    }
    open={true}
    onOk={() => {
      onOk && onOk();
    }}
    onCancel={() => {
      onCancel && onCancel()
    }}
    centered
    footer={null}
    width={575}
  >
    <div style={{ padding: "16px 0 24px 0" }}>
      <AtomicForm form={form} disabled={selectedUser?.is_host}>
        <Row className='row-has-1cols'>
          <Col >
            <Form.Item label={intl.formatMessage({ id: "vlink.settings.account.name" })} >
              <div style={{paddingLeft: 7}}>{selectedUser.custom_name || selectedUser.name}</div>
            </Form.Item>
          </Col>
        </Row>
        <Row className='row-has-1cols'>
          <Col >
            <Form.Item label={intl.formatMessage({ id: "vlink.settings.remoteAgent.connectTime" })}  >
              <Row>
                <Col>
                  <Form.Item name="accessibleTime" rules={[{ required: useAccessTime }]}>
                    <TimePicker.RangePicker format="HH" style={{ width: "250px" }} disabled={!useAccessTime} allowClear={!useAccessTime} allowEmpty={!useAccessTime}/>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Checkbox onChange={handleCheckAccessTime} checked={!useAccessTime}>{intl.formatMessage({ id: "vlink.settings.security.unset" })}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>

        <Row className='row-has-1cols'>
          <Col >
            <Form.Item label={intl.formatMessage({ id: "vlink.settings.remoteAgent.sessionTime" })}>
              <Row>
                <Col>
                  <Form.Item name="sessionTime" rules={[{ required: useSessionTime }]}>
                    <Select style={{ width: "100px" }} options={SESSION_OPTIONS} onChange={handleSessionTime} disabled={!useSessionTime} allowEmpty={!useSessionTime}/>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Checkbox onChange={handleCheckSessionTime} checked={!useSessionTime}>{intl.formatMessage({ id: "vlink.settings.security.unset" })}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ justifyContent: "flex-end", marginTop: 20 }} className="popup-button-row">
          {selectedUser?.name ?
            <Space>
              <AtomicButton onClick={onCancel} label={intl.formatMessage({ id: "vlink.button.cancel" })} type="default" />
              <AtomicButton onClick={handleUpdate} label={intl.formatMessage({ id: "vlink.button.update" })}  />
            </Space>
            :
            <Space>
              <AtomicButton onClick={onCancel} label={intl.formatMessage({ id: "vlink.button.cancel" })} type="default" />
              <AtomicButton onClick={handleAdd} label={intl.formatMessage({ id: "vlink.button.add" })} type="primary" />
            </Space>

          }
        </Row>

      </AtomicForm>
      {isLoading && <CircularProgress3 />}
    </div>


  </AtomicPopup>
}

export default RemoteAgentPopup;
