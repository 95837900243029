import {Spin} from "antd";
import React from "react";
import Spinner from "../../assets/svg/Spinner";
import './index.css';
import VLogo from "../../assets/svg/VLogo";

const antIcon = <div style={{
  display: 'inline-table',
}}>
  <Spinner className={'loaderIcon'}/>
  <VLogo style={{
    position: 'absolute',
    top: 25,
    left: 23,
  }}/>
</div>;

const CircularProgress3 = ({className, spin}) => spin ? <Spin indicator={antIcon}/> :
  <div className={`loader ${className}`}
       style={{
         left: 0,
         top: 0,
         width: '100%',
         height: '100%',
         position: 'fixed',
       }}>
    <Spin indicator={antIcon}/>
  </div>;
export default CircularProgress3;
CircularProgress3.defaultProps = {
  className: ''
}
