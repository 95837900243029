import React from 'react';
import {getNameColor} from "../../util/common";

const StyledFirstCircle = (props) => {

  return (
    <div
      style={{
        // ...config.styles.common,
        display: 'flex',
        backgroundColor: getNameColor(props.text),
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginHorizontal: 4,
        width: (props.size || 20) + 1,
        height: (props.size || 20) + 1,
        borderRadius: props.size ? props.size * 0.5 : 10,
        borderWidth: 1,
        borderColor: '#fff',
        ...props.style,
      }}
    >
        <span
          style={{
            color: '#fff',
            fontSize: props.fontSize?? (props.size || 20 > 30 ? 16 : 12),
          }}>
          {props.text && props.text[0]}
        </span>
    </div>
  );
};

export default StyledFirstCircle;
