import React, {memo, useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {Button, Layout, Tabs, theme} from "antd";
import { v4 } from "uuid";

import {switchLanguage, 
  updateSubUserList, 
  updateDeviceAccessToken,
  updateWsSend,
} from "src/appRedux/actions";
import {useAuth} from "src/authentication";
import {useMenus} from "src/util/common";
import beApis from "src/util/apis/be";

import Logo from "src/assets/svg/Logo";

import UserProfile from "./UserProfile";
import UserSelect from "./UserSelect";
import languageData from "./languageData";
import "./index.css";




const {Header} = Layout;

const Topbar = () => {
  const {gnb, setLnb, authUser, subUser} = useAuth();
  const {locale} = useSelector(({settings}) => settings);
  const csSelected = useSelector(({ cs }) => cs.csSelected)
  const deviceAccessToken = useSelector(({ cs }) => cs.deviceAccessToken)
  const wsConnected = useSelector(({ ws }) => ws.wsConnected);
  const wsReceive = useSelector(({ ws }) => ws.wsReceive);

  const prevWs = useRef(undefined);



  const {gnbMenus} = useMenus();
  const [tabItems, setTabItems] = useState([]);
  const [activeKey, setActiveKey] = useState('home');
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [isHome, setIsHome] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const {
    token: {colorBgContainer},
  } = theme.useToken();

  useEffect(() => {

    if (prevWs.current !== wsReceive) {
      // console.log('wsReceive', wsReceive)
      if (wsReceive.service === 'RegisterComplete') {
        dispatch(updateDeviceAccessToken(wsReceive.device_access_token))
      } 
    }
    prevWs.current = wsReceive;

  }, [wsReceive]);

  useEffect(() => {
    if (!wsConnected) {
      dispatch(updateDeviceAccessToken(undefined))
      return
    }
    if (wsConnected && authUser && subUser && authUser.id === subUser.id) {
      let device_uuid = localStorage.getItem('device_uuid')
      if (!device_uuid || device_uuid.length === 0) {
        device_uuid = v4();
        localStorage.setItem('device_uuid', device_uuid)
      }
      dispatch(updateWsSend({
        data: {
          category: 'ws',
          service: 'Register',
          host_user: authUser.parent_username,
          email: authUser.username,
          client_type: 'web',
          mac_address: device_uuid,
          name: navigator.userAgent.toLowerCase(),
        },
      }))
    }
  }, [authUser, subUser, wsConnected])



  const languageMenu = () => (
    <ul style={{listStyle: "none", padding: "0 10px", marginBottom: 0}}>
      {languageData.map((language) => (
        <li
          style={{cursor: "pointer", display: "flex"}}
          key={JSON.stringify(language)}
          onClick={() => dispatch(switchLanguage(language))}
        >
          <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} style={{marginRight: 10}}/>
          <span>{language.name}</span>
        </li>
      ))}
    </ul>
  );

  const onTabClick = (key, moveflag = true) => {
    setActiveKey(key)


    navigate(`/${key}`);


    // console.log('onTabclick getMenuLnb key', key)
    // httpClient.post('common/get-menu-lnb', {gnb: key})
    //   .then((resp) => {
    //     const subMenu = resp.data.list[0].children ? resp.data.list[0].children[0].id : resp.data.list[0].id;
    //     if (moveflag)
    //       navigate('/' + key + '/' + subMenu);
    //     setLnb(resp.data);
    //     setActiveKey(key);
    //
    //   })
    //   .catch((e) => {
    //   })
  };

  useEffect(() => {
    // console.log("csSelected", csSelected)
    if (deviceAccessToken && csSelected.host) {
      beApis.GetSubUsers(csSelected.host, true).then(resp => {
        dispatch(updateSubUserList(resp))
      })
    }


  }, [csSelected, deviceAccessToken])

  useEffect(() => {
    if (subUser && gnbMenus && gnbMenus.length) {
      setTabItems(gnbMenus.map((item) => {
        return {key: item.key, label: <span style={{fontSize: 16}}>{item.label}</span>}
      }));
    }

  }, [gnbMenus, subUser]);

  // useEffect(() => {
  //   if (gnb && gnb.list.length) {
  //     setTabItems(gnb.list.map((item) => {
  //       return {key: item.id, label: item.text}
  //     }));
  //   }
  // }, [gnb]);

  useEffect(() => {
    if (location && location.pathname) {
      const gnbName = location.pathname.split('/')[1];
      const lnbName = location.pathname.split('/')[2];

      setActiveKey(gnbName)

      if (!lnbName && gnbName === "home") {
        setIsHome(true);

      }
      else {
        setIsHome(false)
      }

      // if (gnbName !== activeKey) {
      //   onTabClick(gnbName, false);
      // }
    }
  }, [location])


  const LogoComponent = memo(() => {
    return (
      <div
        style={{
          scale: '0.65',
          cursor: 'pointer',
        }}
          onClick={() => navigate('/home')}
        >
        <Logo/>
      </div>
    )
  })

  const ProfileComponent = memo(({
                                   user,
                                 }) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',

        }}>
        <UserSelect />
        <div
          style={{
            borderLeft: '1px solid #dcdcdc',
            marginLeft: 16,
          }}>
          <UserProfile/>
        </div>
      </div>
    )
  })

  return (
    <Header
      style={{
        // display: "flex",
        justifyContent: "column",
        background: colorBgContainer,
        padding: 0,
        height: 60,

        position: 'sticky',
        top: 0,
        zIndex: isHome? 1: 0,
        // width: '100%',
        // display: 'flex',
        // alignItems: 'center',

      }}>

      {/* 메뉴 */}
      <div className={'menu-tabs'}>
        <Tabs items={tabItems}
              tabPosition={'top'}
              style={{
                display: 'block',
              }}
              tabBarStyle={{height: 60, fontSize: 18}}
              activeKey={activeKey}
              tabBarExtraContent={{
                left: <LogoComponent/>,
                right: <ProfileComponent user={authUser}/>,
              }}
              renderTabBar={(tabBarProps, DefaultTabBar) => (
                <DefaultTabBar {...tabBarProps}>
                  {(node) => (
                    <div
                      {...node.props}
                      key={node.key}
                      style={{
                        justifyContent: 'center',
                      }}>{node}</div>
                  )}
                </DefaultTabBar>
              )}
              onTabClick={onTabClick}
        />
      </div>
      {/* <ConfigPopup open={isConfigOpen} onCancel={() => setIsConfigOpen(false)}/> */}
    </Header>
  );
};

export default Topbar;
