import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={59}
    height={59}
    viewBox="0 0 59 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_2435_2313)">
      <rect x={12} y={8} width={35} height={35} rx={3} fill="#DF043A" />
    </g>
    <rect x={19.0728} y={15.5} width={20} height={5} rx={1} fill="white" />
    <rect x={19.0728} y={23} width={20} height={5} rx={1} fill="white" />
    <rect x={19.0728} y={30.5} width={20} height={5} rx={1} fill="white" />
    <defs>
      <filter
        id="filter0_d_2435_2313"
        x={0}
        y={0}
        width={59}
        height={59}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={6} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.87451 0 0 0 0 0.0156863 0 0 0 0 0.227451 0 0 0 0.32 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2435_2313"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2435_2313"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SVGComponent;
