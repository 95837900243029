import React from 'react';
import { Button } from 'antd';

/**
 * antd Button에 스타일을 수정한 컴포넌트입니다.
 */
const AtomicButton = ({ type="primary", size, label, ...props }) => {

  return (
    <Button
      type={type}
      size={size}
      {...props}
    >
      {label}
    </Button>
  );
};

export default AtomicButton;
