//외부라이브러리
import React from 'react';
import { Form } from "antd";

import "./index.css";

import { formItemLayoutDefault, formItemLayoutVerticalDefault } from '../../constants/CommonConfigs';

const AtomicForm = (props) => {

	const defaultConfig = props.layout === 'vertical' ? formItemLayoutVerticalDefault : formItemLayoutDefault;

    return <Form 
        {...props}
        {...defaultConfig}
        >
            {props.children}
        </Form>
}

export default AtomicForm;
