import React from 'react';
import {Card, List, Typography} from "antd";
import './index.css';
import OutlineButton from "../../components/OutlineButton";
import IntlMessages from "../../util/IntlMessages";
import { appCustomName } from 'src/util/common';

const {Text} = Typography;

const AgentAppItem = (props) => {

  const {item, onClick} = props

  return (
    <List.Item style={{border: "none"}}>
      <div><Text style={{fontSize: 16}}>{appCustomName(item.title)}</Text></div>
      <div style={{
        display: 'flex',
        alignItems: "center",
      }}>
        <OutlineButton
          label={<IntlMessages id="vlink.common.shortConnect"/>}
          onClick={() => {
            // console.log("AgentAppItem click", item)
            props.onClick && props.onClick(item)
          }}
        />
      </div>
    </List.Item>
  );
};

export default AgentAppItem;
