import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={160}
    height={38}
    fill="none"
    {...props}
  >
    <path
      fill="#DB092F"
      d="M79.747 19.716c0-7.341 5.647-9.406 13.44-9.406 6.652 0 11.004 2.055 11.004 8.37 0 4.565-2.104 6.68-7.596 7.799-2.901.538-6.341.986-10 1.23v.783c0 2.745 1.856 4.027 6.104 4.027a41.39 41.39 0 0 0 9.948-1.18v5.593c-3.15.722-6.55 1.068-10.051 1.068-8.943 0-12.84-3.132-12.84-10.098v-8.196l-.01.01Zm6.797 2.94c2.808-.245 5.896-.641 8.155-1.18 1.793-.438 2.653-1.119 2.653-2.797 0-1.952-1.347-2.938-4.404-2.938-4.56 0-6.404.935-6.404 4.077v2.837ZM130.469 36.82c-3.295.834-6.549 1.18-9.741 1.18-8.548 0-12.299-3.529-12.299-10.149V20.46c0-6.62 3.751-10.149 12.341-10.149 3.243 0 6.445.336 9.699 1.17v5.4a37.547 37.547 0 0 0-9.046-1.13c-4.041 0-6.093 1.221-6.093 4.658v7.514c0 3.295 1.896 4.617 5.999 4.617 3.14 0 6.342-.397 9.14-1.139v5.43-.01ZM53.613 28.594c0 7.352-5.647 9.406-13.44 9.406-6.641 0-10.942-2.054-10.942-8.379 0-4.566 2.042-6.67 7.544-7.8a90.42 90.42 0 0 1 10-1.23v-.833c0-2.746-1.855-4.027-6.052-4.027-3.347 0-6.549.345-9.99 1.18v-5.543c3.203-.732 6.602-1.067 10.042-1.067 8.942 0 12.838 3.132 12.838 10.097v8.196Zm-6.797-2.98c-2.808.245-5.896.631-8.145 1.17-1.761.448-2.652 1.139-2.652 2.796 0 1.953 1.347 2.94 4.393 2.94 4.55 0 6.394-.936 6.394-4.068v-2.848l.01.01ZM65.55 16.33v12.06c0 2.603 1.503 3.874 4.653 3.874 2.197 0 3.844-.101 5.792-.437v5.684c-2.248.244-4.3.438-6.3.438-7.398 0-10.839-3.092-10.839-9.07V6.658l6.694-.731v4.749h10.445v5.643H65.55v.01ZM152.395 37.614V20.317c0-2.939-1.699-4.617-5.45-4.617-1.606 0-3.254.153-4.798.438v21.465h-6.797V0h6.797v10.88c1.751-.345 3.606-.59 6.103-.59 7.699 0 11.098 3.336 11.098 9.61v17.703h-6.942l-.011.01ZM14.559 37.96h-1.44c-2.954 0-4.85-.926-5.99-3.386-2.642-5.43-5.492-16.3-7.129-23.886h7.326c1.492 7.392 3.938 17.154 5.492 20.774.238.437.446.488.798.488h.456c.342 0 .539-.05.798-.488 1.554-3.62 3.989-13.382 5.481-20.774h7.337c-1.648 7.595-4.487 18.456-7.13 23.886-1.15 2.45-3.046 3.386-5.989 3.386h-.01Z"
    />
  </svg>
)
export default SvgComponent
