import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Button, Checkbox, Form, Input, Space} from "antd";
import {useIntl} from "react-intl";


import { setInitUrl } from "src/appRedux/actions";
import Logo from "src/assets/svg/Logo";
import {useAuth} from "src/authentication";
import CircularProgress2 from "src/components/CircularProgress2";
import {getLoginFormData} from "src/util/common";
import IntlMessages from "src/util/IntlMessages";
import { usePopup } from "src/util/use-popup";

import './index.css'
import { useNavigate } from "react-router-dom";

const SignIn = (props) => {
  const [form] = Form.useForm();
  const {isLoading, userLogin, userSNSLogin} = useAuth();
  const intl = useIntl();
  const [SNSAccessToken, setSNSAccessToken] = useState(undefined);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { errorCatchPopup, infoPopup } = usePopup();


  useEffect(() => {
    const account = localStorage.getItem("rememberMe");

    if (account) {
      form.setFieldsValue({"rememberMe": true});
      form.setFieldsValue({"email": account});
    }

  }, []);

  useEffect(() => {
    if (SNSAccessToken) {
      userSNSLogin(getLoginFormData({accessToken: SNSAccessToken}));
    }
  }, [SNSAccessToken]);

  // const getLoginFormData = ({email, password, accessToken = null}) => {
  //
  //     const data = {
  //         grant_type: "password",
  //         client_id: '8b38cFmyzu3p1CQYAtfssBrUUMZdPmhPf4SnVjuE',
  //         client_secret: 'pbJcE7IXhU1i0nqeRcoLLtqj8irm9KA11wqSZ7LWpJlM0pkGmq08Ocqu5vw10Y26AeDzoKfwbXgrPBAfUlIQLoBTJ2T1hgUNwqtBXfZfK7XbeHio1a1UYX3bnupk8Sld',
  //     };
  //     const formData = new FormData();
  //     Object.entries(data).forEach(([key, value]) => {
  //         formData.append(key, value);
  //     });
  //
  //     if (accessToken) {
  //         formData.append("access_token", accessToken);
  //     } else {
  //         formData.append("username", email);
  //         formData.append("password", Buffer.from(password).toString('Base64'));
  //     }
  //
  //     return formData
  // };

  const onFinishFailed = errorInfo => {
  };

  const onFinish = values => {
    const {email, password, rememberMe} = values;

    if (rememberMe) {
      localStorage.setItem("rememberMe", email);
    } else {
      localStorage.removeItem("rememberMe");
    }


    // const data = {
    //   grant_type: "password",
    //   client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
    //   client_secret:process.env.NEXT_PUBLIC_CLIENT_SECRET,
    // };
    // const formData = new FormData();
    // Object.entries(data).forEach(([key, value]) => {
    //   formData.append(key, value);
    // });

    // formData.append("username", email);
    // formData.append("password", Buffer.from(password).toString('Base64'));

    // console.log("onFinish", email, password)

    dispatch(setInitUrl(""));


    userLogin(getLoginFormData({email: email, password: password}),
      () => {}, 
      (ret) => {
        errorCatchPopup(
          ret.response.data,
          () => { }
        );
    });
  };

  if (SNSAccessToken) {
    return null
  }

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content" style={{display: 'flex', flexDirection: 'column',alignItems: 'center', height: 678}}>
          <div className="gx-app-logo-content" style={{justifyContent: 'center', width: "60%"}}>
            <div className="gx-app-logo">
              <Logo/>
            </div>
          </div>
          <div className="gx-app-login-content" style={{width: "60%"}}>
            <Form
              form={form}
              initialValues={{rememberMe: true}}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
              style={{marginBottom: 7}}
            >

              <Form.Item
                initialValue=""
                rules={[{required: true, message: 'The input is not valid E-mail!'}]} name="email">
                <Input
                  style={{
                    height: 44,
                    fontSize: 16,
                  }}
                  placeholder={intl.formatMessage({id: 'vlink.common.email'})}
                />
              </Form.Item>
              <div style={{
                flex: 1,
                width: '100%',
                height: 16,
              }}></div>
              <Form.Item
                initialValue=""
                rules={[{required: true, message: 'Please input your Password!'}]} name="password">
                <Input
                  style={{
                    height: 44,
                    fontSize: 16,
                  }}
                  type="password" placeholder={intl.formatMessage({id: 'vlink.common.password'})}/>
              </Form.Item>
              <Space className={'login-features'}>
                <Form.Item className={'remember-checked'} valuePropName="checked" name={"rememberMe"}
                           style={{marginBottom: 0}}>
                  <Checkbox>
                    <IntlMessages id="app.userAuth.rememberMe"/>
                  </Checkbox>
                </Form.Item>
                <Space align={'end'}>
                  <a href="/user/forgot-password/" className="gx-login-form-forgot gx-link">
                    <IntlMessages id="app.userAuth.forgotPassword"/>
                  </a>
                  {/*<Divider type={'vertical'}/>*/}
                  {/*<a href="/signup" className="gx-login-form-forgot gx-link">*/}
                  {/*  <IntlMessages id="app.userAuth.signUp"/>*/}
                  {/*</a>*/}
                </Space>
              </Space>
              <Form.Item className={'signin-button'}>
                <Button type="primary" className="gx-mb-0" htmlType="submit"
                        disabled={isLoading}
                        style={{
                          height: 46,
                          width: '100%',
                          borderRadius: 4,
                          backgroundColor: '#DF043A',
                          fontSize: 18,
                          fontWeight: 700,
                        }}>

                  {!isLoading ? <IntlMessages id="app.userAuth.signIn"/> : <CircularProgress2 />}
                </Button>
              </Form.Item>
              <Form.Item >
                <Button type="link"
                        style={{
                          width: '100%',
                          color: '#7E7E7E',
                        }}
                        onClick={() => navigate('/user/signup/')}
                        >
                  <IntlMessages id="app.userAuth.signUp"/>
                </Button>
              </Form.Item>
              {/*<Row style={{borderBottom: '1px solid #5e5e5e'}}/>*/}
              {/* <span
                className="gx-text-light gx-fs-sm"> demo user email: 'demo@example.com' and password: 'demo#123'</span> */}
            </Form>
          </div>
          {/*{isLoading &&*/}
          {/*  <div className="gx-loader-view">*/}
          {/*    <CircularProgress3 />*/}
          {/*  </div>}*/}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
