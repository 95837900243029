import { httpClient } from "../Api";

import { convertDateFormat } from "../common";

class BeApis {

  makeString = (d) => {
    let newString = d.new;
    let oldString = d.old;
    return oldString + " -> " + newString;

  }

  makeBooleanToString = (d) => {
    let newString = d.new ? "가능" : "불가능";
    let oldString = d.old ? "가능" : "불가능";
    return oldString + " -> " + newString;

  }

  makeArrayToString = (d) => {
    let newString = d.new.join(", ");
    let oldString = d.old.join(", ");
    return oldString + " -> " + newString;

  }

  makeObjectArrayToString = (d) => {
    let newString = d.new.map(e => e.name).join(", ");
    let oldString = d.old.map(e => e.name).join(", ");
    return oldString + " -> " + newString;

  }

  makeDateToString = (d) => {
    let newString = convertDateFormat(d.new);
    let oldString = convertDateFormat(d.old);
    return oldString + " -> " + newString;

  }

  async GetSubUsers(baseUrl, clearCache) {
    try {
      const requestConfig = {
        baseURL: baseUrl,
      }
      if (clearCache) {
        requestConfig.headers = {
          'Clear-Cache': 'true'
        }
      }
      const resp = await httpClient.get(`/v1/cs/sub-users/?page_size=0`, requestConfig)
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }


  async loginHistory(baseUrl, req, page, pageSize) {
    try {
      const requestConfig = {
        baseURL: baseUrl,
        headers: {
          'Clear-Cache': 'true'
        }
      }

      let url=`/v1/cs/sub-users/login-histories/?page_size=${pageSize}`;
      if (page) {
        url = `${url}&page=${page}`
      }

      const resp = await httpClient.post(url, req, requestConfig)
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }


  async addSubUser(baseUrl, values) {
    try {
      const resp = await httpClient.post(
        `v1/cs/sub-users/`,
        values,
        {
          baseURL: baseUrl,
        },
      );
      const data = resp.data || {};

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }


  async updateSubUser(baseUrl, id, values) {
    try {
      const resp = await httpClient.patch(
        `v1/cs/sub-users/${id}`,
        values,
        {
          baseURL: baseUrl,
        },
      );
      const data = resp.data || {};

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }


  async deleteSubUser(baseUrl, id) {
    try {
      const resp = await httpClient.patch(
        `v1/cs/sub-users/delete/${id}`,
        {is_deleted: true},
        {
          baseURL: baseUrl,
        },
      );
      const data = resp.data || {};

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }


  async resetPinSubUser(baseUrl, id) {
    try {
      const resp = await httpClient.patch(
        `v1/cs/sub-users/${id}/reset-pin/`,
        {secret: ""},
        {
          baseURL: baseUrl,
        },
      );
      const data = resp.data || {};

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }


  async RegisterFcmToken(baseUrl, req) {
    try {
      const resp = await httpClient.post(`/v1/gw/register-fcm-token/`, req, {
        baseURL: baseUrl,
      })
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async GetAgents(baseUrl, clearCache) {
    try {
      const requestConfig = {
        baseURL: baseUrl,
      }
      if (clearCache) {
        requestConfig.headers = {
          'Clear-Cache': 'true'
        }
      }
      const resp = await httpClient.get(`/v1/cs/agents/`, requestConfig)
      const data = resp.data || [];
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async UpdateAgents(baseUrl, values) {
    try {
      const resp = await httpClient.post(
        `v1/cs/agents/`,
        values,
        {
          baseURL: baseUrl,
        },
      );
      const data = resp.data || {};

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }



  async patchAgent(baseUrl, values) {
    try {
      const resp = await httpClient.patch(
        `v1/cs/agents/`,
        values,
        {
          baseURL: baseUrl,
        },
      );
      const data = resp.data || {};

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }




  async GetLayouts(baseUrl, clearCache) {
    try {
      const requestConfig = {
        baseURL: baseUrl,
      }
      if (clearCache) {
        requestConfig.headers = {
          'Clear-Cache': 'true'
        }
      }
      const resp = await httpClient.get(`/v1/cs/layouts/`, requestConfig)
      const data = resp.data?.layouts || []
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }



  async UpdateLayout(baseUrl, values) {
    try {
      const requestConfig = {
        baseURL: baseUrl,
      }

      const resp = await httpClient.post(`/v1/cs/layouts/`, values, requestConfig)
      const data = resp.data?.layouts || []
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }


  async GetPublicIP(baseUrl) {
    try {
      const requestConfig = {
        baseURL: baseUrl,
      }
      const resp = await httpClient.get(`v1/cs/public-ip/`, requestConfig)
      const data = resp.data;

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async GetFavorites(baseUrl, clearCache) {
    try {
      // TODO: 성능을 위해 Clear-Cache 삭제
      const requestConfig = {
        baseURL: baseUrl,
        headers: {
          'Clear-Cache': 'true'
        }
      }
      if (clearCache) {
        requestConfig.headers = {
          'Clear-Cache': 'true'
        }
      }

      const resp = await httpClient.get(`/v1/cs/favorites/`, requestConfig)
      const data = resp.data;

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }


  async UpdateFavorites(baseUrl, values) {
    try {
      const resp = await httpClient.post(
        `/v1/cs/favorites/`,
        values,
        {
          baseURL: baseUrl,
        },
      )
      const data = resp.data?.favorites || []

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }



  async GetRecentVisits(baseUrl, clearCache) {
    try {
      // TODO: 성능을 위해 Clear-Cache 삭제
      const requestConfig = {
        baseURL: baseUrl,
        headers: {
          'Clear-Cache': 'true'
        }
      }
      if (clearCache) {
        requestConfig.headers = {
          'Clear-Cache': 'true'
        }
      }
      const resp = await httpClient.get(`v1/cs/recent-visits/`, requestConfig)
      const data = resp.data;

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async ValidatePIN(baseUrl, values) {
    try {
      const resp = await httpClient.post(`/v1/cs/validate-pin/`, values, {
        baseURL: baseUrl,
      })
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async newMemberJoin(baseUrl, values) {
    try {
      const resp = await httpClient.post(`/v1/gw/new-member-join/`, values, {
        baseURL: baseUrl,
      })
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async validateQuickLink(baseUrl, values) {
    try {
      const resp = await httpClient.post(`/v1/gw/validate-quick-link/`, values, {
        baseURL: baseUrl,
      })
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async signUp(baseUrl, values) {
    try {
      const resp = await httpClient.post(`/v1/gw/sign-up/`, values, {
        baseURL: baseUrl,
      })
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }


  async getPrivacy(baseUrl, values) {
    try {
      const resp = await httpClient.post(`/v1/gw/privacy/search/?page_size=0`,
        values,
       {
        baseURL: baseUrl,
        }
      )
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getTerms(baseUrl, values) {
    try {
      const resp = await httpClient.post(`/v1/gw/terms/search/?page_size=0`,
        values,
       {
        baseURL: baseUrl,
        }
      )
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async forgotPassword(baseUrl, values) {
    try {
      const resp = await httpClient.post(`/v1/gw/forgot-password/`,
        values,
       {
        baseURL: baseUrl,
        }
      )
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async resetPassword(baseUrl, values) {
    try {
      const resp = await httpClient.post(`/v1/gw/reset-password/`,
        values,
       {
        baseURL: baseUrl,
        }
      )
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

}


const beApis = new BeApis();

export default beApis;
