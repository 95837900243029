import React, {Fragment, useEffect, useRef, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import csApis from "../../util/apis/cs";
import {updateCSSelected, updateCSHost, updateCSSlaveList, updateIceServers} from "../../appRedux/actions";

function CSContainer(props) {

  const dispatch = useDispatch();

  const candidateRef = useRef({
    selected: false, // 선택 되었는 지 여부
    slaveCS: {},
  })
  const [updated, setUpdated] = useState(undefined)

  const csHost = useSelector(({ cs }) => cs.csHost);
  const slaveCSList = useSelector(({ cs }) => cs.slaveCSList);

  useEffect(() => {
    if (!csHost) {
      // 0. 초기화
      candidateRef.current = {
        selected: false, // 선택 되었는 지 여부
        slaveCS: {},
        startTime: new Date().getTime(), // 측정 시작 시간
      }

      // 주기적으로 체크
      checkConnection()
    } else {
      // CS 가 정해졌다면
      // 1. CS SystemInformation
      // console.log('csHost', csHost)
      csApis.GetSystemInformation(csHost.host).then( resp => {
        // console.log(resp)
        if (resp && resp.ice_servers) {
          // console.log('>>>>>>', resp)
          dispatch(updateIceServers(resp.ice_servers))
          // cs, csHost 는 같은 정보이다. 단, 웹소켓 정보가 있다.
          dispatch(updateCSSelected({
            ...resp,
            ...csHost,
            websocket_server_url: csHost.host.replace('https:', 'wss:').replace('http:', 'ws:') + '/ws'
          }))
        } else {
          dispatch(updateCSSelected(undefined))
        }
      })
    }
  }, [csHost]);

  useEffect(() => {
    if (slaveCSList) {
      slaveCSList.map(item => {
        candidateRef.current.slaveCS[item.region] = {...item, startTime: new Date().getTime()}
        csApis.Candidate(item.host).then(resp => {
          // console.log('CS-DEBUG-003', JSON.stringify(resp, null, 2))
          // Slave CS 에서 거절한 경우는 패스: CS가 수용하고 있는 클라이언트가 많을 경우 거절한다.
          if (!resp.rejected) {
            candidateRef.current.slaveCS[item.region].endTime = new Date().getTime()
            candidateRef.current.slaveCS[item.region].elapsedTime = candidateRef.current.slaveCS[item.region].endTime - candidateRef.current.slaveCS[item.region].startTime
            candidateRef.current.slaveCS[item.region].resp = resp
            // console.log('CS-DEBUG-003', JSON.stringify(resp, null, 2))
            // console.log('CS-DEBUG-004', JSON.stringify(candidateRef.current, null, 2))
            setUpdated({})
          }
        })
      })
    }
  }, [slaveCSList])
  //
  useEffect(() => {
    if (updated && !candidateRef.current.selected) {
      // console.log('CS-DEBUG-004', JSON.stringify(candidateRef.current, null, 2))
      let minElapsed = undefined
      Object.keys(candidateRef.current.slaveCS).map(k => {
        if (candidateRef.current.slaveCS[k].elapsedTime) {
          if (!minElapsed) {
            minElapsed = candidateRef.current.slaveCS[k]
          } else {
            if (minElapsed.elapsedTime > candidateRef.current.slaveCS[k].elapsedTime) {
              minElapsed = candidateRef.current.slaveCS[k]
            }
          }
        }
      })
      // 가장 빨리 응답온 걸로 선택
      if (minElapsed) {
        // console.log('CS-DEBUG-004', minElapsed.host, minElapsed.elapsedTime)
        candidateRef.current.selected = true
        dispatch(updateCSHost(minElapsed))
      }
    }
  }, [updated]);
  //
  const preparePing = (slave_cs_list) => {
    slave_cs_list?.map(item => {
      csApis.Candidate(item.host).then(resp => {

      })
    })
    // 1초 있다가 응답시간 체크 시작
    setTimeout(() => {
      // console.log('CS-DEBUG-001', slave_cs_list)
      dispatch(updateCSSlaveList(slave_cs_list))
    }, 1000)
  }

  const checkConnection = () => {
    // console.log('Check Connection')
    if (!candidateRef.current.selected) {
      // 1. Slave CS 목록 얻어오기

      csApis.GetSlaveCS(process.env.REACT_APP_CS_HOST).then(resp => {
        // 2. CS 서버 먼저 깨우기
        preparePing(resp.slave_cs_list)
      })
      setTimeout(() => {
        checkConnection()
      }, 3000)
    }
  }

  // console.log('DEBUG-001', csHost, slaveCSList)

  return <Fragment/>;
}

export default CSContainer;
