import React, {useEffect} from "react";
import {Layout, Modal} from "antd";
import {useDispatch} from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import {usePopup} from "util/use-popup";

import App from "../../routes/index";
import {updateWindowWidth} from "../../appRedux/actions";

import SignIn from "src/routes/userAuth/SignIn";
import SignUp from "src/routes/userAuth/SignUp";
import SignUpProcess from "src/routes/userAuth/SignUpProcess";
import ForgotPassword from "src/routes/userAuth/ForgotPassword";
import QuickLink from "src/routes/x/QuickLink";

const MainApp = () => {
  const dispatch = useDispatch();
  const {setModal} = usePopup();
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (modal && setModal)
      setModal(modal);
  }, [modal, setModal]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(updateWindowWidth(window.innerWidth));
    })
  }, [dispatch]);

  return (
    <Layout style={{width: "100vw", height: "100vh",
      overflow: 'auto',
      // minWidth: 1200,
      // minHeight: 600
      }}>
        <Routes>
          <Route path='/user/signin' element={<SignIn />} />
          <Route path='/user/signup' element={<SignUp />} />
          <Route path='/user/signup-process' element={<SignUpProcess />} />
          <Route path='/user/forgot-password' element={<ForgotPassword />} />
          <Route path='/x/*' element={<QuickLink />} />
          <Route path='*' element={<App/>} />
        </Routes>
      {/*<AppSidebar/>*/}
      {/* <App /> */}

      {contextHolder}


    </Layout>
  )
};
export default MainApp;

