import {Modal} from "antd";

import AtomicTitle from "../AtomicTitle";
import './index.css';
import {useCallback, useEffect} from "react";

const AtomicPopup = (props) => {

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter' && props.open) {
      props.onOk && props.onOk();
    }
  }, [props.open]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Modal {...props}

      className={'vlink-modal'}
           styles={{
             header: {
               borderBottom: '1px solid #DCDCDC',
               padding: '20px 24px',
             },
             body: {
               backgroundColor: "#FFFFFF",
               padding: '0 24px',
             }
           }}
           // maskClosable={false}
           title={<AtomicTitle level={3}>{props.title}</AtomicTitle>}
    >
      <div>
        {props.children}
      </div>

    </Modal>
  )
}

export default AtomicPopup;
