import React, { useEffect, useState, useRef } from 'react';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "antd";
import { PinInput } from 'react-input-pin-code';
import { useIntl } from "react-intl";




import AtomicPopup from "src/components/AtomicPopup";
import beApis from "src/util/apis/be";


import IntlMessages from "src/util/IntlMessages";
import Pinpad3by4 from 'src/components/Pinpad3by4';

import "./PinPopup.css";
import CircularProgress3 from 'src/components/CircularProgress3';
import StyledUserCardItem from 'src/components/StyledUserCardItem';

const PinPopup = ({ selectedUser, onOk, onCancel }) => {

  const csSelected = useSelector(({ cs }) => cs.csSelected);
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);



  const [pinData, setPinData] = useState({
    current: { value: ['', '', '', '', '', ''], index: 0 },
  });
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (pinData.current) {
      if (pinData.current.value.join('').length === 6) {
        handleComplete()
      } else if (pinData.current.value.join('').length) {
        setShowError(false);
      }

    }
  }, [pinData])

  //여기서는 pin번호 맞나 확인만 한다
  const handleComplete = () => {
    setIsLoading(true);
    const pin = pinData.current.value.join('');
    beApis.ValidatePIN(csSelected.host, {
      username: selectedUser.username,
      secret: pin,
    }).then((resp) => {

      if (resp.success) {
        onOk && onOk();
      } else {
        setPinData({
          current: { value: ['', '', '', '', '', ''], index: 0 },
        })
        setShowError(intl.formatMessage({ id: "vlink.page.pin.attemptFailed" }));

        document.querySelectorAll(["pincode-input-container input"])

        const pincodeContainer = document.querySelector('.pincode-input-container');
        if (pincodeContainer) {
          const inputs = document.querySelectorAll('.pincode-input-container input');

          const nthInput = inputs[0];
          nthInput.focus();
        }
      }
      setIsLoading(false);

    })
  }

  const handleChange = (name, value, index, values) => {


    const newIndex = value.length ? Math.min(index + 1, 5) : index;
    setPinData({
      ...pinData,
      [name]: { value: values, index: newIndex }
    });


  }

  const handlePad = (name, e) => {

    let index = pinData[name].value.findIndex(item => item == "");

    if (index < 0) {
      index = 6;
    }

    if (e >= 0) {
      index = Math.min(index, 5);
    }
    else if (e == -1) {
      index = Math.max(index - 1, 0);
    }
    else {
      index = 0;
    }


    pinData[name].value[index] = e >= 0 ? e : "";

    const newValue = e == -2 ? ['', '', '', '', '', ''] : pinData[name].value;
    const newIndex = e >= 0 ? Math.min(index + 1, 5) : Math.max(index, 0);

    // refPin.current.focus();
    document.querySelectorAll(["pincode-input-container input"])

    const pincodeContainer = document.querySelector('.pincode-input-container');
    if (pincodeContainer) {
      const inputs = document.querySelectorAll('.pincode-input-container input');

      const nthInput = inputs[newIndex];
      nthInput.focus();
    }

    setPinData({
      ...pinData,
      [name]: { value: newValue, index: newIndex }
    });
  }



  return <AtomicPopup
    title={
      <span>
        <span>
          <IntlMessages id="vlink.page.pin.pleaseInputYourPassword" />
        </span>
      </span>
    }
    open={true}
    onOk={() => {
      onOk && onOk();
    }}
    onCancel={() => {
      onCancel && onCancel()
    }}
    centered
    footer={<div style={{
      height: 24,
    }}></div>}
    width={400}
  >
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 20, gap: 20 }} className="pin-popup">
      <StyledUserCardItem item={selectedUser} hideConnect={true} />
      {showError &&
        <div style={{ fontSize: 16, color: "#df043a" }}>{showError}</div>
      }
      <PinInput
        autocomplete="off"
        length={6}
        values={pinData.current.value}
        onChange={(value, index, values) => handleChange("current", value, index, values)}
        placeholder=''
        type="number"
        containerClassName={"pincode-input-container-popup" + (showError ? " invalid" : "")}
        containerStyle={{ padding: 0 }}
        mask={true}
        autoFocus={true}
      // onComplete={handleComplete}
      />
      <Pinpad3by4 onClick={(value) => handlePad('current', value)} />
    </div>
    {isLoading && <CircularProgress3 />}

  </AtomicPopup>
}

export default PinPopup;