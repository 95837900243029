import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

const antIcon = <Loading3QuartersOutlined style={{ fontSize: 28, color: '#DF043A' }} spin />;

const CircularProgress = ({className, spin}) => spin ? <Spin indicator={antIcon} /> :
  <div className={`loader ${className}`}
    style={{
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      position: 'fixed',
    }}>
    <Spin indicator={antIcon} />
  </div>;
export default CircularProgress;
CircularProgress.defaultProps = {
  className: ''
}
