import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from "../../authentication";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress3 from "../../components/CircularProgress3";
import {
  updateActiveAgents,
  updateAgents,
  updateAgentSelected,
  updateDeviceAccessToken,
  updateLayouts,
  updatePublicIP,
  updateSubUserList,
  updateWsSend
} from "../../appRedux/actions";
import { v4 } from "uuid";
import beApis from "src/util/apis/be";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import AtomicPopup from "../../components/AtomicPopup";
import IntlMessages from "../../util/IntlMessages";
import { Input, List, Button, Menu, Avatar, Space, Card, Switch, Select, TimePicker, Table, Flex, Tag } from "antd";
import StyledFirstCircle from 'src/components/StyledFirstCircle';
import config from "constants/Config";
import Information from "src/assets/svg/Information";
import { convertDateFormat, getProductTagColors } from 'src/util/common';

import './index.css';
import AccountPopup from './AccountPopup';
import StyledHostTag from 'src/components/StyledHostTag';
import HasPinTag from './HasPinTag';


const AccountSettings = () => {
  const { authUser, userSignOut } = useAuth();
  const intl = useIntl();
  const dispatch = useDispatch();

  const csSelected = useSelector(({ cs }) => cs.csSelected);
  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(false);
  const [hostUser, setHostUser] = useState();
  const [permissionList, setPermissionList] = useState([]);

  useEffect(() => {

    getData();

  }, [authUser]);

  useEffect(() => {
    if (hostUser) {
      setPermissionList(hostUser.product_permissions.sort())
    }
  }, [hostUser])

  const getData = () => {
    beApis.GetSubUsers(csSelected.host, true).then(resp => {
      setData(resp)
      setHostUser(resp.filter((item) => item.is_host)[0])
      dispatch(updateSubUserList(resp))

    }).catch((e) => console.log("getSubUsers error", e))
  }

  const columns = [
    {
      title: intl.formatMessage({ id: 'vlink.settings.account.name' }),
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => {
        return <Flex align='center'>
          <StyledFirstCircle text={value} size={24} style={{ marginRight: 10 }} />
          <span>{value}</span>
          <StyledHostTag isHost={record.is_host}/>
        </Flex>
      }
    },
    {
      title: intl.formatMessage({ id: 'vlink.settings.account.permission' }),
      dataIndex: 'product_permissions',
      key: 'product_permissions',
      render: (value) => {
        const temp = value.sort();
        return <>
          {temp.map((item) => <Tag color={getProductTagColors(item)} key={item}>{item}</Tag>)}
        </>
      }
    },
    {
      title: intl.formatMessage({ id: 'vlink.settings.account.hasPin' }),
      dataIndex: 'has_pin',
      key: 'has_pin',
      render: (t) => <HasPinTag hasPin={t} />
    },
    {
      title: intl.formatMessage({ id: 'vlink.settings.account.startDate' }),
      dataIndex: 'start_date',
      key: 'start_date',
      render: (t) => convertDateFormat(t)
    },
    {
      title: intl.formatMessage({ id: 'vlink.settings.account.endDate' }),
      dataIndex: 'end_date',
      key: 'end_date',
      render: (t) => convertDateFormat(t)
    },
    {
      title: intl.formatMessage({ id: 'vlink.settings.account.config' }),
      key: 'config',
      render: (t, r, i) => <Button onClick={() => setSelectedUser(r)}><IntlMessages id="vlink.settings.account.config" /></Button>
    },
  ]

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20, height: "100%" }}>

      <Card title={<Flex justify='space-between' align="center">
        <IntlMessages id="vlink.settings.account.setting" />
        <Button onClick={() => setSelectedUser({})}><IntlMessages id="vlink.settings.account.add" /></Button>
      </Flex>} className="settings-card">
        <Table
          className={'user-list'}
          columns={columns}
          dataSource={data}
          pagination={{ position: ['bottomCenter'] }}
          rowKey='id'
          // locale={{
          //   emptyText: (
          //     <CircularProgress3 />
          //   )
          // }}
        />
      </Card>
      {selectedUser &&
        <AccountPopup selectedUser={selectedUser} 
          permissionList={permissionList} 
          onOk={() => {setSelectedUser(false); getData();}}
          onCancel={() => setSelectedUser(false)} />
      }
    </div>
  )

}

export default AccountSettings;