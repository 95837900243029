import {
  WEBRTC_AGENT_APPS,
  WEBRTC_AGENT_CONNECTIONS,
  WEBRTC_AGENTS,
  WEBRTC_DATA_CHANNELS,
  WEBRTC_DATA_MESSAGES,
  WEBRTC_SUBSCRIBE_AGENT_APPS,
  WEBRTC_SUBSCRIBE_DATA_CHANNELS,
  WEBRTC_PERMISSION_RESULT,
  WEBRTC_RESOLUTION,
  WEBRTC_SWITCH_APP,
} from "../../constants/ActionTypes";

const initialSettings = {
  dataChannels: {},
  dataMessages: {},
  agents: [],
  agentApps: {},
  agentConnections: {},
  subscribeAgentApps: {},
  subscribeDataChannels: {},
  permissionResult: {},
  resolution: {},
  switchApp: undefined,
};

const WebsocketReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case WEBRTC_DATA_CHANNELS:
      return {
        ...state,
        dataChannels: action.dataChannels
      };
    case WEBRTC_DATA_MESSAGES:
      return {
        ...state,
        dataMessages: action.dataMessages
      };
    case WEBRTC_AGENTS:
      return {
        ...state,
        agents: action.agents
      };
    case WEBRTC_AGENT_APPS:
      return {
        ...state,
        agentApps: action.agentApps
      };
    case WEBRTC_AGENT_CONNECTIONS:
      return {
        ...state,
        agentConnections: action.agentConnections
      }
    case WEBRTC_SUBSCRIBE_AGENT_APPS:
      return {
        ...state,
        subscribeAgentApps: action.subscribeAgentApps,
      };
    case WEBRTC_SUBSCRIBE_DATA_CHANNELS:
      return {
        ...state,
        subscribeDataChannels: action.subscribeDataChannels,
      };
    case WEBRTC_PERMISSION_RESULT:
      return {
        ...state,
        permissionResult: action.permissionResult,
      }
    case WEBRTC_RESOLUTION:
      return {
        ...state,
        resolution: action.resolution,
      }
    case WEBRTC_SWITCH_APP:
      return {
        ...state,
        switchApp: action.switchApp,
      }
    default:
      return state;
  }
};

export default WebsocketReducer;
