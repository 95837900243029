import {Card, List} from "antd";
import CircularProgress3 from "../../../components/CircularProgress3";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import SelectUser from "./index";
import StyledUserCardItem from "../../../components/StyledUserCardItem";
import {useAuth} from "../../../authentication";


const UserCardView = (props) => {
  const subUserList = useSelector(({cs}) => cs.subUserList);
  const [data, setData] = useState([])

  useEffect(() => {
    setData(subUserList)
  }, [subUserList]);

  return (
    <div>

      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 4,
        }}
        locale={{
          emptyText: <CircularProgress3/>
        }}
        dataSource={(data && data.filter((item, idx) => idx === 0)) || []}
        renderItem={(item) => <StyledUserCardItem item={item} onClick={props.onClick}/>}
      />
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 4,
        }}
        locale={{
          emptyText: <span></span>
        }}
        dataSource={(data && data.filter((item, idx) => idx !== 0)) || []}
        renderItem={(item) => <StyledUserCardItem item={item}  onClick={props.onClick} />}
      />
    </div>
  )
}

export default UserCardView;
