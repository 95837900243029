export default {
  package: {
    version: '0.0.0',
    name: 'VLink'
  },
  colors: {

    debugColor1: 'rgba(255, 0, 0, 0.25)',
    debugColor2: 'rgba(255, 255, 0, 0.5)',
    debugColor3: 'rgba(255, 0, 255, 0.25)',
    debugColor4: 'rgba(0, 255, 0, 0.25)',
    debugColor5: 'rgba(255, 255, 128, 0.2)',
    debugColor6: 'rgba(0, 255, 255, 0.25)',
    debugColor7: 'rgba(0, 0, 255, 0.25)',
    debugColor8: 'rgba(255, 128, 255, 0.25)',
    debugColor9: 'rgba(128, 255, 255, 0.5)',
    debugColor10: 'rgba(0, 128, 128, 0.25)',
    primary: '#db082f',
    figmaPrimary: '#FD85A4',
    mainColor: '#ffffff',
    white: '#ffffff',
    black: '#000000',
    indigo: '#3557C9',
    lightishGreen: '#50ee50',
    pinkishGrey: '#cccccc',
    charcoalGrey: '#404044',
    slateGrey: '#606066',
    slateGreyTwo: '#505055',
    battleshipGrey: '#707077',
    darkBlueGreen: '#004025',
    sunflowerYellow: '#ffcc00',
    placeholder: '#B9B8B9',
    tomato: '#ee3224',
    achromatic: '#222222',

    headerColor: '#00A5BD',
    bottomColor: '#00A5BD',
    loadingCircleColor: '#00A5BD',
    imageBackGroundColor: '#00A5BD',
    imageDateColor: '#99dbe5',
    tagBackGroundColor: '#bfbfbf',
    bottomBackGroundColor: '#f5f6fa',
    startButtonColor: '#354d67',
    confirmButtonColor: '#354d67',
    nextButtonColor: '#354d67',
    textborderBottomColor: '#a8a8a8',
    placeholderTextColor: '#a8a8a8',
    buttonBackGroundColor: '#354d66',
    basicButtonColor: '#354d67',
    statusBarColor: '#00525e',
    confirmBackGroundColor: '#26415c',
  }
}
