import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={61}
    height={61}
    viewBox="0 0 61 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_528_13306)">
      <rect x={12.5} y={8.5} width={36} height={36} rx={3} fill="#DF043A" />
    </g>
    <path
      d="M20.9666 18.426C20.9666 17.6201 21.5334 16.9668 22.2327 16.9668H28.5634C29.2627 16.9668 29.8296 17.6201 29.8296 18.426V24.2627C29.8296 25.0686 29.2627 25.7219 28.5634 25.7219H22.2327C21.5334 25.7219 20.9666 25.0686 20.9666 24.2627V18.426Z"
      fill="white"
    />
    <path
      d="M20.9666 28.7375C20.9666 27.9317 21.5334 27.2784 22.2327 27.2784H28.5634C29.2627 27.2784 29.8296 27.9317 29.8296 28.7375V34.5743C29.8296 35.3802 29.2627 36.0335 28.5634 36.0335H22.2327C21.5334 36.0335 20.9666 35.3802 20.9666 34.5743V28.7375Z"
      fill="white"
    />
    <path
      d="M31.1702 18.426C31.1702 17.6201 31.7371 16.9668 32.4363 16.9668H38.7671C39.4663 16.9668 40.0332 17.6201 40.0332 18.426V24.2627C40.0332 25.0686 39.4663 25.7219 38.7671 25.7219H32.4363C31.7371 25.7219 31.1702 25.0686 31.1702 24.2627V18.426Z"
      fill="white"
    />
    <path
      d="M31.1702 28.7375C31.1702 27.9317 31.7371 27.2784 32.4363 27.2784H38.7671C39.4663 27.2784 40.0332 27.9317 40.0332 28.7375V34.5743C40.0332 35.3802 39.4663 36.0335 38.7671 36.0335H32.4363C31.7371 36.0335 31.1702 35.3802 31.1702 34.5743V28.7375Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_528_13306"
        x={0.5}
        y={0.5}
        width={60}
        height={60}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={6} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.87451 0 0 0 0 0.0156863 0 0 0 0 0.227451 0 0 0 0.32 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_528_13306"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_528_13306"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SVGComponent;
