import React, { useState, useEffect } from 'react';
import { Modal, Popover } from "antd";
import './index.css';


const StyledTooltip = (props) => {

  if (!props.content) {
    return <>
    {props.children}
    </>
  }
  return (
    <Popover
      overlayStyle={{
        border: '1px solid #B9B9B9',
        borderRadius: 8,
        ...props.style,
      }}
      placement="bottom"
      trigger="click"
      {...props}
    >
      {props.children}
    </Popover>
  )
}

export default StyledTooltip;
