import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={47}
    height={47}
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.82 16.182 23.897 21.103l-1.556 -1.553a2.749 2.749 0 0 0 -3.873 0l-10.615 10.615a2.726 2.726 0 0 0 -0.804 1.939c0 0.736 0.284 1.419 0.804 1.939l4.839 4.839c0.536 0.536 1.234 0.799 1.939 0.799s1.403 -0.263 1.939 -0.799l10.617 -10.617a2.738 2.738 0 0 0 0 -3.875l-1.553 -1.551 4.923 -4.926a1.224 1.224 0 0 0 -1.732 -1.73zM25.451 26.531l-10.617 10.617a0.294 0.294 0 0 1 -0.416 0L9.581 32.313a0.282 0.282 0 0 1 -0.085 -0.209c0 -0.054 0.014 -0.139 0.085 -0.207l10.617 -10.617c0.118 -0.118 0.306 -0.118 0.416 0l4.839 4.836c0.118 0.118 0.118 0.306 0 0.416"
      fill={props.color || "#4F4F4F"}
    />
    <path
      d="M19.963 23.838c-1.633 0 -2.961 1.33 -2.961 2.961 0 1.636 1.328 2.966 2.961 2.966s2.963 -1.33 2.963 -2.963 -1.33 -2.963 -2.961 -2.963m0 3.483a0.517 0.517 0 0 1 -0.517 -0.517 0.517 0.517 0 0 1 0.517 -0.517 0.517 0.517 0 0 1 0.517 0.517 0.517 0.517 0 0 1 -0.517 0.517"
      fill={props.color || "#4F4F4F"}
    />
    <path
      d="M32.313 12.89a1.495 1.495 0 1 0 0 2.992 1.495 1.495 0 0 0 0 -2.992"
      fill={props.color || "#4F4F4F"}
    />
    <path
      d="M31.349 11.774a1.222 1.222 0 0 0 1.222 -1.222v-2.28a1.222 1.222 0 1 0 -2.444 0v2.28c0 0.674 0.545 1.222 1.222 1.222"
      fill={props.color || "#4F4F4F"}
    />
    <path
      d="M38.728 13.167h-2.28a1.222 1.222 0 1 0 0 2.444h2.28a1.222 1.222 0 1 0 0 -2.444"
      fill={props.color || "#4F4F4F"}
    />
    <path
      d="M35.053 12.526a1.222 1.222 0 0 0 0.865 -0.357l1.61 -1.612a1.224 1.224 0 0 0 -1.73 -1.73l-1.612 1.612a1.224 1.224 0 0 0 0.865 2.087"
      fill={props.color || "#4F4F4F"}
    />
    <path
      d="M27.049 13.31a1.222 1.222 0 0 0 1.732 0 1.222 1.222 0 0 0 0 -1.732l-1.612 -1.612a1.224 1.224 0 0 0 -1.73 1.732z"
      fill={props.color || "#4F4F4F"}
    />
    <path
      d="M35.602 17.136a1.224 1.224 0 0 0 -1.732 1.73l1.612 1.612a1.222 1.222 0 0 0 1.73 0 1.222 1.222 0 0 0 0 -1.732z"
      fill={props.color || "#4F4F4F"}
    />
  </svg>
);
export default SVGComponent;