import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={47}
    height={47}
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m35.05 11.55 -1.95 -1.95c-1.408 -1.408 -3.607 -1.492 -4.91 -0.193L9.472 28.13a1.038 1.038 0 0 0 -0.282 0.54l-1.257 6.746c-0.07 0.376 0.059 0.777 0.346 1.064l0.007 0.007a1.195 1.195 0 0 0 1.071 0.336l6.627 -1.351a1.009 1.009 0 0 0 0.527 -0.282L35.24 16.461c1.302 -1.302 1.217 -3.502 -0.19 -4.912m-3.492 -0.409 1.95 1.95c0.489 0.487 0.517 1.247 0.066 1.699l-1.937 1.939 -3.718 -3.718 1.939 -1.937c0.451 -0.451 1.212 -0.423 1.699 0.066M10.492 34.235l0.871 -4.67L26.38 14.55l3.713 3.718 -15.035 15.035 -4.569 0.93"
      fill={props.color || "#4F4F4F"}
    />
  </svg>
);
export default SVGComponent;
