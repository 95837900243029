import React, { useState, useContext, createContext } from "react";
import IntlMessages from './IntlMessages';
import { Collapse } from "antd";

const popupContext = createContext({});

var infoMessageCount = 0;
var warningMessageCount = 0;
var errorMessageCount = 0;
var errorCatchMessageCount = 0;


export function PopupProvider({ modal, children }) {
    const popup = useProvidePopup();
    return <popupContext.Provider value={popup}>{children}</popupContext.Provider>;
}


export const usePopup = () => {
    return useContext(popupContext);
};


const useProvidePopup = () => {
    const [modal, setModal] = useState(null);

    const confirmPopup = (content, onOk, onCancel) => {
        modal.confirm({
            title: <IntlMessages id="vlink.common.confirm" />,
            centered: true,
            content: <div style={{fontSize: 16}}>{content}</div>,
            okText: <IntlMessages id="vlink.common.ok" />,
            cancelText: <IntlMessages id="vlink.common.cancel" />,
            onOk: () => {
                onOk && onOk();
            },
            onCancel: () => {
                onCancel && onCancel();
            }
        });
    }

    const infoPopup = (content, onOk) => {
        if (infoMessageCount === 1) {
            return;
        }
        infoMessageCount = 1;

        modal.info({
            title: <IntlMessages id="vlink.common.info" />,
            centered: true,
            content: content,
            okText: <IntlMessages id="vlink.common.ok" />,
            onOk: () => { onOk && onOk(); infoMessageCount = 0; }
        });
    }

    const warningPopup = (content, onOk) => {
        if (warningMessageCount === 1) {
            return;
        }
        warningMessageCount = 1;

        modal.warning({
            title: <IntlMessages id="vlink.common.warning" />,
            centered: true,
            content: content,
            okText: <IntlMessages id="vlink.common.ok" />,
            onOk: () => { onOk && onOk(); warningMessageCount = 0; }
        });
    }

    const errorPopup = (content, onOk) => {
        if (errorMessageCount === 1) {
            return;
        }
        errorMessageCount = 1;

        modal.error({
            title: <IntlMessages id="vlink.common.error" />,
            centered: true,
            content: content,
            okText: <IntlMessages id="vlink.common.ok" />,
            onOk: () => { onOk && onOk(); errorMessageCount = 0; }
        });
    }


    const errorCatchPopup = (e, onOk) => {
        if (errorCatchMessageCount === 1) {
            return;
        }
        errorCatchMessageCount = 1;

        modal.error({
            title: <IntlMessages id="vlink.common.error" />,
            centered: true,
            content: <div style={{ paddingTop: 10 }}>
                <div style={{ marginBottom: 10 }}><IntlMessages id="vlink.error.msg" /></div>
                <Collapse items={[
                    {
                        key: '1',
                        label: <span><IntlMessages id="vlink.error.detail" /></span>,
                        children: <>
                            <div>{e.code} : <IntlMessages id={e.message} /></div>
                        </>
                    }
                ]} ghost 
                defaultActiveKey={['1']} 
                size="small"/>
             
            </div>,
            okText: <IntlMessages id="vlink.common.ok" />,
            onOk: () => { onOk && onOk(); errorCatchMessageCount = 0; }
        });
    }


    return {
        confirmPopup,
        infoPopup,
        warningPopup,
        errorPopup,
        errorCatchPopup,
        setModal,

    };
}