import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={35}
    height={35}
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={7.07275} y={7.5} width={20} height={5} rx={1} fill="#B9B8B9" />
    <rect x={7.07275} y={15} width={20} height={5} rx={1} fill="#B9B8B9" />
    <rect x={7.07275} y={22.5} width={20} height={5} rx={1} fill="#B9B8B9" />
  </svg>
);
export default SVGComponent;
