import React, { useEffect, useRef, useState } from 'react';
import { Input, List, Button, Menu } from "antd";
import { useIntl } from "react-intl";

import { useAuth } from "src/authentication";
import IntlMessages from "src/util/IntlMessages";

import PersonalSettings from './PersonalSettings';
import SecuritySettings from './SecuritySettings';
import SoftwareSettings from './SoftwareSettings';
import AuthoritySettings from './AuthoritySettings';
import AccountSettings from './AccountSettings';
import RemoteAgents from './RemoteAgents';

import "./index.css";
import LoginHistory from './LoginHistory';

const Settings = () => {
  const INIT_KEY = 'personal';
  const [focus, setFocus] = useState(false);
  const intl = useIntl();
  const { authUser, getAuthUser } = useAuth();
  const [menuItems, setMenuItems] = useState([]);
  const refCurrentUser = useRef({});

  const [selectedKeys, setSelectedKeys] = useState(INIT_KEY);


  // useEffect(() => {

  //   console.log("selectedKeys", selectedKeys)
  //   if (focus) {
  //     getAuthUser();
  //   }

  // }, [selectedKeys]);

  useEffect(() => {
    const temp = [
      {
        key: 'userInfo',
        label: <IntlMessages id="vlink.settings.userInfo" />,
        children: [
          {
            key: 'personal',
            label:  <IntlMessages id="vlink.settings.personal"/>,
          },
          {
            key: 'security',
            label: <IntlMessages id="vlink.settings.security"/>,
          },
          {
            key: 'authority',
            label: <IntlMessages id="vlink.settings.authority"/>,
          },
        ],
      },
      {
        key: 'etc',
        label: <IntlMessages id="vlink.settings.etc"/>,
        children: [
          {
            key: 'sw',
            label: <IntlMessages id="vlink.settings.sw"/>,
          },
        ],
      }
    ];

    if (authUser?.is_host) {
      temp.splice(1, 0, {
        key: 'account',
        label: <IntlMessages id="vlink.settings.account"/>,
        children: [
          {
            key: 'accountSetting',
            label: <IntlMessages id="vlink.settings.account.setting"/>,
          },
          {
            key: 'loginHistory',
            label: <IntlMessages id="vlink.settings.loginHistory"/>,
          },
        ],
      })

      temp.splice(2, 0, {
        key: 'remoteAgent',
        label: <IntlMessages id="vlink.settings.remoteAgent"/>,
        children: [
          {
            key: 'remoteAgentSetting',
            label: <IntlMessages id="vlink.settings.remoteAgent.setting"/>,
          },
        ],
      })
    }
    setMenuItems(temp);

    if (authUser?.id !== refCurrentUser?.current?.id) {
      setSelectedKeys(INIT_KEY)
    }

    refCurrentUser.current = authUser;
  }, [authUser])

  useEffect(() => {
    setFocus(true);
  }, [])

  const onClick = (e) => {

    setSelectedKeys(e.key);
  };

  let contentComponent;
  switch (selectedKeys) {
    case 'personal':
      contentComponent = <PersonalSettings />;
      break;
    case 'security':
      contentComponent = <SecuritySettings />;
      break;
    case 'authority':
      contentComponent = <AuthoritySettings />;
      break;
    case 'accountSetting':
      contentComponent = <AccountSettings />;
      break;
    case 'loginHistory':
      contentComponent = <LoginHistory />;
      break;
    case 'remoteAgentSetting':
      contentComponent = <RemoteAgents />;
      break;
    case 'sw':
      contentComponent = <SoftwareSettings />;
      break;
    default:
      contentComponent = null;
      break;
  }


  // 연결되었음.
  return (
    <div style={{
      height: "100%",
      display: "flex",
      flexDirection: "row",
      margin: 40,
      gap: 30,
      overflow: "auto",
    }}>
      <div style={{
        width: 282,
        backgroundColor: "#fff",
        borderRadius: 8,
        overflow: 'hidden',
      }}>
        <div style={{
          width: "100%",
          height: 70,
          paddingLeft: 28,
          alignItems: "center",
          backgroundColor: "#fff",
          borderBottom: "1px solid #DCDCDC",
          fontSize: 20,
          fontWeight: 700,
          display: "flex",
        }}>
          <IntlMessages id="vlink.common.gnb.settings" />
        </div>
        <Menu
          onClick={onClick}
          style={{
            width: "100%",
          }}
          mode="inline"
          className='settings-menu'
          items={menuItems}
          defaultOpenKeys={['userInfo']}
          selectedKeys={selectedKeys}
          defaultSelectedKeys={['personal']}
        />
      </div>
      <div style={{
        flex: 1,
      }}>
        {contentComponent}
      </div>

    </div>
  )
}


export default Settings;
