import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.7857 16.2823C12.3853 16.7908 11.6146 16.7908 11.2142 16.2823L7.03888 10.9789C6.52243 10.3229 6.98971 9.36027 7.82459 9.36027L16.1753 9.36027C17.0102 9.36027 17.4775 10.3229 16.961 10.9789L12.7857 16.2823Z"
      fill={props.color || "#4F4F4F"}
    />
  </svg>
);
export default SVGComponent;
