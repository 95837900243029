import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, Checkbox, Flex, Form, Input, Typography, Modal, Row } from "antd";
import { useIntl } from "react-intl";
import { Buffer } from 'buffer';

import IntlMessages from "src/util/IntlMessages";
import { useAuth } from "src/authentication";
import CircularProgress from "src/components/CircularProgress";
import beApis from "../../../util/apis/be";
import CircularProgress2 from "src/components/CircularProgress2";
import { usePopup } from "src/util/use-popup";

import "./index.css";

const { info, error } = Modal;

const Step3 = ({email}) => {
  const intl = useIntl();

  const [focus, setFocus] = useState(false);


  const navigate = useNavigate();


  return (
    <>
      {/* 
      <Flex justify="center" align="center" style={{ height: 86 }}>
        <Typography.Title style={{ color: "#df043a" }}><IntlMessages id="vlink.signup.addInfo" /></Typography.Title>
      </Flex> */}

      <div className="gx-app-login-content" style={{ width: "80%" }}>
        <div style={{ height: 380, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
          <span style={{fontSize: 24, fontWeight: 500, marginBottom: 20}}><IntlMessages id="vlink.signup.welcome1" /></span>
          <span style={{fontSize: 24, fontWeight: 500, marginBottom: 20}}><IntlMessages id="vlink.signup.welcome2" /></span>
          <span style={{fontSize: 24, fontWeight: 500, textDecoration: "underline"}}>{email}</span>
        </div>

        <Row style={{ gap: "10px" }}>
          <Button className="gx-mb-0"
            type="primary"
            style={{
              height: 46,
              flex: 1,
              borderRadius: 4,
              // backgroundColor: '#DF043A',
              fontSize: 18,
              fontWeight: 700,
            }}
            onClick={() => navigate('/user/signin')}
          >
            <IntlMessages id="vlink.signup.vlinkStart" />
          </Button>
        </Row>
      </div>
    </>
  );
};

export default Step3;
