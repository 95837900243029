import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Popover, Tag } from "antd";
import { SettingOutlined } from "@ant-design/icons";


import ArrowDown from "src/assets/svg/ArrowDown";
import Logout from "src/assets/svg/Logout";
import { useAuth } from "src/authentication";
import StyledFirstCircle from "src/components/StyledFirstCircle";
import StyledHostTag from "src/components/StyledHostTag";
import StyledTooltip from "src/components/StyledTooltip";
import IntlMessages from "src/util/IntlMessages";

import './index.css';

const UserProfile = () => {
  const { authUser, userSignOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [menuOptions, setMenuOptions] = useState([]);

  const onLogoutClick = () => {
    userSignOut(() => {
      navigate('/user/signin');
    });
  }

  useEffect(() => {
    if (location && location.pathname) {
      const gnbName = location.pathname.split('/')[1];
      const lnbName = location.pathname.split('/')[2];

      if (gnbName === "user") {
        setMenuOptions(
          <ul style={{ listStyle: "none", padding: 0, marginBottom: 0 }}>
            <li
              style={{ cursor: "pointer", display: "flex", }}
              onClick={onLogoutClick}>
              <Button
                style={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                  padding: "0 30px 0 10px"
                }}
                type={'text'}
                icon={<Logout />}
                onClick={onLogoutClick}>
                <span style={{
                  // padding: '0 30px 0 0',
                }}>
                  <IntlMessages id="vlink.userInfo.logout" />
                </span>
              </Button>
            </li>
          </ul>
        )
      }
      else {
        setMenuOptions(
          <ul style={{ listStyle: "none", padding: 0, marginBottom: 0 }}>
          <li
            style={{ cursor: "pointer", display: "flex" }}
            onClick={() => navigate("/settings")}>
            <Button
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                padding: "0 30px 0 10px"
              }}
              type={'text'}
              icon={<SettingOutlined style={{ fontSize: 20, color: "#4f4f4f" }} />}
            >
              <span style={{
                // padding: '0 30px 0 0',
              }}>
                <IntlMessages id="vlink.userInfo.settings" />
              </span>
            </Button>
          </li>
          <li
            style={{ cursor: "pointer", display: "flex", }}
            onClick={onLogoutClick}>
            <Button
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                padding: "0 30px 0 10px"
              }}
              type={'text'}
              icon={<Logout />}
              onClick={onLogoutClick}>
              <span style={{
                // padding: '0 30px 0 0',
              }}>
                <IntlMessages id="vlink.userInfo.logout" />
              </span>
            </Button>
          </li>
        </ul>
        )


      }
    }
  }, [location])


  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginLeft: 16,
        marginRight: 16,
      }}>
      {/*FIXME 20240511: open={false}*/}
      <StyledTooltip
        // open={false}
        // placement="bottomRight"
        content={menuOptions}
      >
        <span style={{ cursor: "pointer", display: "flex", alignItems: "center", height: 60 }}>
          <div
            className={'user-profile-right'}
            style={{
              width: 8,
            }}
          />
          <div
            style={{
              marginLeft: 8,
              marginRight: 8,
            }}
          >
            <StyledFirstCircle text={authUser?.name || ''} size={36} />
          </div>
          <span style={{ marginLeft: 8 }} className={'user-profile-right'}>
            {authUser?.name}
          </span>
          <span className={'user-profile-right'}>
            <StyledHostTag isHost={authUser?.is_host} />
          </span>
          <div
            className={'user-profile-right'}
            style={{
              marginLeft: 4,
              marginRight: 16,
            }}
          >
            <ArrowDown style={{ display: 'flex' }} />
          </div>
        </span>
      </StyledTooltip>
    </div>
  )
};

export default UserProfile;
