import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={47}
    height={47}
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="path-1-inside-1_2680_9362" fill="white">
      <path d="M0 0h47v47H0z" />
    </mask>
    <path
      d="M45.825 0v47h2.35V0z"
      fill="#DCDCDC"
      mask="url(#path-1-inside-1_2680_9362)"
    />
    <path
      d="M23.5 30.746h5.354a5.356 5.356 0 0 0 0 -10.711H13.979"
      stroke="#4F4F4F"
      strokeWidth={1.7624999999999997}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m17.55 15.275 -4.761 4.76 4.761 4.761"
      stroke="#4F4F4F"
      strokeWidth={1.7624999999999997}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;