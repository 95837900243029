import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={18} cy={18} r={17.5} fill="white" stroke="#DCDCDC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9002 12.6002C18.9002 12.1031 18.4973 11.7002 18.0002 11.7002C17.5031 11.7002 17.1002 12.1031 17.1002 12.6002V17.1002H12.6002C12.1031 17.1002 11.7002 17.5031 11.7002 18.0002C11.7002 18.4973 12.1031 18.9002 12.6002 18.9002H17.1002V23.4002C17.1002 23.8973 17.5031 24.3002 18.0002 24.3002C18.4973 24.3002 18.9002 23.8973 18.9002 23.4002V18.9002H23.4002C23.8973 18.9002 24.3002 18.4973 24.3002 18.0002C24.3002 17.5031 23.8973 17.1002 23.4002 17.1002H18.9002V12.6002Z"
      fill="#181818"
    />
  </svg>
);
export default SVGComponent;
