import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

const antIcon = <Loading3QuartersOutlined style={{ fontSize: 28, color: '#fff' }} spin/>;

const CircularProgress2 = () => <Spin indicator={antIcon} />;
export default CircularProgress2;
CircularProgress2.defaultProps = {
  className: ''
}
