import React, {useEffect} from "react";
import {Provider, useSelector} from 'react-redux'
// import {ConnectedRouter} from 'connected-react-router'
import {BrowserRouter as Router} from "react-router-dom";
import 'antd/dist/reset.css';
import "assets/vendors/style";
import "assets/fonts/pretendard.css";
import "assets/fonts/SpoqaHanSansNeo.css";

import configureStore from './appRedux/store';
import App from "./containers/App/index";
import {AuthProvider, useAuth} from "./authentication";
import CSContainer from "./containers/CSContainer";
import CircularProgress3 from "./components/CircularProgress3";
import {notification} from 'antd';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";

const store = configureStore();

const AppWrapper = () => {

  const [api, contextHolder] = notification.useNotification({maxCount: 5});
  const csSelected = useSelector(({ cs }) => cs.csSelected);

  useEffect(() => {
    onMessageFCM().then(() => { })
  }, []);

  // useEffect(() => {
  //
  //   console.log('AppWrapper - cs', csSelected)
  //
  // }, [csSelected]);

  const onMessageFCM = async () => {
    // 브라우저에 알림 권한을 요청합니다.
    console.log('Requesting permission...');
    const permission = await Notification.requestPermission()
    if (permission !== 'granted') return;

    const firebaseConfig = {
      apiKey: "AIzaSyCznweCvgOaRuEcP0XSmcBN0Mgwpw08QRw",
      authDomain: "vatechlink.firebaseapp.com",
      projectId: "vatechlink",
      storageBucket: "vatechlink.appspot.com",
      messagingSenderId: "931104104578",
      appId: "1:931104104578:web:19b08a3307da2e4b0e949b",
      measurementId: "G-28RHBSGH27"
    };

    const vapid_key = "BC82xvDK1ITFRQPFeqI2U0YQz3nsCES0C3PHoAzyMxCUcp3oG57eN8xeRtNMwUDC4g96XZ_Vo2qp552gO_nCx-o";
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    getToken(messaging, { vapidKey: vapid_key }).then((currentToken) => {
      if (currentToken) {
        // 정상적으로 토큰이 발급되면 콘솔에 출력합니다.
        // console.log("fcmToken", currentToken)
        localStorage.setItem("fcm_token", currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.')
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
    })

    // 메세지가 수신되면 역시 콘솔에 출력합니다.
    onMessage(messaging, (payload) => {
      console.log('[firebase] Message received. ', payload)
      if (payload.data?.title ) {
        api.success({message: payload.data.title, description: payload.data.body, style: {border: "1px solid black"}, duration: 0, placement: "bottomRight"});

      }
    })
  }

  if (csSelected) {
    return <App />
  } else {
    return <CircularProgress3 />
  }
}
const NextApp = () => {

  return <Provider store={store}>
    <Router>
      <AuthProvider>
        <CSContainer />
        <AppWrapper />
      </AuthProvider>
    </Router>
  </Provider>;
}


export default NextApp;
