import React, { Fragment, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import DataChannel from "../../containers/WebRTC/DataChannel";
import { useNavigate } from "react-router-dom";
import IntlMessages from 'src/util/IntlMessages';
import config from 'src/constants/Config';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { appCustomName } from 'src/util/common';


const Connecting = ({ dcConnected, onCancel }) => {

  const agentSelected = useSelector(({ cs }) => cs.agentSelected);

  // console.log('agentSelected', agentSelected)

  return <div style={{
    background: '#222222',
    flex: 1,
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    color: "",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }}>
    <div style={{ color: "#fff", width: "50%", textAlign: "center" }}>
      <div style={{ fontSize: 28, fontWeight: 700 }}>{agentSelected?.custom_name}<span style={{ marginLeft: 10 }}>{appCustomName(agentSelected?.app.title)}</span></div>
      <div style={{ fontSize: 18, fontWeight: 400, marginTop: 40, }}><IntlMessages id="stream.connecting" /></div>
      <LoadingOutlined style={{ fontSize: 40, color: dcConnected ? config.colors.lightishGreen : config.colors.sunflowerYellow, marginTop: 20 }} />

      <div style={{ marginTop: 120, cursor: "pointer" }}>
        <Button onClick={onCancel} ><IntlMessages id="vlink.common.cancel" /></Button>

      </div>
    </div>

  </div>
}


export default Connecting;
