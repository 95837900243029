import React from 'react';
import {Tag} from 'antd';

const StyledHostTag = ({isHost, style}) => {

  if (isHost) {
    return <Tag color="#FFCFDC" style={{ display: 'revert', color: '#DF043A', marginLeft: 8, ...style}}>Host</Tag>
  }

  return null
};

export default StyledHostTag;
