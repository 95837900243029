import React from 'react';
import {Card, List, Typography} from "antd";
import './index.css';
import IntlMessages from "../../../util/IntlMessages";
import Add from "../../../assets/svg/Add";

const {Text} = Typography;

const AgentCardAddItem = (props) => {

  const {tab, onClick} = props
  return (
    <List.Item>
      <Card
        className={'agent-card-add-item'}
        hoverable
        bordered
        style={{
          // border: "1px solid #DCDCDC",
          // borderColor: '#EFEFEF',
          borderRadius: 6,
          height: 136,
          // margin: 5,
          // padding: 10,
          backgroundColor: '#EFEFEF',
        }}
        onClick={() => {
          onClick && onClick(undefined, 'add', tab)
        }}
      >
        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}>
            <Add/>
          </div>
          <div style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            // alignItems: 'center',
            marginTop: 8,
          }}>
            <Text style={{
              fontSize: 16,
              color: '#7E7E7E',
            }}>
              <IntlMessages id="home.pc.add.remotePc"/>
            </Text>
          </div>
        </div>
      </Card>
    </List.Item>
  );
};

export default AgentCardAddItem;
