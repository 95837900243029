import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 8C13.3726 8 8 13.3726 8 20C8 26.6274 13.3726 32 20 32C26.6274 32 32 26.6274 32 20C32 13.3726 26.6274 8 20 8ZM15.8 17C15.8 14.6804 17.6804 12.8 20 12.8C22.3196 12.8 24.2 14.6804 24.2 17C24.2 19.3196 22.3196 21.2 20 21.2C17.6804 21.2 15.8 19.3196 15.8 17ZM27.5095 25.9812C25.7505 28.1866 23.0403 29.6 20 29.6C16.9597 29.6 14.2495 28.1866 12.4905 25.9812C14.4356 24.5853 17.0899 23.6 20 23.6C22.9101 23.6 25.5644 24.5853 27.5095 25.9812Z"
      fill="#7E7E7E"
    />
  </svg>
);
export default SVGComponent;
