import React from 'react';
import {Button, ConfigProvider} from 'antd';
import './index.css';

/**
 * antd Button에 스타일을 수정한 컴포넌트입니다.
 */

import { TinyColor } from '@ctrl/tinycolor';
const colors1 = ['#6253E1', '#04BEFE'];
const colors2 = ['#fc6076', '#ff9a44', '#ef9d43', '#e75516'];
const colors3 = ['#40e495', '#30dd8a', '#2bb673'];
const getHoverColors = (colors) =>
  colors.map((color) => new TinyColor(color).lighten(5).toString());
const getActiveColors = (colors) =>
  colors.map((color) => new TinyColor(color).darken(5).toString());
const OutlineButton = ({size, label, ...props}) => {

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#B9B9B9',
        },
        // components: {
        //   Button: {
        //     colorPrimary: `#ff0000`,
        //     colorPrimaryHover: `#000000`,
        //     colorPrimaryActive: `#000000`,
        //     // lineWidth: 0,
        //   },
        // },
      }}
    >
      <Button
        className={'outline-button'}
        style={{
          lineHeight: '16px',
          fontSize: '16px',
          // color: '#DF053A',
          backgroundColor: '#FFFFFF',
          // border: '1px solid #B9B9B9',
          borderRadius: 4,
        }}

        type={'primary'}
        size={size}
        {...props}
      >
        {label}
      </Button>
    </ConfigProvider>
  );
};

export default OutlineButton;
