import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5789 4.58506C11.7756 4.27785 12.2244 4.27785 12.4211 4.58506L14.4309 7.72423C14.6343 8.04207 14.9502 8.27152 15.3153 8.36683L18.9219 9.30817C19.2749 9.4003 19.4135 9.82715 19.1822 10.1091L16.8177 12.9906C16.5783 13.2823 16.4576 13.6536 16.4798 14.0303L16.6991 17.7513C16.7205 18.1154 16.3574 18.3793 16.0177 18.2463L12.5466 16.888C12.1952 16.7505 11.8048 16.7505 11.4534 16.888L7.98228 18.2463C7.64259 18.3793 7.27948 18.1154 7.30093 17.7513L7.52016 14.0303C7.54236 13.6536 7.42173 13.2823 7.18233 12.9906L4.81785 10.1091C4.58645 9.82715 4.72514 9.4003 5.0781 9.30817L8.68468 8.36683C9.04984 8.27152 9.36566 8.04207 9.56914 7.72423L11.5789 4.58506Z"
      stroke="#B9B8B9"
    />
  </svg>
);
export default SVGComponent;
