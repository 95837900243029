import React from 'react';
import {Card, List, Typography} from "antd";
import './index.css';
import OutlineButton from "../../components/OutlineButton";
import IntlMessages from "../../util/IntlMessages";

const {Text} = Typography;

const AgentItem = (props) => {

  const {item, onClick} = props

  return (
    <List.Item>
      <div><Text style={{fontSize: 16}}>{item.custom_name}</Text></div>
      <div style={{
        display: 'flex',
        alignItems: "center",
      }}>
        <OutlineButton
          label={<IntlMessages id="home.pc.add"/>}
          onClick={() => {
            console.log("AgentItem click", item)
            props.onClick && props.onClick(item)
          }}
        />
      </div>
    </List.Item>
  );
};

export default AgentItem;
