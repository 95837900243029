import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={114}
    height={114}
    viewBox="0 0 114 114"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={57} cy={57} r={53} stroke="#ECE8E9" strokeWidth={8} />
    <path
      d="M110 57C110 27.7289 86.2711 4 57 4"
      stroke="url(#paint0_linear_889_10801)"
      strokeWidth={6}
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_889_10801"
        x1={83.5}
        y1={4}
        x2={105}
        y2={57}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FA6C8F" />
        <stop offset={1} stopColor="#DF043A" />
      </linearGradient>
    </defs>
  </svg>
);
export default SVGComponent;
