import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Flex, Form, Input, Typography, Modal, Row } from "antd";
import { useIntl } from "react-intl";

import IntlMessages from "src/util/IntlMessages";
import { useAuth } from "src/authentication";
import CircularProgress from "src/components/CircularProgress";
import beApis from "../../../util/apis/be";
import CircularProgress2 from "src/components/CircularProgress2";
import { usePopup } from "src/util/use-popup";

import "./index.css";


const { info, error } = Modal;

const SignUp = (props) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const [email, setEmail] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const csSelected = useSelector(({ cs }) => cs.csSelected);
  const navigate = useNavigate();
  const { errorCatchPopup, infoPopup } = usePopup();

  const onFinish = values => {

    const req = {
      email: values.email,
      code: values.code,
      etc: {
        clinic_name: values.clinic_name
      }
    }

    setIsLoading(true);
    beApis.newMemberJoin(csSelected.host, req).then(() => {
      infoPopup(
        <IntlMessages id="vlink.signUp.successSignUp" />,
        () => { navigate('/user/signin') }
      )
      setIsLoading(false);

    }).catch((e) => {
      errorCatchPopup(
        e.response.data,
        () => { }
      );
      setIsLoading(false);

    });


  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 678 }}>
          <Flex justify="center" align="center" style={{ height: 86 }}>
            <Typography.Title style={{ color: "#df043a" }}><IntlMessages id="vlink.signup" /></Typography.Title>
          </Flex>

          <div className="gx-app-login-content" style={{ width: "80%" }}>
            <Form
              form={form}
              initialValues={{ rememberMe: true }}
              name="basic"
              onFinish={onFinish}
              className="gx-signin-form gx-form-row0"
              style={{ marginBottom: 7 }}>
              <Form.Item
                initialValue=""
                label={intl.formatMessage({ id: 'vlink.signup.clinicName' })}
                labelCol={{ span: 8 }}
                required
                rules={[{ required: true }]} name="clinic_name"
                style={{ marginBottom: 20 }}>
                <Input
                  style={{
                  }} placeholder={intl.formatMessage({ id: 'vlink.signup.clinicName' })}/>
              </Form.Item>
              <Form.Item
                initialValue=""
                rules={[{ required: true }]}
                name="email"
                label={intl.formatMessage({ id: 'vlink.signup.email' })}
                labelCol={{ span: 8 }}
                style={{ marginBottom: 20 }}>
                <Input
                  style={{
                  }} placeholder={intl.formatMessage({ id: 'vlink.signup.email' })} />
              </Form.Item>
              <Form.Item
                initialValue=""
                rules={[{ required: true }]}
                name="code"
                label={intl.formatMessage({ id: 'vlink.signup.code' })}
                labelCol={{ span: 8 }}
                style={{ marginBottom: 30 }}>
                <Input
                  style={{
                  }}
                  placeholder={intl.formatMessage({ id: 'vlink.signup.code' })}
                />
              </Form.Item>

              <Row style={{ gap: "10px" }}>
                <Button className="gx-mb-0"
                  disabled={isLoading}
                  style={{
                    height: 46,
                    flex: 1,
                    borderRadius: 4,
                    // backgroundColor: '#DF043A',
                    fontSize: 18,
                    fontWeight: 700,
                  }}
                  onClick={() => navigate('/user/signin')}
                >

                  <IntlMessages id="vlink.button.cancel" />
                </Button>
                <Button type="primary" className="gx-mb-0" htmlType="submit"
                  disabled={isLoading}
                  style={{
                    height: 46,
                    flex: 1,
                    borderRadius: 4,
                    backgroundColor: '#DF043A',
                    fontSize: 18,
                    fontWeight: 700,
                  }}>

                  {!isLoading ? <IntlMessages id="vlink.signup.send" /> : <CircularProgress2 />}
                </Button>
              </Row>
              <Form.Item >
                {/* <Button type="link"
                        style={{
                          width: '100%',
                          color: '#7E7E7E',
                        }}
                        >
                </Button> */}
              </Form.Item>

              {/*<Row style={{borderBottom: '1px solid #5e5e5e'}}/>*/}
              {/* <span
                className="gx-text-light gx-fs-sm"> demo user email: 'demo@example.com' and password: 'demo#123'</span> */}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
