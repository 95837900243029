import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.3333 6.9598C17.7015 6.9598 18 7.28216 18 7.6798C18 8.07745 17.7015 8.3998 17.3333 8.3998H16.6667L16.665 8.45113L16.0425 17.8624C15.9927 18.616 15.4121 19.1998 14.7126 19.1998H9.28744C8.58793 19.1998 8.00733 18.616 7.95749 17.8624L7.33505 8.45113C7.33392 8.43392 7.33335 8.4168 7.33333 8.3998H6.66667C6.29848 8.3998 6 8.07745 6 7.6798C6 7.28216 6.29848 6.9598 6.66667 6.9598H17.3333ZM15.3317 8.3998H8.66839L9.28744 17.7598H14.7126L15.3317 8.3998ZM13.3333 4.7998C13.7015 4.7998 14 5.12216 14 5.5198C14 5.91745 13.7015 6.2398 13.3333 6.2398H10.6667C10.2985 6.2398 10 5.91745 10 5.5198C10 5.12216 10.2985 4.7998 10.6667 4.7998H13.3333Z"
      fill="#4F4F4F"
    />
  </svg>
);
export default SVGComponent;
