import {Card, List, Table, Typography} from "antd";
import CircularProgress3 from "../../../components/CircularProgress3";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import IntlMessages from "../../../util/IntlMessages";
import StyledHostTag from "../../../components/StyledHostTag";
import StyledFirstCircle from "../../../components/StyledFirstCircle";
import OutlineButton from "../../../components/OutlineButton";
import './index.css';

const {Text} = Typography;

const UserListView = (props) => {

  const subUserList = useSelector(({cs}) => cs.subUserList);
  const [data, setData] = useState([])

  const columns = [
    {
      title: <IntlMessages id="vlink.page.selectUser.number"/>,
      dataIndex: 'no',
      key: 'no',
      width: 80,
      sorter: (a, b) => a.no > b.no,
      responsive: ['lg'],
    },
    {
      title: <IntlMessages id="vlink.page.selectUser.username"/>,
      dataIndex: 'name',
      key: 'name',
      render: (_, {name, is_host}) => (
        <div style={{ display: 'flex', flexDirection: 'row'}}>
          <StyledFirstCircle size={20} text={name}/>
          <Text style={{paddingLeft: 8, paddingRight: 8}}>{name}</Text>
          <StyledHostTag isHost={is_host}/>
        </div>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      render: (_, record) => (
        <div style={{
          display: 'flex',
          alignItems: "center",
        }}>
          <OutlineButton
            label={<IntlMessages id="vlink.common.shortConnect"/>}
            onClick={() => {
              props.onClick && props.onClick(record)
            }}
          />
        </div>
      )
    },
    // {
    //   title: 'Tags',
    //   key: 'tags',
    //   dataIndex: 'tags',
    //   render: (_, { tags }) => (
    //     <>
    //       {tags.map((tag) => {
    //         let color = tag.length > 5 ? 'geekblue' : 'green';
    //         if (tag === 'loser') {
    //           color = 'volcano';
    //         }
    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <a>Invite {record.name}</a>
    //       <a>Delete</a>
    //     </Space>
    //   ),
    // },
  ];

  useEffect(() => {
    if (subUserList && subUserList.length) {
      setData(subUserList.reduce((acc, cur, idx) => {
        acc.push({
          ...cur,
          no: idx + 1,
        })
        return acc
      }, []))
    }
  }, [subUserList]);

  return (
    <Table
      className={'user-list'}
      columns={columns}
      dataSource={data}
      pagination={{position:['bottomCenter']}}
      locale={{
        emptyText: (
          <CircularProgress3/>
        )
      }}
    />
  )
}

export default UserListView;
