import antdDE from "antd/locale/de_DE";
import saMessages from "../locales/de_DE.json";

const deLang = {
  messages: {
    ...saMessages
  },
  antd: antdDE,
  locale: 'de-DE',
};
export default deLang;
