import React, { useState, useEffect } from "react";
import { Layout, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { usePopup } from "util/use-popup";

import App from "../index";
import { setXdata, updateWindowWidth } from "../../appRedux/actions";

import beApis from "src/util/apis/be";
import SignUpProcess from "../userAuth/SignUpProcess";
import ResetPassword from "../userAuth/ResetPassword";

const QuickLink = () => {
  const dispatch = useDispatch();
  const [focus, setFocus] = useState(false);
  const csSelected = useSelector(({ cs }) => cs.csSelected);
  const param = useParams();
  const navigate = useNavigate();
  const { errorCatchPopup, infoPopup } = usePopup();

  const [contentComponent, setContentComponent] = useState();
 
  useEffect(() => {
    setFocus(true);
  }, [])



  useEffect(() => {
    if (focus && param) {
      const uuid = param['*'];
      if (uuid) {
        beApis.validateQuickLink(csSelected.host, { uuid }).then((resp) => {

          if (resp.type === "signup_email_verification") {
            dispatch(setXdata(resp));
            navigate('/user/signup-process');
          // setContentComponent(<SignUpProcess xData={resp}/>)
          } else if (resp.type === "forgot_password") {
            setContentComponent(<ResetPassword xData={resp} uuid={uuid}/>)
            // navigate('/user/forgotPassword');
          }
        }).catch((e) => {
          errorCatchPopup(
            e.response.data,
            () => { navigate('/user/signin') }
          );
        });
      }
    }
  }, [focus])

  return (
    <>
      {contentComponent && contentComponent}
    </>
  )
};
export default QuickLink;

