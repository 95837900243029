import antdRU from "antd/locale/ru_RU";
import enMessages from "../locales/ru_RU.json";

const ruLang = {
  messages: {
    ...enMessages
  },
  antd: antdRU,
  locale: 'ru-RU',
};
export default ruLang;
