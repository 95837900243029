import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={47}
    height={47}
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m39.064 19.712 -9.4 -9.424a3.032 3.032 0 0 0 -4.282 0l-15.096 15.134a3.046 3.046 0 0 0 0 4.296l7.473 7.492s0.028 0.021 0.038 0.033c0.214 0.219 0.51 0.357 0.839 0.357h18.166a1.17 1.17 0 0 0 0 -2.343h-8.961l11.224 -11.254a3.046 3.046 0 0 0 0 -4.291m-19.949 15.545L11.938 28.059a0.698 0.698 0 0 1 0 -0.98l8.93 -8.954 10.378 10.406L24.534 35.25H19.115zM37.412 22.349l-4.517 4.528 -10.378 -10.406 4.517 -4.526a0.693 0.693 0 0 1 0.978 0l9.4 9.424a0.691 0.691 0 0 1 0 0.978z"
      fill={props.color || "#4F4F4F"}
    />
  </svg>
);
export default SVGComponent;
