import './index.css';
import React from "react";
import {Button, ConfigProvider} from "antd";


const StyledMenuOptions = (props) => {
  return (
    <ul style={{listStyle: "none", padding: 0, marginBottom: 0}}>
      {props.options.map((item, idx) => {
        return (
          <li
            key={"option"+idx}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: 'center',
            }}
          >
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorBgTextHover: '#FFE9EF',
                    colorBgTextActive: '#FACDD9',
                  },
                },
              }}
            >
            <Button
              className={'menu-options-item'}
              style={{
                flex: 1,
                display: "flex",
                alignItems: 'center',
                paddingLeft: 0,
                paddingRight: 0,
              }}
              type={'text'}
              onClick={() => {
                props.onClick && props.onClick(item)
              }}>
              <div
                style={{
                  // flex: 1,
                  // cursor: "pointer",
                  display: "flex",
                  alignItems: 'center',
                  paddingRight: 32,
                }}>
                {item.icon ? <div
                  style={{
                    display: "flex",
                  }}>{item.icon}</div> : null}
                <div
                  style={{
                    display: "flex",
                  }}>{item.label}</div>
              </div>
            </Button>
            </ConfigProvider>
          </li>
        )
      })}
    </ul>
  )
}

export default StyledMenuOptions;
