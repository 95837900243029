import antdCN from "antd/locale/zh_CN";
import enMessages from "../locales/zh_CN.json";

const zhCnLang = {
  messages: {
    ...enMessages
  },
  antd: antdCN,
  locale: 'zh-CN',
};
export default zhCnLang;
