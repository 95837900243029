import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from "../../authentication";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress3 from "../../components/CircularProgress3";
import {
  updateActiveAgents,
  updateAgents,
  updateAgentSelected,
  updateDeviceAccessToken,
  updateLayouts,
  updatePublicIP,
  updateSubUserList,
  updateWsSend
} from "../../appRedux/actions";
import { v4 } from "uuid";
import beApis from "../../util/apis/be";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import AtomicPopup from "../../components/AtomicPopup";
import IntlMessages from "../../util/IntlMessages";
import { Input, List, Button, Menu, Avatar, Space, Card, Switch, Select, TimePicker } from "antd";
import StyledFirstCircle from 'src/components/StyledFirstCircle';
import config from "constants/Config";
import Information from "src/assets/svg/Information";

const SoftwareSettings = () => {
  const { authUser, userSignOut } = useAuth();

  const [needUpdate, setNeedUpdate] = useState(false);

  // console.log("authUSer", authUser)

  const FormItem = ({ label, center, control }) => {
    return <div style={{ height: 70, display: "flex", alignItems: "center" }}>
      <div style={{ width: 200, height: 32, lineHeight: "32px", fontSize: 20, fontWeight: 700 }}>{label}</div>
      {center}
      <div style={{ flex: 1, height: 32, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        {control}
      </div>
    </div>
  }
  const updateMessage = () => {

    if (needUpdate) {
      return <>
      <Information color="#DF043A" style={{marginRight: 10}} />
      <IntlMessages id="vlink.settings.sw.updateNeededMessage" />
      </>
    }
    else {
      return <IntlMessages id="vlink.settings.sw.latestVersionMessage" />
    }
  }


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20, height: "100%" }}>

      <Card title={<IntlMessages id="vlink.settings.sw" />} className="settings-card">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <FormItem 
            label={<IntlMessages id="vlink.settings.sw.vlink" />}
            center={<div style={{display: "flex", gap: 20, }}>
              <div>
                <span style={{marginRight: 10, fontWeight: 700}}>Ver. {config.package.version}</span>
              </div>
              <div style={{display: "flex", alignItems: "center"}}>
                {/* {updateMessage()} */}
              </div>
            </div>}
            // control={<Button danger={needUpdate} disabled={!needUpdate}><IntlMessages id="vlink.settings.sw.update" /></Button>} 
            />
        </div>

      </Card>
    </div>
  )

}

export default SoftwareSettings;