import antdES from "antd/locale/es_ES";
import saMessages from "../locales/es_ES.json";

const esLang = {
  messages: {
    ...saMessages
  },
  antd: antdES,
  locale: 'es-ES',
};
export default esLang;
