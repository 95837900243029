import React from "react";
import { Button, Popover } from "antd";
import KeypadDelete from "src/assets/svg/KeypadDelete";

import "./index.css";


const Pinpad = (props) => {

  const {onClick} = props;

  const PinButton = ({ value, onClick, style }) => {
    return <Button onClick={() => onClick && onClick(value)} style={{ width: 44, height: 40, radius: 4, fontSize: 16, fontWeight: 400, ...style }}>{value >= 0? value: <KeypadDelete/> }</Button>
  }

  return (
    <Popover
      overlayStyle={{
        border: '1px solid #B9B9B9',
        borderRadius: 8,
        ...props.style,
      }}
      placement="bottom"
      trigger="click"
      overlayClassName="popover-pinpad"
      content={<div style={{ width: 294, height: 85, display: "flex", gap: 6, flexWrap: "wrap" }}>
        <PinButton value={"1"} onClick={onClick}/>
        <PinButton value={"2"} onClick={onClick}/>
        <PinButton value={"3"} onClick={onClick}/>
        <PinButton value={"4"} onClick={onClick}/>
        <PinButton value={"5"} onClick={onClick}/>
        <PinButton value={"6"} onClick={onClick}/>
        <PinButton value={"7"} onClick={onClick}/>
        <PinButton value={"8"} onClick={onClick}/>
        <PinButton value={"9"} onClick={onClick}/>
        <PinButton value={"0"} onClick={onClick}/>
        <PinButton value={-1} onClick={onClick} style={{width: 94, display: "flex", justifyContent: "center", alignItems:"center"}} />
      </div>}
    >
      {props.children}
    </Popover>
  );
};

export default Pinpad;
