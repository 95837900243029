import React from "react";
import { Button, Popover } from "antd";
import KeypadDelete from "src/assets/svg/KeypadDelete";

import "./index.css";
import IntlMessages from "src/util/IntlMessages";


const Pinpad3by4 = (props) => {

  const { onClick } = props;

  const PinButton = ({ value, onClick, buttonStyle, icon, children }) => {
    return <Button onClick={(e) => {
      e.stopPropagation();
      onClick && onClick(value);
    } } 
      icon={icon}
      style={{ width: 84, height: 70, radius: 4, fontSize: 24, fontWeight: 400, ...buttonStyle }}>
        {value >= 0 ? value : children}
      </Button>
  }

  return (
    <div style={{ width: 292, height: 340, display: "flex", gap: 10, flexWrap: "wrap", padding: 10 }} 
      className="popover-pinpad">
      <PinButton value={"1"} onClick={onClick} />
      <PinButton value={"2"} onClick={onClick} />
      <PinButton value={"3"} onClick={onClick} />
      <PinButton value={"4"} onClick={onClick} />
      <PinButton value={"5"} onClick={onClick} />
      <PinButton value={"6"} onClick={onClick} />
      <PinButton value={"7"} onClick={onClick} />
      <PinButton value={"8"} onClick={onClick} />
      <PinButton value={"9"} onClick={onClick} />
      <PinButton value={-2} onClick={onClick} buttonStyle={{ fontSize: 15, whiteSpace: "wrap"}}><IntlMessages id="vlink.page.pin.deleteAll"/></PinButton>
      <PinButton value={"0"} onClick={onClick} />
      <PinButton value={-1} onClick={onClick} buttonStyle={{ width: 84, display: "flex", justifyContent: "center", alignItems: "center" }} icon={<KeypadDelete  style={{transform: "scale(1.5)"}}/>}></PinButton>
    </div>
  );
};

export default Pinpad3by4;
