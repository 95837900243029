import {
  CS_ACTIVE_AGENTS,
  CS_AGENT_SELECTED,
  CS_AGENTS,
  CS_DEVICE_ACCESS_TOKEN,
  CS_HOST, CS_ICE_SERVERS, CS_LAYOUTS, CS_PUBLIC_IP,
  CS_SELECTED,
  CS_SLAVE_LIST,
  CS_SUB_USER_LIST,
} from "../../constants/ActionTypes";

export function updateCSHost(csHost) {
  return (dispatch) => {
    dispatch({type: CS_HOST, csHost});
  }
}

export const updateCSSlaveList = (slaveCSList) => {
  return (dispatch) => {
    dispatch({type: CS_SLAVE_LIST, slaveCSList});
  }
};
export const updateCSSelected = (csSelected) => {
  return (dispatch) => {
    dispatch({type: CS_SELECTED, csSelected});
  }
};

export const updateSubUserList = (subUserList) => {
  return (dispatch) => {
    dispatch({type: CS_SUB_USER_LIST, subUserList});
  }
};

export const updateIceServers = (icsServers) => {
  return (dispatch) => {
    dispatch({type: CS_ICE_SERVERS, icsServers});
  }
};

export const updateDeviceAccessToken = (deviceAccessToken) => {
  return (dispatch) => {
    dispatch({type: CS_DEVICE_ACCESS_TOKEN, deviceAccessToken});
  }
};

export const updateAgents = (agents) => {
  return (dispatch) => {
    dispatch({type: CS_AGENTS, agents});
  }
};

export const updateLayouts = (layouts) => {
  return (dispatch) => {
    dispatch({type: CS_LAYOUTS, layouts});
  }
};

export const updateAgentSelected = (agentSelected) => {
  return (dispatch) => {
    dispatch({type: CS_AGENT_SELECTED, agentSelected});
  }
};

export const updatePublicIP = (publicIP) => {
  return (dispatch) => {
    dispatch({type: CS_PUBLIC_IP, publicIP});
  }
};

export const updateActiveAgents = (activeAgents) => {
  return (dispatch) => {
    dispatch({type: CS_ACTIVE_AGENTS, activeAgents});
  }
};
