import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0536 16.6178C17.4369 16.6178 17.7476 16.9272 17.7476 17.3089C17.7476 17.6633 17.4797 17.9554 17.1345 17.9954L17.0536 18H12.1955C11.8122 18 11.5015 17.6906 11.5015 17.3089C11.5015 16.9545 11.7694 16.6624 12.1146 16.6225L12.1955 16.6178H17.0536ZM15.0382 6.50603C15.7157 5.83132 16.8143 5.83132 17.4918 6.50603C18.1399 7.15141 18.1681 8.18034 17.5764 8.85907L17.4918 8.94938L9.92995 16.4795C9.86375 16.5454 9.78903 16.602 9.70786 16.6479L9.62462 16.6902L6.98455 17.8851C6.42332 18.1392 5.84718 17.5973 6.03689 17.0389L6.06465 16.9691L7.26468 14.3402C7.30342 14.2553 7.35342 14.1761 7.41324 14.1048L7.47628 14.0361L15.0382 6.50603ZM16.5104 7.48337C16.3899 7.36343 16.203 7.3501 16.0677 7.44339L16.0196 7.48337L8.50224 14.9692L8.09329 15.865L8.99298 15.4578L16.5104 7.97205C16.6459 7.83711 16.6459 7.61831 16.5104 7.48337Z"
      fill="#4F4F4F"
    />
  </svg>
);
export default SVGComponent;
