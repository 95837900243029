import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Flex, Form, Input, Typography, Modal, Row } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import { useIntl } from "react-intl";
import CircularProgress2 from "src/components/CircularProgress2";
import beApis from "src/util/apis/be";
import { usePopup } from "src/util/use-popup";

import "./index.css";


const ForgotPassword = () => {

  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const csSelected = useSelector(({ cs }) => cs.csSelected);

  const { errorCatchPopup, infoPopup } = usePopup();

  const onFinish = values => {

    console.log("onFinish", values)
    const req = {
      email: values.email,
    }

    setIsLoading(true);
    beApis.forgotPassword(csSelected.host, req).then(() => {
      infoPopup(
        <IntlMessages id="vlink.forgotPassword.initSuccess" />,
        () => { navigate('/user/signin') }
      )
      setIsLoading(false);

    }).catch((e) => {
      errorCatchPopup(
        e.response.data,
        () => { }
      );
      setIsLoading(false);
    
    });
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 500, padding: "70px 0" }}>
          <Flex justify="center" align="center" style={{ height: 86 }}>
            <Typography.Title style={{ color: "#df043a" }}><IntlMessages id="vlink.forgotPassword" /></Typography.Title>
          </Flex>


          <div className="gx-app-login-content" style={{ width: "80%" }}>
            <div style={{ height: 200, display: "flex", alignItems: "center" }}>

              <Form
                form={form}
                initialValues={{ rememberMe: true }}
                name="basic"
                onFinish={onFinish}
                className="gx-signin-form gx-form-row0"
                style={{ marginBottom: 7, flex: 1 }}>
                <Form.Item
                  initialValue=""
                  rules={[{ required: true }]}
                  name="email"
                  label={intl.formatMessage({ id: 'vlink.signup.email' })}
                  labelCol={{ span: 10 }}
                  style={{ marginBottom: 20 }}>
                  <Input
                    style={{
                    }} placeholder={intl.formatMessage({ id: 'vlink.signup.email' })} allowClear />
                </Form.Item>


                {/*<Row style={{borderBottom: '1px solid #5e5e5e'}}/>*/}
                {/* <span
                className="gx-text-light gx-fs-sm"> demo user email: 'demo@example.com' and password: 'demo#123'</span> */}
              </Form>
              </div>

              <Row style={{ gap: "10px" }}>
                  <Button className="gx-mb-0"
                    disabled={isLoading}
                    style={{
                      height: 46,
                      flex: 1,
                      borderRadius: 4,
                      // backgroundColor: '#DF043A',
                      fontSize: 18,
                      fontWeight: 700,
                    }}
                    onClick={() => navigate('/user/signin')}
                  >

                    <IntlMessages id="vlink.button.cancel" />
                  </Button>
                  <Button type="primary" className="gx-mb-0"
                    onClick={() => form.submit()} 
                    disabled={isLoading}
                    style={{
                      height: 46,
                      flex: 1,
                      borderRadius: 4,
                      backgroundColor: '#DF043A',
                      fontSize: 18,
                      fontWeight: 700,
                    }}>

                    {!isLoading ? <IntlMessages id="vlink.button.reset" /> : <CircularProgress2 />}
                  </Button>
                </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword
