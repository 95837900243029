import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Input, List, Button, Menu, Avatar, Space, Card, Switch, Select, TimePicker } from "antd";
import { useIntl } from "react-intl";


import { useAuth } from "src/authentication";
import csApis from 'src/util/apis/cs';
import IntlMessages from "src/util/IntlMessages";



const PersonalSettings = () => {
  const [focus, setFocus] = useState(false);
  const { authUser, getAuthUser } = useAuth();
  const intl = useIntl();

  const csSelected = useSelector(({ cs }) => cs.csSelected)

  const [limitTime, setLimitTime] = useState();
  const [sessionTime, setSessionTime] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [useConnectTime, setUseConnectTime] = useState(true);

  useEffect(() => {
    if (authUser && authUser.settings) {
      if (authUser.settings.use_auto_logoff) {
        setLimitTime(authUser.settings.auto_logoff_limit_time)
      } else {
        setLimitTime(0);
      }

      setSessionTime(authUser.settings.session_expired_time ?? 0);

      setStartTime(authUser.settings.accessible_start_time ? authUser.settings.accessible_start_time.slice(0, 2) : null);
      setEndTime(authUser.settings.accessible_end_time ? authUser.settings.accessible_end_time.slice(0, 2) : null);

      if (!authUser.settings.accessible_start_time && !authUser.settings.accessible_end_time) {
        setUseConnectTime(false);
      }
      else {
        setUseConnectTime(true);
      }
    }
  }, [authUser])

  useEffect(() => {
    setFocus(true);
    return (
      () => setFocus(false)
    )
  }, [])

  const makeFormItem = (label, control, children) => {
    return <div style={{ height: 70, display: "flex", alignItems: "center" }}>
      <div style={{ width: 200, height: 32, lineHeight: "32px", fontSize: 20, fontWeight: 700 }}>{label}</div>
      {children}
      <div style={{ flex: 1, height: 32, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        {control}
      </div>
    </div>
  }

  const LOGOFF_OPTIONS = [{ value: 0, label: intl.formatMessage({ id: "vlink.settings.security.unset" }) },
  { value: 300, label: intl.formatMessage({ id: "vlink.settings.security.300" }) },
  { value: 600, label: intl.formatMessage({ id: "vlink.settings.security.600" }) },
  { value: 900, label: intl.formatMessage({ id: "vlink.settings.security.900" }) },
  { value: 1800, label: intl.formatMessage({ id: "vlink.settings.security.1800" }) },
  { value: 3600, label: intl.formatMessage({ id: "vlink.settings.security.3600" }) },
  ];
  const SESSION_OPTIONS = [{ value: 0, label: intl.formatMessage({ id: "vlink.settings.security.unset" }) },
  { value: 300, label: intl.formatMessage({ id: "vlink.settings.security.300" }) },
  { value: 600, label: intl.formatMessage({ id: "vlink.settings.security.600" }) },
  { value: 900, label: intl.formatMessage({ id: "vlink.settings.security.900" }) },
  { value: 1800, label: intl.formatMessage({ id: "vlink.settings.security.1800" }) },
  { value: 3600, label: intl.formatMessage({ id: "vlink.settings.security.3600" }) },
  ];
  const START_OPTIONS = Array.from({ length: 23 }, (_, i) => {
    const number = (i + 1).toString().padStart(2, '0');
    return { value: number, label: number }
  });

  const updateSettings = (req) => {
    csApis.UpdateSubUser(csSelected.host, authUser.id, req).then((resp) => {
      getAuthUser();
    }).catch(e => {
      console.log("updateSettings error", e)
    })
  }

  const handleLogoffTime = (value) => {

    setLimitTime(value);

    const req = {
      use_auto_logoff: value > 0 ? true : false,
      auto_logoff_limit_time: value
    };

    updateSettings({ settings: req });
  }

  const handleConnectTimeYN = (e) => {


    let startTime = 0;
    let endTime = 0;
    if (e) {
      startTime = '09';
      endTime = '18';
    }
    else {
      startTime = null;
      endTime = null;
    }

    updateSettings({
      settings: {
        accessible_start_time: startTime ? startTime + ":00" : startTime,
        accessible_end_time: endTime ? endTime + ":00" : endTime,
      }
    })

    setUseConnectTime(e);
    setStartTime(startTime);
    setEndTime(endTime);
  }

  const handleConnectTime = (type, value) => {
    
    if (type === "start") {
      setStartTime(value);
      updateSettings({
        settings: {
          accessible_start_time: value + ":00" ,
        }
      })
    }
    else if (type === "end") {
      setEndTime(value);
      updateSettings({
        settings: {
          accessible_end_time: value + ":00" ,
        }
      })
    }
  }

  const handleSessionTime = (value) => {

    updateSettings({ settings: { session_expired_time: value } });
    setSessionTime(value);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20, height: "100%" }}>

      <Card title={<IntlMessages id="vlink.settings.security" />} className="settings-card" >
        <div style={{ display: "flex", flexDirection: "column" }}>

          {makeFormItem(<IntlMessages id="vlink.settings.security.logoff" />,
            <Select style={{ width: "100px" }} value={limitTime} options={LOGOFF_OPTIONS} onChange={handleLogoffTime} />,
            <IntlMessages id="vlink.settings.security.logoff.description" />)}

          {/* {makeFormItem(<IntlMessages id="vlink.settings.security.connectTime" />,
            <Switch onChange={handleConnectTimeYN} value={useConnectTime} />,
            <div style={{ display: "flex", gap: 20, }}>
              <div>
                <span style={{ marginRight: 10 }}>시작시각</span><Select options={START_OPTIONS} style={{ width: 80 }} value={startTime} disabled={startTime === null} onChange={(v) => handleConnectTime("start", v)} />
              </div>
              <div>
                <span style={{ marginRight: 10 }}>종료시각</span><Select options={START_OPTIONS} style={{ width: 80 }} value={endTime} disabled={endTime === null} onChange={(v) => handleConnectTime("end", v)} />
              </div>
            </div>)}

          {makeFormItem(<IntlMessages id="vlink.settings.security.sessionTime" />,
            <Select style={{ width: "100px" }} value={sessionTime} options={SESSION_OPTIONS} onChange={handleSessionTime} />,
            <IntlMessages id="vlink.settings.security.sessionTime.description" />)} */}

        </div>

      </Card>
    </div>
  )

}

export default PersonalSettings;