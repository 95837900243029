import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={35}
    height={35}
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.55737 10.0893C7.55737 9.34962 8.15882 8.75 8.90075 8.75H15.6176C16.3595 8.75 16.961 9.34962 16.961 10.0893V15.4464C16.961 16.1861 16.3595 16.7857 15.6176 16.7857H8.90075C8.15882 16.7857 7.55737 16.1861 7.55737 15.4464V10.0893Z"
      fill="#B9B8B9"
    />
    <path
      d="M7.55737 19.5536C7.55737 18.8139 8.15882 18.2143 8.90075 18.2143H15.6176C16.3595 18.2143 16.961 18.8139 16.961 19.5536V24.9107C16.961 25.6504 16.3595 26.25 15.6176 26.25H8.90075C8.15882 26.25 7.55737 25.6504 7.55737 24.9107V19.5536Z"
      fill="#B9B8B9"
    />
    <path
      d="M18.3834 10.0893C18.3834 9.34962 18.9848 8.75 19.7268 8.75H26.4436C27.1855 8.75 27.787 9.34962 27.787 10.0893V15.4464C27.787 16.1861 27.1855 16.7857 26.4436 16.7857H19.7268C18.9848 16.7857 18.3834 16.1861 18.3834 15.4464V10.0893Z"
      fill="#B9B8B9"
    />
    <path
      d="M18.3834 19.5536C18.3834 18.8139 18.9848 18.2143 19.7268 18.2143H26.4436C27.1855 18.2143 27.787 18.8139 27.787 19.5536V24.9107C27.787 25.6504 27.1855 26.25 26.4436 26.25H19.7268C18.9848 26.25 18.3834 25.6504 18.3834 24.9107V19.5536Z"
      fill="#B9B8B9"
    />
  </svg>
);
export default SVGComponent;
