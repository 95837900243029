// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

// Loading
export const COMMON_FORCE_LOADING = 'COMMON_FORCE_LOADING';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const SET_XDATA = 'SET_XDATA';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const UPDATE_LOAD_USER = 'UPDATE_LOAD_USER';

// CS
export const CS_HOST = 'CS_HOST';
export const CS_SLAVE_LIST = 'CS_SLAVE_LIST';
export const CS_SUB_USER_LIST = 'CS_SUB_USER_LIST';
export const CS_SELECTED = 'CS_SELECTED';
export const CS_ICE_SERVERS = 'CS_ICE_SERVERS';
export const CS_DEVICE_ACCESS_TOKEN = 'CS_DEVICE_ACCESS_TOKEN';
export const CS_AGENTS = 'CS_AGENTS';
export const CS_LAYOUTS = 'CS_LAYOUTS';
export const CS_AGENT_SELECTED = 'CS_AGENT_SELECTED';
export const CS_PUBLIC_IP = 'CS_PUBLIC_IP';
export const CS_ACTIVE_AGENTS = 'CS_ACTIVE_AGENTS';


//For Websocket
export const WEBSOCKET_RECEIVE = 'WEBSOCKET_RECEIVE';
export const WEBSOCKET_SEND = 'WEBSOCKET_SEND';
export const WEBSOCKET_CONNECTED = 'WEBSOCKET_CONNECTED';

// WebRTC

export const WEBRTC_AGENT_APPS = 'WEBRTC_AGENT_APPS';
export const WEBRTC_AGENT_CONNECTIONS = 'WEBRTC_AGENT_CONNECTIONS';
export const WEBRTC_AGENTS = 'WEBRTC_AGENTS';
export const WEBRTC_DATA_CHANNELS = 'WEBRTC_DATA_CHANNELS';
export const WEBRTC_DATA_MESSAGES = 'WEBRTC_DATA_MESSAGES';
export const WEBRTC_SUBSCRIBE_AGENT_APPS = 'WEBRTC_SUBSCRIBE_AGENT_APPS';
export const WEBRTC_SUBSCRIBE_DATA_CHANNELS = 'WEBRTC_SUBSCRIBE_DATA_CHANNELS';
export const WEBRTC_PERMISSION_RESULT = 'WEBRTC_PERMISSION_RESULT';
export const WEBRTC_RESOLUTION = 'WEBRTC_RESOLUTION';
export const WEBRTC_SWITCH_APP = 'WEBRTC_SWITCH_APP';

