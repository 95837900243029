import antdHK from "antd/locale/zh_HK";
import enMessages from "../locales/zh_HK.json";

const zhHkLang = {
  messages: {
    ...enMessages
  },
  antd: antdHK,
  locale: 'zh-HK',
};
export default zhHkLang;
