import {WEBSOCKET_CONNECTED, WEBSOCKET_RECEIVE, WEBSOCKET_SEND} from "../../constants/ActionTypes";

const initialSettings = {
  wsReceive: {},
  wsSend: {},
  wsConnected: false,
};

const WebsocketReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case WEBSOCKET_RECEIVE:
      return {
        ...state,
        wsReceive: action.wsReceive
      };
    case WEBSOCKET_SEND:
      return {
        ...state,
        wsSend: action.wsSend
      };
    case WEBSOCKET_CONNECTED:
      return {
        ...state,
        wsConnected: action.wsConnected
      };
    default:
      return state;
  }
};

export default WebsocketReducer;
