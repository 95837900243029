import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {List, Typography} from "antd";

import StyledFirstCircle from "src/components/StyledFirstCircle";
import StyledHostTag from "src/components/StyledHostTag";
import {useMeasure} from "react-use";

const {Text} = Typography;

const UserHorizontalView = (props) => {
  const subUserList = useSelector(({cs}) => cs.subUserList);
  const [data, setData] = useState([])
  const [containerRef, {width, height}] = useMeasure();

  useEffect(() => {
    setData(subUserList)
  }, [subUserList]);

  useEffect(() => {
    if (width > 0) {
      props.onLayout && props.onLayout(width)
    }
  }, [width]);

  return (
    <div
      style={{
        maxWidth: '320px',
      }}
      ref={containerRef}
    >
      <List
        grid={{
          column: Math.min(subUserList?.length, 4),
        }}
        locale={{
          emptyText: <span></span>
        }}
        dataSource={data || []}
        renderItem={(item) =>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '12px',
            marginBottom: '12px',
          }}>
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
              }}
              onClick={() => props.onClick(item)}>
              <StyledFirstCircle size={28} text={item.name}/>
              <Text style={{paddingLeft: 8, paddingRight: 8}}>{item.name}</Text>
              <span style={{ display: 'flex', justifyContent: 'center'}}>
                <StyledHostTag isHost={item.is_host}/>
              </span>
            </div>
          </div>}
      />
    </div>
  )
}

export default UserHorizontalView;
