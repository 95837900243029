import { useEffect, useState } from "react";

export default function useMousePosition(parentRef) {
  const [mousePosition, setMousePosition] = useState({ x: -100, y: -100 });

  useEffect(() => {
    const mouseMoveHandler = (event) => {

      const { clientX, clientY } = event;
      if (parentRef.current) {
        const parentRect = parentRef.current.getBoundingClientRect();
        const withinX = clientX >= parentRect.left && clientX <= parentRect.right;
        const withinY = clientY >= parentRect.top && clientY <= parentRect.bottom;

        if (withinX && withinY) {
          setMousePosition({ x: clientX, y: clientY });
        }
        else {
          setMousePosition({ x: -100, y: -100 });

        }
      }
    };
    document.addEventListener("mousemove", mouseMoveHandler);

    return () => {
      document.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, [parentRef]);

  return mousePosition;
}
