import antdPT from "antd/locale/pt_BR";
import enMessages from "../locales/pt_BR.json";

const ptLang = {
  messages: {
    ...enMessages
  },
  antd: antdPT,
  locale: 'pt-BR',
};
export default ptLang;
