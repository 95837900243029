import React, { useMemo, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "antd";


import { updateForceLoading } from "src/appRedux/actions";
import Profile from "src/assets/svg/Profile";
import { useAuth } from "src/authentication";
import StyledTooltip from "src/components/StyledTooltip";
import UserHorizontalView from "src/routes/userAuth/SelectUser/UserHorizontalView";
import { getLoginFormData } from "src/util/common";

import PinPopup from "src/routes/userAuth/SelectUser/PinPopup";

import './index.css';


const UserSelect = () => {
  const { authUser, userSignOut, userLoginSubPinFree } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false)
  const [showPin, setShowPin] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);

  const refSelectedUser = useRef();


  const onLogoutClick = () => {
    userSignOut(() => {
      navigate('/user/signin');
    });
  }
  const handleSelectSubUser = (selectedUser) => {
    console.log('handleClick', selectedUser)
    setOpenPopover(false);
    refSelectedUser.current = selectedUser;

    if (selectedUser.has_pin) {
      setShowPin(selectedUser);

    } else {
      userLoginSubPinFree(
        getLoginFormData({
          email: selectedUser.username,
          password: selectedUser.username,
        }), (ok) => {
          if (ok) {
            dispatch(updateForceLoading({ favorites: true, recents: true }))
          }
        })
    }

  }


  const handlePinSuccess = () => {
    setShowPin(false);
    userLoginSubPinFree(
      getLoginFormData({
        email: refSelectedUser.current.username,
        password: refSelectedUser.current.username,
      }), (ok) => {
        if (ok) {
          // navigate('/home');
        }
      })
  }

  const userMenuOptions = (<ul style={{
    listStyle: "none",
    // padding: "0 10px",
    padding: "0 0 0 0",
    marginBottom: 0,
  }}>
    <li>
      <UserHorizontalView
        onClick={handleSelectSubUser}
        onLayout={(w) => {
          // console.log('w', w);
          setLoad(true)
        }} />
    </li>
    {/* <li>
      <Divider style={{ margin: '8px 0px 8px 0'}}/>
    </li> */}
    {/* <li
      style={{display: "flex", justifyContent: 'center'}}>
      <span>
        {`v${config.package.version}`}
      </span>
    </li> */}

  </ul>);

  return (
    <>
      <div
        style={{
          display: "flex", alignItems: "center", marginLeft: 28,
        }}
        onClick={() => setOpenPopover(!openPopover)}
      >
        <StyledTooltip content={userMenuOptions} placement={load ? "bottom" : "bottomRight"} open={openPopover}>
          <span style={{ cursor: "pointer", display: "flex", alignItems: "center", width: 32, height: 60 }}>
            <Button type={'text'} shape="circle" icon={<Profile />} />
            <div
              className={'user-profile-right'}
              style={{
                width: 8,
              }}
            />
          </span>
        </StyledTooltip>

      </div>
      {showPin && <PinPopup selectedUser={showPin} onCancel={() => setShowPin(false)} onOk={handlePinSuccess} />}
    </>
  )
};

export default UserSelect;
