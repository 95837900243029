import {
  CS_ACTIVE_AGENTS,
  CS_AGENT_SELECTED,
  CS_AGENTS,
  CS_DEVICE_ACCESS_TOKEN,
  CS_HOST,
  CS_ICE_SERVERS,
  CS_LAYOUTS,
  CS_PUBLIC_IP,
  CS_SELECTED,
  CS_SLAVE_LIST,
  CS_SUB_USER_LIST,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  csHost: undefined,
  slaveCSList: undefined,
  subUserList: undefined,
  csSelected: undefined,
  iceServers: undefined,
  deviceAccessToken: undefined,
  agents: undefined,
  layouts: undefined,
  agentSelected: undefined,
  publicIP: undefined,
  activeAgents: undefined,
};

const CSReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CS_HOST:
      return {
        ...state,
        csHost: action.csHost,
      }
    case CS_SELECTED:
      return {
        ...state,
        csSelected: action.csSelected,
      }
    case CS_SLAVE_LIST:
      return {
        ...state,
        slaveCSList: action.slaveCSList,
      }
    case CS_SUB_USER_LIST:
      return {
        ...state,
        subUserList: action.subUserList,
      }
    case CS_ICE_SERVERS:
      return {
        ...state,
        iceServers: action.iceServers,
      }
    case CS_DEVICE_ACCESS_TOKEN:
      return {
        ...state,
        deviceAccessToken: action.deviceAccessToken,
      }
    case CS_AGENTS:
      return {
        ...state,
        agents: action.agents,
      }
    case CS_LAYOUTS:
      return {
        ...state,
        layouts: action.layouts,
      }
    case CS_AGENT_SELECTED:
      return {
        ...state,
        agentSelected: action.agentSelected,
      }
    case CS_PUBLIC_IP:
      return {
        ...state,
        publicIP: action.publicIP,
      }
    case CS_ACTIVE_AGENTS:
      return {
        ...state,
        activeAgents: action.activeAgents,
      }
    default:
      return state;
  }
};

export default CSReducer;
