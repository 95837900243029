import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Input, List, Button, Menu, Avatar, Space, Card, Switch, Select, TimePicker, Tooltip, Popover, Tag } from "antd";
import { useIntl } from "react-intl";
import { PinInput } from 'react-input-pin-code';

import Keyboard from 'src/assets/svg/Keyboard';
import { useAuth } from "src/authentication";
import Pinpad from 'src/components/Pinpad';
import csApis from 'src/util/apis/cs';
import IntlMessages from "src/util/IntlMessages";
import { usePopup } from 'src/util/use-popup';

import HasPinTag from './HasPinTag';
import "./index.css";

const AuthoritySettings = () => {
  const { authUser, getAuthUser } = useAuth();
  const { infoPopup} = usePopup();

  const csSelected = useSelector(({ cs }) => cs.csSelected)
  const intl = useIntl();


  const [hasPin, setHasPin] = useState(false);
  const [showError, setShowError] = useState(false);

  const [formComplete, setFormComplete] = useState(false);

  const [pinData, setPinData] = useState({
    current: { value: ['', '', '', '', '', ''], index: 0 },
    password: { value: ['', '', '', '', '', ''], index: 0 },
    re_password: { value: ['', '', '', '', '', ''], index: 0 },

  });

  useEffect(() => {
    if (authUser) {
      setHasPin(authUser.has_pin);
      initPin();
    }
  }, [authUser])

  useEffect(() => {
    if (!showError && (hasPin && pinData.current.value.join("").length === 6 || !hasPin) && pinData.password.value.join("").length === 6 && pinData.re_password.value.join("").length === 6) {
      setFormComplete(true)
    }
    else {
     setFormComplete(false);
    }
  }, [showError, pinData])

  const initPin = () => {
    setPinData({
      current: { value: ['', '', '', '', '', ''], index: 0 },
      password: { value: ['', '', '', '', '', ''], index: 0 },
      re_password: { value: ['', '', '', '', '', ''], index: 0 },
    })
  }



  const handleChange = (name, value, index, values) => {


    const newIndex = value.length ? Math.min(index + 1, 5) : index;
    setPinData({
      ...pinData,
      [name]: { value: values, index: newIndex }
    });


  }

  const handlePad = (name, e) => {

    let index = pinData[name].value.findIndex(item => item == "");

    if (index < 0) {
      index = 6;
    }

    if (e >= 0) {
      index = Math.min(index, 5);
    }
    else {
      index = Math.max(index - 1, 0);
    }


    pinData[name].value[index] = e >= 0 ? e : "";

    const newValue = pinData[name].value;
    const newIndex = e >= 0 ? Math.min(index + 1, 5) : Math.max(index - 1, 0);

    setPinData({
      ...pinData,
      [name]: { value: newValue, index: newIndex }
    });
  }

  const handleSave = () => {
    const req = {
      old_secret: hasPin ? pinData.current.value.join('') : null,
      secret: pinData.password.value.join('')
    }
    csApis.UpdatePin(csSelected.host, req).then((resp) => {

      infoPopup(IntlMessages({id: "vlink.common.saved"}), () => {
        initPin();
        getAuthUser();
      })
    }).catch(e => {
      console.log("UpdatePin error", e)
    });

  }

  const handleChangeRepassword = (value, index, values) => {
    handleChange("re_password", value, index, values);
    handleCheckRepassword();
  }


  const handleCheckRepassword = () => {
    // console.log("handleCheckRepassword password:", pinData.password.value)
    // console.log("handleCheckRepassword re_password:", pinData.re_password.value)

    if (pinData.password.value.join('') == pinData.re_password.value.join('')) {
      setShowError(false)
    }
    else {
      setShowError(intl.formatMessage({id:"vlink.settings.authority.pinNumberNotMatched"}))
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20, height: "100%" }}>
      <Card title={<div style={{display: "flex", alignItems: "center", gap: 10}}>
        <div><IntlMessages id="vlink.settings.authority" /></div>
        <HasPinTag hasPin={hasPin} />
      </div>} className="settings-card">
        <div style={{ display: "flex", flexDirection: "column" }}>
          {hasPin &&

            <div style={{ height: 70, display: "flex", alignItems: "center" }}>
              <div style={{ width: 200, height: 32, lineHeight: "32px", fontSize: 20, fontWeight: 700 }}><IntlMessages id="vlink.settings.authority.pinNumberCurrent" /></div>
              <>
                <PinInput
                  autocomplete="off"
                  length={6}
                  values={pinData.current.value}
                  onChange={(value, index, values) => handleChange("current", value, index, values)}
                  placeholder=''
                  type="number"
                  containerClassName="pincode-input-container"
                  containerStyle={{ padding: 0, marginRight: 20 }}
                  mask="*"
                  autoFocus={true}
                />
                <Pinpad onClick={(value) => handlePad('current', value)}>
                  <Keyboard />
                </Pinpad>
              </>
              <div style={{ flex: 1, height: 32, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              </div>
            </div>
          }


          <div style={{ height: 70, display: "flex", alignItems: "center" }}>
            <div style={{ width: 200, height: 32, lineHeight: "32px", fontSize: 20, fontWeight: 700 }}>
              <IntlMessages id="vlink.settings.authority.pinNumber" />
            </div>
            <>
              <PinInput
                autocomplete="off"
                length={6}
                values={pinData.password.value}
                onChange={(value, index, values) => handleChange("password", value, index, values)}
                placeholder=''
                type="number"
                containerClassName="pincode-input-container"
                containerStyle={{ padding: 0, marginRight: 20 }}
                mask="*"
                autoFocus={true}
              />
              <Pinpad onClick={(value) => handlePad('password', value)}>
                <Keyboard />
              </Pinpad>
            </>
            <div style={{ flex: 1, height: 32, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            </div>
          </div>


          <div style={{ height: showError?90: 70, display: "flex", alignItems: "center" }}>
            <div style={{ width: 200, height: 32, lineHeight: "32px", fontSize: 20, fontWeight: 700 }}>
              <IntlMessages id="vlink.settings.authority.pinNumberConfirm" />
            </div>
            <div style={{ display: "flex", flexDirection: "column"}}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <PinInput
                  autocomplete="off"
                  length={6}
                  values={pinData.re_password.value}
                  onChange={handleChangeRepassword}
                  placeholder=''
                  type="number"
                  containerClassName={"pincode-input-container" + (showError? " invalid": "")}
                  containerStyle={{ padding: 0, marginRight: 20 }}
                  mask="*"
                  onComplete={handleCheckRepassword}
                />
                <Pinpad onClick={(value) => handlePad('re_password', value)}>
                  <Keyboard />
                </Pinpad>
              </div>
              {showError && 
                <div style={{height: 16, color: "#DF043A", fontSize: 12, marginTop: 5, fontWeight: 400}}>{showError}
                </div>
              }

            </div>
            <div style={{ flex: 1, height: 32, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            </div>
          </div>

          <div style={{ height: 70, display: "flex", justifyContent: "flex-end" }}>
            <Space>
              {/* <Button><IntlMessages id="vlink.common.cancel" /></Button> */}
              <Button type="primary" className="gx-mb-0" danger onClick={handleSave} disabled={!formComplete}><IntlMessages id="vlink.common.save" /></Button>
            </Space>
          </div>
        </div>
      </Card>
    </div>
  )

}

export default AuthoritySettings;