import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.0854 8.47266L9.28797 12.2227M9.28797 8.47266L13.0854 12.2227M4.04747 10.9477L6.78165 14.5477C7.00443 14.841 7.11582 14.9877 7.25699 15.0934C7.38203 15.1871 7.52362 15.257 7.67454 15.2996C7.84494 15.3477 8.03059 15.3477 8.4019 15.3477H13.212C14.2754 15.3477 14.8071 15.3477 15.2133 15.1433C15.5705 14.9635 15.861 14.6767 16.0431 14.3239C16.25 13.9228 16.25 13.3978 16.25 12.3477V8.34766C16.25 7.29756 16.25 6.77251 16.0431 6.37142C15.861 6.01862 15.5705 5.73178 15.2133 5.55202C14.8071 5.34766 14.2754 5.34766 13.212 5.34766H8.4019C8.03059 5.34766 7.84494 5.34766 7.67454 5.39571C7.52362 5.43828 7.38203 5.50819 7.25699 5.60188C7.11582 5.70766 7.00443 5.85432 6.78165 6.14766L4.04747 9.74766C3.88405 9.96282 3.80235 10.0704 3.77085 10.1886C3.74305 10.2928 3.74305 10.4025 3.77085 10.5068C3.80235 10.6249 3.88405 10.7325 4.04747 10.9477Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;
