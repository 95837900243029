import * as React from "react";
const ArrowRight = (props) => (
  <svg
    width={15}
    height={15}
    viewBox="0 -3 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.75 4L8.86653 7.11653C9.07831 7.32831 9.07831 7.67169 8.86653 7.88347L5.75 11" stroke="#B9B8B9" stroke-linecap="square"/>
  </svg>
);
export default ArrowRight;
