import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from "../../authentication";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress3 from "../../components/CircularProgress3";
import {
  updateActiveAgents,
  updateAgents,
  updateAgentSelected,
  updateDeviceAccessToken,
  updateLayouts,
  updatePublicIP,
  updateSubUserList,
  updateWsSend
} from "../../appRedux/actions";
import { v4 } from "uuid";
import beApis from "src/util/apis/be";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import AtomicPopup from "../../components/AtomicPopup";
import IntlMessages from "../../util/IntlMessages";
import { Input, List, Button, Menu, Avatar, Space, Card, Switch, Select, TimePicker, Table, Flex, Tag, DatePicker } from "antd";
import StyledFirstCircle from 'src/components/StyledFirstCircle';
import config from "constants/Config";
import Information from "src/assets/svg/Information";
import { convertDateFormat, getProductTagColors } from 'src/util/common';
import dayjs from 'dayjs';

import './index.css';
import AccountPopup from './AccountPopup';
import StyledHostTag from 'src/components/StyledHostTag';
import HasPinTag from './HasPinTag';
import { SearchOutlined } from '@ant-design/icons';


const LoginHistory = () => {
  const { authUser, userSignOut } = useAuth();
  const intl = useIntl();
  const dispatch = useDispatch();

  const csSelected = useSelector(({ cs }) => cs.csSelected);
  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(false);
  const [hostUser, setHostUser] = useState();
  const [permissionList, setPermissionList] = useState([]);

  const [name, setName] = useState("");
  const [dateRange, setDateRange] = useState();

  const [pageSize, setPageSize] = useState(10);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [ordering, setOrdering] = useState([]);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    setFocus(true);
  }, [])



  const getData = () => {

    const req = {
      keyword: name,
      date_range: ["", ""],
      ordering,
    }

    if (dateRange) {
      req.date_range = [dayjs(dateRange[0]).format('YYYY.MM.DD'), dayjs(dateRange[1]).format('YYYY.MM.DD')]
    }

    beApis.loginHistory(csSelected.host, req, current, pageSize).then(resp => {
      setData(resp.results);
      setTotal(resp.count);
    }).catch((e) => console.log("loginHistory error", e))
  }

  useEffect(() => {
    if (focus) {
      getData();
    }
  }, [focus, pageSize, current, ordering])


  const columns = [
    {
      title: intl.formatMessage({ id: 'vlink.settings.loginHistory.accessDate' }),
      dataIndex: 'date',
      key: 'date',
      width: 100,
      sorter: true,
      render: (value, record) => {
        return convertDateFormat(value, 'withTime')
      }
    },
    {
      title: intl.formatMessage({ id: 'vlink.settings.loginHistory.system' }),
      dataIndex: 'system',
      key: 'system',
      width: 100,
    },
    {
      title: intl.formatMessage({ id: 'vlink.settings.loginHistory.name' }),
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: intl.formatMessage({ id: 'vlink.settings.loginHistory.ip' }),
      dataIndex: 'ip',
      key: 'ip',
      width: 100,
    },
    // {
    //   title: intl.formatMessage({ id: 'vlink.settings.loginHistory.accessTime' }),
    //   dataIndex: 'start_date',
    //   key: 'start_date',
    // },
    // {
    //   title: intl.formatMessage({ id: 'vlink.settings.loginHistory.accessApp' }),
    //   dataIndex: 'end_date',
    //   key: 'end_date',
    // },
  ]

  const handleDatePicker = (e) => {
    console.log("handleDatePicker", e)
    setDateRange(e);
  }

  const onMovePage = (current) => {
    setCurrent(current);
  }

  const onChangeSize = (size) => {
    setPageSize(size);
  }

  const handleChange = (p, f, sorter) => {
    console.log("handleChangd sorter", sorter)
    if (sorter.column) {
      const order = sorter.order === "ascend" ? sorter.column.dataIndex : "-" + sorter.column.dataIndex;
      setOrdering([order]);
    }
    else {
      setOrdering([]);
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20, height: "100%" }}>

      <Card title={<Flex justify='space-between' align="center">
        <IntlMessages id="vlink.settings.loginHistory" />
        <Space>
          <Input value={name} onChange={(e) => setName(e.target.value)} style={{ width: 150 }}  onPressEnter={() => { getData() }} prefix={<SearchOutlined />} placeholder={intl.formatMessage({ id: "vlink.settings.loginHistory.name" })} />
          <DatePicker.RangePicker style={{ width: 280 }} onChange={(e) => { handleDatePicker(e) }} value={dateRange} allowClear />
          <Button onClick={() => { getData() }} type="primary"><IntlMessages id="vlink.button.search" /></Button>
        </Space>
      </Flex>} className="settings-card">
        <Table
          className={'user-list'}
          columns={columns}
          dataSource={data}
          pagination={{
            position: ['bottomCenter'],
            current: current,
            total: total,
            pageSize: pageSize,
            onChange(current) { onMovePage(current) },
            onShowSizeChange(current, size) { onChangeSize(size) },

          }}
          rowKey='id'
          onChange={handleChange}
        // locale={{
        //   emptyText: (
        //     <CircularProgress3 />
        //   )
        // }}
        />
      </Card>
      {selectedUser &&
        <AccountPopup selectedUser={selectedUser}
          permissionList={permissionList}
          onOk={() => { setSelectedUser(false); getData(); }}
          onCancel={() => setSelectedUser(false)} />
      }
    </div>
  )

}

export default LoginHistory;
