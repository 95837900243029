import React, { useEffect, useRef, useState } from 'react';
import { Input, List, Button, Menu, Avatar, Space, Card, Switch, Select, TimePicker, Tooltip, Popover, Tag } from "antd";

import IntlMessages from "src/util/IntlMessages";


const HasPinTag = ({hasPin}) => {
  return hasPin? <Tag color="#42b677"><IntlMessages id="vlink.settings.authority.active" /></Tag>
    : <Tag color="#df043a"><IntlMessages id="vlink.settings.authority.inActive" /></Tag>

}

export default HasPinTag;