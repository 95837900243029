import React, { useRef, useState, useEffect } from "react";

import "./index.css";

export const DRAW_TYPE = {
  Free: 'pencil',
  Erase: 'eraser',
}

const CustomCanvasV2 = ({ color, type, width, height }) => {
  const parentRef = useRef(null);
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [paths, setPaths] = useState([]);
  const newPath = useRef([]);
  const [isErasing, setIsErasing] = useState(false);
  const [ctx, setCtx] = useState();


  useEffect(() => {
    const canvas = canvasRef.current;
    const node = parentRef.current;
    if (node) {
      // const { width, height } = node.getBoundingClientRect();
      const canvas = canvasRef.current;

      if (canvas) {
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext("2d");

        context.strokeStyle = color;
        context.lineWidth = 2.5;

        contextRef.current = context;

        setCtx(context);
      }
    }

  }, []);

  useEffect(() => {
    if (type.eraser) {
      setIsErasing(true)
    }
    else {
      setIsErasing(false);
    }
  }, [type])


  useEffect(() => {
    // console.log("width, height changed", width, height)

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width, height, paths]);



  useEffect(() => {
    // console.log("width, height changed", width, height)

    handleResize();

  }, [width, height]);

  const handleResize = () => {
    const node = parentRef.current;
    if (node) {
      // const { width, height } = node.getBoundingClientRect();
      const canvas = canvasRef.current;
      const element = document.getElementById('app-video');

      // console.log("handleResize old", canvas.width, canvas.height)
      // console.log("handleResize new", width, height)
      // console.log("==>app-video", element.getBoundingClientRect().width,  element.getBoundingClientRect().height);

      
      if (canvas) {
        const updatedPaths = paths.map(path => {
          return path.map(({ x, y }) => {

            if (parseInt(canvas.width) == parseInt(width) && parseInt(canvas.height) == parseInt(height)) {
              return {x, y};
            }
            else {
              return {
                x: (x / parseInt(canvas.width)) * parseInt(width),
                y: (y / parseInt(canvas.height)) * parseInt(height)
              };
            }
          
          });
        });
        
        canvas.width = width;
        canvas.height = height;
        setPaths(updatedPaths);
        redraw(updatedPaths);

      }

    }
  };




  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const context = canvas.getContext("2d");
  //   context.strokeStyle = color;
  //   context.lineWidth = 2.5;
  //   contextRef.current = context;
  // }, [color]);

  // useEffect(() => {
  //   console.log("IsDrawing", isDrawing)
  // }, [isDrawing])

  const startDrawing = ({ nativeEvent }) => {
    // console.log("startDrawing", isErasing, paths)
    const { offsetX, offsetY } = nativeEvent;
    if (isErasing) {
      erasePath({ x: offsetX, y: offsetY });
    } else {
      setIsDrawing(true);
      newPath.current = [{ x: offsetX, y: offsetY }];
    }
  };

  const finishDrawing = () => {
    setIsDrawing(false);

    // console.log("finishDrawing", paths)
    // console.log("finishDrawing", newPath.current)
    setPaths([...paths, [...newPath.current]]);
    newPath.current = [];

  };

  const draw = ({ nativeEvent }) => {
    if (!isDrawing || isErasing) return;
    const { offsetX, offsetY } = nativeEvent;
    newPath.current.push({ x: offsetX, y: offsetY });

    const updatedPaths = [...paths, newPath.current];
    redraw(updatedPaths);


  };

  const erasePath = ({ x, y }) => {
    // 클릭한 좌표를 기준으로 가장 가까운 경로를 찾아 제거
    const clickedPathIndex = paths.findIndex((path) => {
      let isFind = false;
      for(let i = 0; i < path.length; i++) {
        const dx = path[i].x - x;
        const dy = path[i].y - y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance < 10) {// 클릭한 지점과 경로의 거리가 10px 이내인지 확인
          isFind = true;
          break;
        }
      }

      return isFind;
    });

    if (clickedPathIndex !== -1) {
      const updatedPaths = [...paths];
      updatedPaths.splice(clickedPathIndex, 1);
      setPaths(updatedPaths);

      // 캔버스를 지우고 다시 그림
      redraw(updatedPaths);
    }
  };

  const redraw = (updatedPaths) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.strokeStyle = color;
    context.lineWidth = 2.5;

    updatedPaths.forEach((path) => {
      context.beginPath();
      path.forEach(({ x, y }) => {
        context.lineTo(x, y);
      });
      context.stroke();
    });
  };

  return (
    <div className="canvas_wrap" ref={parentRef} width={width} height={height} style={{cursor: type.pencil? "url('/assets/images/cursor/pencil.png') 5 30, auto": "url('/assets/images/cursor/eraser.png') 15 30, auto"}}>
      <canvas
        ref={canvasRef}
        onMouseDown={startDrawing}
        onMouseUp={finishDrawing}
        onMouseMove={draw}
        onMouseLeave={finishDrawing}
      />
    </div>
  );
};

export default CustomCanvasV2;