import {useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useMatch} from 'react-router-dom';
import {Buffer} from 'buffer';
import dayjs from "dayjs";
import {Tag} from "antd";
import {httpClient} from "./Api";
import beApis from "./apis/be";
import IntlMessages from "./IntlMessages";

export const useJobStatusConst = () => {
  const intl = useIntl();

  const jobStatusOptions = useMemo(() => [
      {label: intl.formatMessage({id: "working"}), value: "W"},
      {label: intl.formatMessage({id: "done"}), value: "D"},
      {label: intl.formatMessage({id: "error"}), value: "E"},
      {label: intl.formatMessage({id: "flush"}), value: "F"},
      {label: intl.formatMessage({id: "start"}), value: "S"},
      {label: intl.formatMessage({id: "hold"}), value: "H"},
      {label: intl.formatMessage({id: "suspend"}), value: "P"},
      {label: intl.formatMessage({id: "stop"}), value: "T"},]
    , [intl]);

  const jobStatusDefaultList = useMemo(() =>
      jobStatusOptions.map((j) => j.value)
    , [jobStatusOptions]);

  return {jobStatusOptions, jobStatusDefaultList};
}


export const lnbIcons = {
  base: {
    volumes: "LayoutOutlined",
    catalogs: "TagsOutlined",
    datasets: "DatabaseOutlined",
    locks: "LockOutlined",
    logs: "ExceptionOutlined",
    console: "DesktopOutlined",
    command: "CodeOutlined",
  },
}

export const useQueryString = () => {
  const matchAction = useMatch("/:gnb/:lnb/:action/*");

  const setQueryStringToHistory = (reqObj, actionName) => {

    const queryStr = new URLSearchParams(reqObj).toString();
    const pathname = window.location.pathname;
    const newPathname = pathname.substring(0, pathname.indexOf(`/${actionName}`));

    // console.log("setQueryStringToHistory queryStr", queryStr)
    // console.log("setQueryStringToHistory window.location", window.location)
    // console.log("setQueryStringToHistory newPathname", newPathname)
    // console.log("setQueryStringToHistory matchAction?.params.action", matchAction?.params.action)

    let newUrl = "";
    if (matchAction?.params.action) {
      newUrl = `?${queryStr}`;
    } else if (newPathname) {
      newUrl = `${newPathname}/${actionName}?${queryStr}`;
    } else {
      newUrl = `${pathname}/${actionName}?${queryStr}`;

    }
    window.history.replaceState(null, null, newUrl);

  }

  const getQueryStringObj = () => {
    return Object.fromEntries(new URLSearchParams(window.location.search));
  }

  return {setQueryStringToHistory, getQueryStringObj};
}


export const getLoginFormData = ({email, password, pin = null, accessToken = null}) => {

  // console.log("getLoginFormData", email, password)
  const data = {
    grant_type: "password",
    client_id: '8b38cFmyzu3p1CQYAtfssBrUUMZdPmhPf4SnVjuE',
    client_secret: 'pbJcE7IXhU1i0nqeRcoLLtqj8irm9KA11wqSZ7LWpJlM0pkGmq08Ocqu5vw10Y26AeDzoKfwbXgrPBAfUlIQLoBTJ2T1hgUNwqtBXfZfK7XbeHio1a1UYX3bnupk8Sld',
  };
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  if (pin) {
    formData.append("pin", Buffer.from(pin).toString('Base64'));
  }
  if (accessToken) {
    formData.append("access_token", accessToken);
  } else {
    formData.append("username", email);
    formData.append("password", Buffer.from(password).toString('Base64'));
  }

  return formData
};


export const getProductTagColors = (product) => {
  if (product === 'CONSOLE') {
    return '#06BB8A'
  } else if (product === 'VODA') {
    return '#038FDE'
  } else if (product === 'VIEWER') {
    return '#FA8C16'
  } else if (product === 'PMS') {
    return '#EB2F96'
  } else if (product === 'ALL') {
    return 'volcano'
  } else {
    return 'gold'
  }
}

export const GROUP_PRODUCT = {
  "CONSOLE": ["Green X", "Green X 18", "VCaptureSW", "VCaptureSW_S", "vatech S21"],
  "VIEWER": ["Ez3D-i", "EzDent-i"],
  "PMS": ["weClever"],
  "ALL": [""],
  "VODA": ["Vatech Online Diagnosis Agent"],
}

export const getGroupFromApp = (app) => {
  if (GROUP_PRODUCT.VODA.includes(app)) {
    return {color: getProductTagColors("VODA"), group: "VODA"};
  } else if (GROUP_PRODUCT.CONSOLE.includes(app)) {
    return {color: getProductTagColors("CONSOLE"), group: "CONSOLE"};
  } else if (GROUP_PRODUCT.VIEWER.includes(app)) {
    return {color: getProductTagColors("VIEWER"), group: "VIEWER"};
  } else if (GROUP_PRODUCT.PMS.includes(app)) {
    return {color: getProductTagColors("PMS"), group: "PMS"};
  } else {
    return {color: getProductTagColors(""), group: ""};
  }
}


export const PRODUCT_GROUP = {}
export const makeCreatorString = (data) => {
  return data && data.account && data.account + "(" + data.nickname + " " + data.job_title + "/" + data.dept + ")" || "";
}

export const makeCreatorString2 = (data) => {
  return data && data.nickname && data.nickname + " ( " + data.job_title + "·" + data.dept + " )" || "";
}

export const makeManagerString = (data) => {
  return data && data.name && data.name + " " + data.job_title + (data.dept && " (" + data.dept + ")") || "";
}

export const getPreciseStringValue = value => {
  // return value + '';
  return Math.round(value * 10000.0) * 0.0001 + '';
};

export const DATE_FORMAT = "YYYY-MM-DD";

export const convertDateFormat = (t, type) => {
  if (!t) {
    return ""
  }
  if (type === "withTime") {
    return dayjs(new Date(t)).format("YYYY-MM-DD HH:mm:ss")
  } else {
    return dayjs(new Date(t)).format("YYYY-MM-DD")
  }

}
export const convertTimeFormat = (t, type) => {
  if (!t) {
    return ""
  }

  return dayjs(t, ['h:m a', 'H:m']).format("HH:mm")
}
export const getAccessibleDate = (s, e) => {
  const data = [];
  if (s) {
    data.push(<Tag>{`${convertDateFormat(s)} `}</Tag>)
  }
  if (e) {
    // data.push(<span><Tag>{`${convertDateFormat(e, 'withTime')}`}</Tag></span>)
    data.push(<Tag style={{borderWidth: 0, backgroundColor: 'transparent'}}>{'~'}</Tag>)
    data.push(<Tag>{`${convertDateFormat(e)}`}</Tag>)
  }
  return data
}
export const getAccessibleTime = (s, e) => {
  const data = [];
  if (s) {
    data.push(<Tag>{`${convertTimeFormat(s)} `}</Tag>)
  }
  if (e) {
    // data.push(<span><Tag>{`${convertDateFormat(e, 'withTime')}`}</Tag></span>)
    data.push(<Tag style={{borderWidth: 0, backgroundColor: 'transparent'}}>{'~'}</Tag>)
    data.push(<Tag>{`${convertTimeFormat(e)}`}</Tag>)
  }
  return data
}

export const formatAddress = (data) => {
  return data && "(" + data.zipcode + ") " + data.address + " " + data.detail || "";

}

export const convertStatusToTag = (value) => {
  let name;
  if (value === "사용중") {
    name = "use";
  } else if (value === "계약종료") {
    name = "end";
  } else if (value === "보류중") {
    name = "hold";
  } else if (value === "준비중") {
    name = "prepare";
  }

  return <span className={`hy-tag hy-tag-${name}`}>{value}</span>
}

export const coloringEnableService = (value) => {
  return value ? <span style={{color: "#3557c9"}}>적용</span> : <span>미적용</span>
}

export const coloringEnableService2 = (value) => {
  return value === "적용" ? <span style={{color: "#3557c9"}}>적용</span> : <span>미적용</span>
}
export const convertToFormPlans = (plans) => {
  let tmp = plans.reduce((acc, cur, idx) => {
    if (!acc.hasOwnProperty(cur.time_range)) {
      acc[cur.time_range] = [];
    }
    acc[cur.time_range].push(cur.day);
    return acc;
  }, {});

  let result = [];
  Object.entries(tmp).forEach(([key, value]) => {
    result.push({
      days: value,
      time_range: [
        dayjs(key.split(",")[0], "HH:mm"),
        dayjs(key.split(",")[1], "HH:mm"),
      ],
    });
  });
  return result;
};
export const uploadFiles = async (fileList) => {
  const promiseArray = fileList.map((file) => {
    const formData = new FormData();
    if ("originFileObj" in file) {
      formData.append("file", file.originFileObj);
      formData.append("name", file.name);
    } else {
      formData.append("file", file);
      formData.append("name", file.name);

    }

    return new Promise(async (resolve, reject) => {
      try {
        const res = await httpClient.post("v1/bm/file/", formData, {headers: {'Content-Type': 'application/form-data'}})
        resolve(res.data)
      } catch (e) {
        reject(e)
      }
    })
  })

  try {
    const retVals = await Promise.all(promiseArray)
    return Promise.resolve(retVals)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const fileDownLoad = async (fileObj) => {
  console.log("fileObj :", fileObj)
  try {
    const resp = await httpClient.get(`v1/op/file/${fileObj.id}`);
    console.log("file : ", resp.data.file)
    //const resp2 = await httpClient.get(resp.data.file, {responseType:"blob"});
    const element = document.createElement('a');
    element.setAttribute('href', resp.data.file);
    element.setAttribute('target', "_blank");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element)
    return Promise.resolve()
  } catch (e) {
    return Promise.reject(e)
  }
}

export const getSysInfo = async () => {
  try {
    const resp = await httpClient.get(`v1/helper/sysinfo`);
    return Promise.resolve(resp.data)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const getTenants = async () => {
  try {
    const resp = await httpClient.get(`v1/tm/tenants`);
    const newData = resp.data?.map(item => {
      return {id: item.id, name: item.name}
    }) || [];
    return Promise.resolve(newData)
  } catch (e) {
    return Promise.reject(e)
  }

}

export const getManagers = async (id) => {
  try {
    const resp = await httpClient.get(`v1/sm/accounts?company_in=${id}`);
    console.log("resp.data : ", resp.data, resp)
    return Promise.resolve(resp.data)
  } catch (e) {
    return Promise.reject(e)
  }

}


export const useFetchInitData = () => {
  const [loading, setLoading] = useState(true);
  const [teachers, setTeachers] = useState([]);
  const [members, setMembers] = useState([]);
  const [students, setStudents] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        // const {post_systems, recruitment_fields, depts } = await getSysInfo();
        // const custormers = await getTenants();
        // setPostSystems(post_systems);
        // setRecruitmentFields(recruitment_fields.map(item => item.name));
        // setDepts(depts.map(item=>item.name));
        // setCustomerList(custormers)
        const respTeachers = await beApis.getTeachers();
        const respMembers = await beApis.getMembers();
        const respStudents = await beApis.getStudents();
        setTeachers(respTeachers);
        setMembers(respMembers);
        setStudents(respStudents);
        setLoading(false)

      } catch (e) {
        setError(e)
        setLoading(false)
      }
    }
    getData();
  }, []);

  return {loading, teachers, members, students, error};
}


export const checkPIN = (pw) => {
  if (!pw)
    return {
      result: true
    }

  const dup = pw.search(/([0-9a-zA-Z`~!@#$%^&*()_+|<>?:{}₩₩₩'₩";:₩/?])\1{1,}/);

  // console.log("SWS-DEBUG-CHECK-PASSWORD", pw, id, num, eng, spe, dup)

  if (dup >= 0) {
    return {
      result: false,
      msg: "동일문자를 2회 이상 연속으로 사용할 수 없습니다."
    }
  } else {

    return {
      msg: "",
      result: true
    }
  }

}

export const checkPassword = (pw, id) => {
  if (!pw)
    return {
      result: true
    }

  const num = pw.search(/[0-9]/g);
  const eng = pw.search(/[a-zA-Z]/ig);
  const spe = pw.search(/[`~!@#$%^&*()_+|<>?:{}₩₩₩'₩";:₩/?]/gi);
  const dup = pw.search(/([0-9a-zA-Z`~!@#$%^&*()_+|<>?:{}₩₩₩'₩";:₩/?])\1{1,}/);

  // console.log("SWS-DEBUG-CHECK-PASSWORD", pw, id, num, eng, spe, dup)

  if (pw.length < 8 || pw.length > 12) {

    return {
      result: false,
      msg: "8자리 ~ 12자리 이내로 입력해주세요."
    }
  } else if (pw.search(/\s/) !== -1) {

    return {
      result: false,
      msg: "비밀번호 사이에 공백을 사용할 수 없습니다."
    }
  } else if (num < 0 || eng < 0 || spe < 0) {
    return {
      result: false,
      msg: "영문, 숫자, 특수문자를 조합하여 입력해주세요."
    }
  } else if (dup >= 0) {
    return {
      result: false,
      msg: "동일문자를 2회 이상 연속으로 사용할 수 없습니다."
    }
  } else if (pw === id) {
    return {
      result: false,
      msg: "아이디와 동일한 비밀번호를 사용할 수 없습니다."
    }
  } else {

    return {
      msg: "",
      result: true
    }
  }

}

export const sendDC = (dc, msg) => {
  dc && dc.readyState === 'open' && dc.send(JSON.stringify(msg));
}

export const useMenus = () => {
  const intl = useIntl();

  const gnbMenus = useMemo(() => [
      {
        key: 'home',
        label: intl.formatMessage({id: "vlink.common.gnb.home"}),
        lnb: []
      },
      // {
      //   key: 'remote-pc-management',
      //   label: intl.formatMessage({id: "vlink.common.gnb.remotePCManagement"}),
      //   lnb: []
      // },
      // {
      //   key: 'favorite',
      //   label: intl.formatMessage({id: "vlink.common.gnb.favorite"}),
      //   lnb: []
      // },
      // {
      //   key: 'recently-visit',
      //   label: intl.formatMessage({id: "vlink.common.gnb.recentlyVisit"}),
      //   lnb: []
      // },
      {
        key: 'settings',
        label: intl.formatMessage({id: "vlink.common.gnb.settings"}),
        lnb: []
      },
    ]
    , [intl]);

  const lnbMenus = useMemo(() =>
      gnbMenus.map((j) => j.lnb)
    , [gnbMenus]);

  return {gnbMenus, lnbMenus};
}
export const getNameColor = (name) => {
  const c = [
    '#F9C135',
    '#D5285C',
    '#98C637',
    '#3248BC',
    '#992A8F',
    '#42B677',
    '#F77314'
  ]
  const n = Math.abs(hashCode(name) % c.length)

  return c[n]
};

export const hashCode = (s) => {
  let hash = 0, i, chr, len;
  if (s.length === 0) return hash;
  for (i = 0, len = s.length; i < len; i++) {
    chr   = s.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export const getConstantLayoutAdd = () => {
  return '{{ADD LAYOUT}}'
}


export const appCustomName = (title) => {
  let custom = title

  if (!custom) {
    return ''
  }

  if (title.includes('Chrome')) {
    if (title.includes('e.cooDentist')) {
      custom = 'weClever'
    } else if (title.includes('e.cooDentist')) {
      custom = 'weClever'
    } else if (title.includes("Page d'authentification")) {
      custom = 'weClever'
    } else if (title.includes('app.weclever-detal.com')) {
      custom = 'weClever'
    } else if (title.includes('Bordereau de remise')) {
      custom = 'weClever'
    }
  } else if (title.includes('VCaptureSW')) {
    custom = 'vatech S21'
  } else if (title.includes('Vatech Online Diagnosis Agent')) {
    custom = 'VODA'
  } else if (title.includes('Clever ONE')) {
    custom = 'Clever ONE'
  } else if (title.includes('EzDent-i')) {
    custom = 'EzDent-i'
  }


  return custom
}

export const SESSION_OPTIONS = [
  { value: 300, label: <IntlMessages id="vlink.settings.security.300" /> },
  { value: 600, label: <IntlMessages id="vlink.settings.security.600" /> },
  { value: 900, label: <IntlMessages id="vlink.settings.security.900"  /> },
  { value: 1800, label: <IntlMessages id="vlink.settings.security.1800"  /> },
  { value: 3600, label: <IntlMessages id="vlink.settings.security.3600"  /> }
  ];
