import React, { useEffect, useState, useRef } from "react";
// import { Route, Switch } from "react-router-dom";
import {Layout, Modal} from "antd";
import { Route, Routes } from "react-router-dom";
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer'
import { useDispatch, useSelector } from "react-redux";


import packageJson from '../../package.json';
import config from "../constants/Config";
import { useAuth } from "src/authentication";
import { usePopup } from 'src/util/use-popup';
import IntlMessages from "src/util/IntlMessages";
import Topbar from "src/containers/Topbar";
import AppSidebar from "src/containers/App/AppSidebar";

import {
  updateActiveAgents,
  updateAgents,
  updateAgentSelected,
  updateDeviceAccessToken,
  updateForceLoading,
  updateLayouts,
  updatePublicIP,
  updateSubUserList,
  updateWsSend
} from "src/appRedux/actions";

import Home from "./Home";
import Stream from "./Stream";
import SelectUser from "./userAuth/SelectUser";
import Settings from "./Settings";

const {Content} = Layout;

// const App = ({ match }) => {
const App = () => {
  const { authUser, userSignOut } = useAuth();
  const { infoPopup } = usePopup();
  const dispatch = useDispatch();

  const [logoffTime, setLogoffTime] = useState(3600 * 1000);

  const wsReceive = useSelector(({ ws }) => ws.wsReceive);
  const agents = useSelector(({ cs }) => cs.agents)
  const activeAgents = useSelector(({ cs }) => cs.activeAgents)
  const prevWs = useRef(undefined);


  // const matchAction = useMatch("/:gnb/:lnb/*");

  // const gnbName = matchAction.params.gnb;
  // const lnbName = matchAction.params.lnb;
  useEffect(() => {
    config.package.version = packageJson.version
    config.package.name = packageJson.name
  }, []);

  useEffect(() => {
    // console.log("authUserChanged", authUser?.settings?.auto_logoff_limit_time)
    if (authUser?.settings && authUser.settings.use_auto_logoff && authUser.settings.auto_logoff_limit_time) {
      setLogoffTime(authUser.settings.auto_logoff_limit_time * 1000);
    }
  }, [authUser])

  useEffect(() => {

    if (prevWs.current !== wsReceive) {
      console.log('wsReceive', wsReceive)
      if (wsReceive.service === 'TellYou') {
        processActiveAgents(wsReceive.device_access_token, wsReceive)
      }
    }
    prevWs.current = wsReceive;

  }, [wsReceive]);

  useEffect(() => {
    if (agents && agents.length) {
      dispatch(updateWsSend({
        data: {
          category: 'ws',
          service: 'TellMe',
        },
      }));
    }
  }, [agents])

  const processActiveAgents = (dat, data) => {

    let newData = {}
    if (data) {
      const now = new Date().getTime()
      newData = {
        ...data,
        updated_at: now
      }
    }
    else {
      newData = undefined;
    }

    dispatch(updateActiveAgents({ ...activeAgents, [dat]: {...newData}}))
  }

  const handleOnIdle = () => {
    if (authUser?.settings?.use_auto_logoff) {
      // console.log("!!!!!!!!!!!!!idle", authUser.settings.use_auto_logoff)
      // infoPopup(IntlMessages({id: "vlink.common.saved"}), () => {
      userSignOut()
    }
  }

  return  <Layout style={{
    flex: 1,
    // 최소 넓이 지정
    minWidth: '500px',
  }}>
    <Topbar />
    <Content style={{
      display: "flex", flexDirection: "column", flexWrap: "nowrap"
    }}>

      <div style={{
        // flex: "1",
        backgroundColor: "#EFEFEF",
        height: "calc(100%)",
        display: "flex",
        flexDirection: "column",
        //   padding: 40,
      }}>
        <IdleTimerProvider
          timeout={logoffTime}
          onIdle={handleOnIdle}
          debounce={250}
        >
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/home/stream" element={<Stream />} />
            <Route path="/user/select-user" element={<SelectUser />} />
            <Route path="/settings" element={<Settings />} />
            {/* <Route path="*" element={asyncComponent(() => import('./' + gnbName + '/' + lnbName))}/> */}
          </Routes>
        </IdleTimerProvider>
      </div>

      {/* <App match={match}/> */}
      {/* <Footer>
        <div>
          {footerText}
        </div>
      </Footer> */}
    </Content>
  </Layout>





}


export default App;
