import { combineReducers } from 'redux'
// import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Common from "./Common";
import CS from "./CS";
import WS from "./WS";
import WebRTC from "./WebRTC";

const createRootReducer = () => combineReducers({
  // router: connectRouter(history),
  settings: Settings,
  common: Common,
  cs: CS,
  ws: WS,
  webrtc: WebRTC
});

export default createRootReducer;
