import React from 'react';
import {Card, List, Typography} from "antd";
import StyledFirstCircle from "../StyledFirstCircle";
import './index.css';
import IntlMessages from "../../util/IntlMessages";
import OutlineButton from "../OutlineButton";
import StyledHostTag from "../StyledHostTag";

const {Text} = Typography;

const StyledUserCardItem = (props) => {

  const {item} = props

  return (
    <List.Item className={'user-card-item'}>
      <Card
        hoverable
        bordered
        style={{
          // border: "1px solid #DCDCDC",
          borderRadius: 4,
          borderColor: item.is_host ? '#DF043A' : null,
          height: 74,
          margin: 5,
          padding: 10,
          backgroundColor: '#F9F9F9',
        }}>

        {/* 동그라미 한글자 이름 */}

        <StyledFirstCircle text={item.name} size={36}/>

        {/* 이름/병원 이름 */}

        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 10,
        }}>

          {/* 이름 */}

          <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}>
            <div style={{}}>
              <Text>
                {item.name}
              </Text>
              <StyledHostTag isHost={item.is_host}/>
            </div>
          </div>

          {/* 병원 이름 */}

          <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}>
            <div>
              <Text style={{
                fontSize: 12,
                color: '#7E7E7E',
              }}>
                {'TO-DO: Clinic name'}
              </Text>
            </div>
          </div>
        </div>
        {!props.hideConnect &&
        <div style={{
          display: 'flex',
          alignItems: "center",
        }}>
          <OutlineButton
            label={<IntlMessages id="vlink.common.shortConnect"/>}
            onClick={() => {
              props.onClick && props.onClick(item)
            }}
          />
        </div>
        }
        {item.is_host ?
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '4px',
          height: '100%',
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
          backgroundColor: '#DF043A'
        }} /> : null }
      </Card>
    </List.Item>
  );
};

export default StyledUserCardItem;
