import React, { useEffect, useState, useRef } from 'react';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "antd";
import { PinInput } from 'react-input-pin-code';
import { useIntl } from "react-intl";

import AtomicPopup from "src/components/AtomicPopup";


import IntlMessages from "src/util/IntlMessages";


const DetailPopup = ({ titleId, content, onCancel }) => {

  return <AtomicPopup
    title={
      <span>
        <span>
          <IntlMessages id={titleId} />
        </span>
      </span>
    }
    open={true}

    onCancel={() => {
      onCancel && onCancel()
    }}
    centered
    footer={<div style={{
      height: 24,
    }}></div>}
    width={710}
  >
    <div style={{ display: "flex", height: 450, overflow: "auto"}} >
      <div dangerouslySetInnerHTML={{ __html: content }} style={{padding: 20}} />
    </div>

  </AtomicPopup>
}

export default DetailPopup;