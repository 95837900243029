import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from "../../authentication";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress3 from "../../components/CircularProgress3";
import {
  updateActiveAgents,
  updateAgents,
  updateAgentSelected,
  updateDeviceAccessToken,
  updateLayouts,
  updatePublicIP,
  updateSubUserList,
  updateWsSend
} from "../../appRedux/actions";
import { v4 } from "uuid";
import beApis from "src/util/apis/be";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import AtomicPopup from "../../components/AtomicPopup";
import IntlMessages from "../../util/IntlMessages";
import { Input, List, Button, Menu, Avatar, Space, Card, Switch, Select, TimePicker, Table, Flex, Tag } from "antd";
import StyledFirstCircle from 'src/components/StyledFirstCircle';
import config from "constants/Config";
import Information from "src/assets/svg/Information";
import { convertDateFormat, getProductTagColors, SESSION_OPTIONS } from 'src/util/common';
import PcOn from "assets/svg/PcOn";
import PcOff from "assets/svg/PcOff";

import './index.css';
import RemoteAgentPopup from './RemoteAgentPopup';


const AccountSettings = () => {
  const { authUser, userSignOut } = useAuth();
  const intl = useIntl();
  const csSelected = useSelector(({ cs }) => cs.csSelected);
  const activeAgents = useSelector(({ cs }) => cs.activeAgents);

  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(false);
  const [hostUser, setHostUser] = useState();
  const [permissionList, setPermissionList] = useState([]);

  useEffect(() => {

    getData();

  }, [authUser, activeAgents]);

  // useEffect(() => {
  //   if (hostUser) {
  //     setPermissionList(hostUser.product_permissions.sort())
  //   }
  // }, [hostUser])

  const getData = () => {
    beApis.GetAgents(csSelected.host, true).then(resp => {

      setData(resp.map((item) => {
        const status = activeAgents && activeAgents.hasOwnProperty(item.device_access_token)? true: false;
        return {
          status,
          ...item
        }
      }))
    }).catch((e) => console.log("GetAgents error", e))
  }

  const AgentStatusComponent = ({icon, text}) => (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      marginRight: 16,
    }}>
      {icon}
      <span style={{margin: '0 4px'}}>
        <IntlMessages id={text}/>
      </span>
    </div>
  )


  const columns = [
    {
      title: intl.formatMessage({ id: 'vlink.settings.remoteAgent.agentName' }),
      dataIndex: 'custom_name',
      key: 'custom_name',
      render: (t, r, i) => <span>{r.custom_name || r.name}</span>
    },
    {
      title: intl.formatMessage({ id: 'vlink.settings.remoteAgent.status' }),
      dataIndex: 'status',
      key: 'status',
      render: (t) => t? <AgentStatusComponent icon={<PcOn/>} text='common.pc.on'/>
        : <AgentStatusComponent icon={<PcOff/>} text='common.pc.off'/>
    },
    {
      title: intl.formatMessage({ id: 'vlink.settings.remoteAgent.connectTime' }),
      dataIndex: ['settings', 'use_accessible_time'],
      key: 'connectTime',
      render: (t, r) => {
        if (t) {
          return <span>{r.settings?.accessible_start_time} - {r.settings?.accessible_end_time}</span>
        }
        else {
          return intl.formatMessage({ id: "vlink.settings.security.unset" })
        }
      }
    },
    {
      title: intl.formatMessage({ id: 'vlink.settings.remoteAgent.sessionTime' }),
      dataIndex: ['settings', 'session_expired_time'],
      key: 'sessionTime',
      render: (t, r) => {
        if (t) {
          return SESSION_OPTIONS.find((item) => item.value == t).label
        }
        else {
          return intl.formatMessage({ id: "vlink.settings.security.unset" })
        }
      }
    },
    {
      title: intl.formatMessage({ id: 'vlink.settings.remoteAgent.lastLogin' }),
      dataIndex: 'last_login',
      key: 'last_login',
      render: (t) => convertDateFormat(t, "withTime")
    },
    {
      title: intl.formatMessage({ id: 'vlink.settings.account.config' }),
      key: 'config',
      render: (t, r, i) => <Button onClick={() => setSelectedUser(r)}><IntlMessages id="vlink.settings.account.config" /></Button>
    },
  ]

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20, height: "100%" }}>

      <Card title={<Flex justify='space-between' align="center">
        <IntlMessages id="vlink.settings.remoteAgent.setting" />
        {/* <Button onClick={() => setSelectedUser({})}><IntlMessages id="vlink.settings.account.add" /></Button> */}
      </Flex>} className="settings-card">
        <Table
          className={'user-list'}
          columns={columns}
          dataSource={data}
          pagination={{ position: ['bottomCenter'] }}
          rowKey="device_access_token"
          // locale={{
          //   emptyText: (
          //     <CircularProgress3 />
          //   )
          // }}
        />
      </Card>
      {selectedUser &&
        <RemoteAgentPopup selectedUser={selectedUser}
          onOk={() => {setSelectedUser(false); getData();}}
          onCancel={() => setSelectedUser(false)} />
      }
    </div>
  )

}

export default AccountSettings;
