import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, Checkbox, Card, Flex, Form, Input, Divider, Modal, Row } from "antd";
import { useIntl } from "react-intl";
import { Buffer } from 'buffer';

import IntlMessages from "src/util/IntlMessages";
import { useAuth } from "src/authentication";
import CircularProgress from "src/components/CircularProgress";
import beApis from "../../../util/apis/be";
import CircularProgress2 from "src/components/CircularProgress2";
import { usePopup } from "src/util/use-popup";

import "./index.css";
import { RightOutlined } from "@ant-design/icons";
import DetailPopup from "./DetailPopup";

const { info, error } = Modal;

const Step1 = (props) => {
  const locale = useSelector(({ settings }) => settings.locale);

  const [focus, setFocus] = useState(false);

  const [terms, setTerms] = useState({});
  const [privacy, setPrivacy] = useState({});

  const [agreeStatus, setAgreeStatus] = useState({all: false, terms: false, privacy: false});
  const [isLoading, setIsLoading] = useState(false);

  const [detailData, setDetailData] = useState(false);

  const csSelected = useSelector(({ cs }) => cs.csSelected);
  const navigate = useNavigate();

  const location = useLocation();
  const param = useParams();

  useEffect(() => {
    setFocus(true);
  }, [])

  useEffect(() => {
    if (focus) {

      getTerms();
      getPrivacy();

    }
  }, [focus])

  const getTerms = () => {
    beApis.getTerms(csSelected.host, { category: ["서비스"], lang: [locale.locale] }).then((resp) => {
      setTerms(resp[0]);
    }).catch((e) => {
      console.log("catch error", e.response)
    })
  }

  const getPrivacy = () => {
    beApis.getPrivacy(csSelected.host, { category: ["서비스"], lang: [locale.locale] }).then((resp) => {
      setPrivacy(resp[0]);
    }).catch((e) => {
      console.log("catch error", e.response)
    })
  }

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      setAgreeStatus({
        all: true,
        terms: true,
        privacy: true
      })
    } else {
     setAgreeStatus({
        all: false,
        terms: false,
        privacy: false
      })
    }
  }

  const handleCheck = (e, name) => {

    const temp = {
      ...agreeStatus, 
      [name]: e.target.checked
    }
    temp.all = temp.privacy && temp.terms;

    setAgreeStatus(temp);
  }

  const handleOk = () => {
    props.onOk(terms.id, privacy.id)
  }

  return (
      <div className="gx-app-login-content" style={{ width: "80%" }}>
        <div style={{ height: 380, display: "flex", flexDirection: "column" }}>

          <Checkbox style={{ marginTop: 40 }} checked={agreeStatus.all} onChange={handleCheckAll}>
            <span style={{fontWeight: 500}}><IntlMessages id="vlink.signup.allAgree" /></span>
          </Checkbox>

          <Divider />

          <Flex style={{ marginBottom: 20 }} align="center" justify="space-between">
            <Checkbox checked={agreeStatus.terms} onChange={(e) => handleCheck(e, "terms")}>
              <span style={{fontWeight: 500}}><IntlMessages id="vlink.signup.termsAgree" /></span>
              <span style={{ color: "#DF043A", marginLeft: 5 }}><IntlMessages id="vlink.signup.required" /></span>
            </Checkbox>
            <Button onClick={() => setDetailData({title: "vlink.signup.terms", content: terms.content})}><IntlMessages id="vlink.signup.detail"/></Button>
          </Flex>

          <Flex style={{ marginBottom: 20 }} align="center" justify="space-between">
            <Checkbox checked={agreeStatus.privacy} onChange={(e) => handleCheck(e, "privacy")}>
              <span style={{fontWeight: 500}}><IntlMessages id="vlink.signup.privacyAgree" /></span>
            <span style={{ color: "#DF043A", marginLeft: 5 }}><IntlMessages id="vlink.signup.required" /></span>
          </Checkbox>
            <Button onClick={() => setDetailData({title: "vlink.signup.privacy", content: privacy.content})}><IntlMessages id="vlink.signup.detail"/></Button>
          </Flex>
          <p style={{ marginLeft: "24px", fontSize: 16, lineHeight: "24px" }}><IntlMessages id="vlink.signup.privacyAgree.description" /></p>

        </div>

        <Row style={{ gap: "10px" }}>
          <Button className="gx-mb-0"
            disabled={isLoading}
            style={{
              height: 46,
              flex: 1,
              // borderRadius: 4,
              // backgroundColor: '#DF043A',
              fontSize: 18,
              fontWeight: 700,
            }}
            onClick={() => navigate('/user/signin')}
          >

            <IntlMessages id="vlink.button.cancel" />
          </Button>
          <Button type="primary" className="gx-mb-0"
            disabled={isLoading || !agreeStatus.all}
            style={{
              height: 46,
              flex: 1,
            //   borderRadius: 4,
            //   backgroundColor: '#DF043A',
              fontSize: 18,
              fontWeight: 700,
            }}
            onClick={handleOk}
          >

            {!isLoading ? <IntlMessages id="vlink.signup.next" /> : <CircularProgress2 />}
          </Button>
        </Row>
        {detailData && <DetailPopup titleId={detailData.title} content={detailData.content} onCancel={() => setDetailData(false)}/>}
      </div>
  );
};

export default Step1;
