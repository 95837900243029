import {
  WEBRTC_AGENT_APPS,
  WEBRTC_AGENT_CONNECTIONS,
  WEBRTC_AGENTS,
  WEBRTC_DATA_CHANNELS,
  WEBRTC_DATA_MESSAGES,
  WEBRTC_SUBSCRIBE_AGENT_APPS,
  WEBRTC_SUBSCRIBE_DATA_CHANNELS,
  WEBRTC_PERMISSION_RESULT, WEBRTC_RESOLUTION, WEBRTC_SWITCH_APP,
} from "../../constants/ActionTypes";


export function updateDataChannels(dataChannels) {
  return (dispatch) => {
    dispatch({type: WEBRTC_DATA_CHANNELS, dataChannels});
  }
}

export function updateDataMessages(dataMessages) {
  return (dispatch) => {
    dispatch({type: WEBRTC_DATA_MESSAGES, dataMessages});
  }
}

export function updateWebRTCAgents(agents) {
  return (dispatch) => {
    dispatch({type: WEBRTC_AGENTS, agents});
  }
}

export function updateAgentApps(agentApps) {
  return (dispatch) => {
    dispatch({type: WEBRTC_AGENT_APPS, agentApps});
  }
}

export function updateAgentConnections(agentConnections) {
  return (dispatch) => {
    dispatch({type: WEBRTC_AGENT_CONNECTIONS, agentConnections});
  }
}


export function updateSubscribeAgentApps(subscribeAgentApps) {
  return (dispatch) => {
    dispatch({type: WEBRTC_SUBSCRIBE_AGENT_APPS, subscribeAgentApps});
  }
}

export function updateSubscribeDataChannels(subscribeDataChannels) {
  return (dispatch) => {
    dispatch({type: WEBRTC_SUBSCRIBE_DATA_CHANNELS, subscribeDataChannels});
  }
}

export function updatePermissionResult(permissionResult) {
  return (dispatch) => {
    dispatch({type: WEBRTC_PERMISSION_RESULT, permissionResult})
  }
}

export function updateResolution(resolution) {
  return (dispatch) => {
    dispatch({type: WEBRTC_RESOLUTION, resolution})
  }
}

export function updateSwitchApp(switchApp) {
  return (dispatch) => {
    dispatch({type: WEBRTC_SWITCH_APP, switchApp})
  }
}
