import { httpClient } from "../Api";

import { makeCreatorString, convertDateFormat } from "../common";

class CSApis {

  async Service(url, values) {
    try {
      const token = httpClient.defaults.headers.common['Authorization']?.split('Bearer ')[1]

      const resp = await fetch(url, {
        method: 'post',
        headers: {'Content-type': 'application/json'},
        body: JSON.stringify({
          data: {
            ...values,
            access_token: token,
          }
        })
      }).then(
          resp => resp.json()
      )

      const data = resp || {};

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  // async RegisterToken(baseUrl, values) {
  //   const {Service} = this;
  //   try {
  //     const resp = await Service(`${baseUrl}/api/public/RegisterToken`, values)
  //     const data = resp.data || {};
  //
  //     return Promise.resolve(data)
  //   } catch (e) {
  //     return Promise.reject(e)
  //   }
  // }
  async GetSystemInformation(baseUrl, values) {
    const {Service} = this;
    try {
      const resp = await Service(`${baseUrl}/api/public/GetSystemInformation`, values)
      const data = resp.data || {};

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async GetSlaveCS(baseUrl) {
    const {Service} = this;
    try {
      const resp = await Service(`${baseUrl}/api/public/GetSlaveCS`, {})
      const data = resp.data || {};

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async Candidate(baseUrl) {
    const {Service} = this;
    try {
      const resp = await Service(`${baseUrl}/api/public/Candidate`, {})
      const data = resp.data || {};

      return Promise.resolve(data)
    } catch (e) {
      return Promise.resolve({ rejected: true })
      // return Promise.reject(e)
    }
  }




  async UpdateSubUser(baseUrl, id, values) {
    try {
      const resp = await httpClient.patch(
        `/v1/cs/sub-users/${id}`,
        values,
        {
          baseURL: baseUrl,
        },
      )
      const data = resp.data || []

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  
  async UpdatePin(baseUrl, values) {
    try {
      const resp = await httpClient.patch(
        `/v1/cs/pin/`,
        values,
        {
          baseURL: baseUrl,
        },
      )
      const data = resp.data || []

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }




  // async GetNodes(baseUrl, values) {
  //   const {Service} = this;
  //   try {
  //     const resp = await Service(`${baseUrl}/api/private/GetNodes`, values)
  //     const data = resp.data || {};
  //
  //     return Promise.resolve(data)
  //   } catch (e) {
  //     return Promise.reject(e)
  //   }
  // }
  // async GetHostAgentAll(baseUrl, values) {
  //   const {Service} = this;
  //   try {
  //     const resp = await Service(`${baseUrl}/api/private/GetHostAgentAll`, values)
  //     const data = resp.data || {};
  //
  //     return Promise.resolve(data)
  //   } catch (e) {
  //     return Promise.reject(e)
  //   }
  // }
  // async UpdatePcName(baseUrl, values) {
  //   const {Service} = this;
  //
  //   try {
  //     const resp = await Service(`${baseUrl}/api/private/UpdatePcName`, values)
  //     const data = resp.data || {};
  //
  //     return Promise.resolve(data)
  //   } catch (e) {
  //     return Promise.reject(e)
  //   }
  // }
}




const csApis = new CSApis();

export default csApis;
