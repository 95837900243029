import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ConfigProvider } from 'antd';
import { IntlProvider } from "react-intl";

import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";
import SignIn from "src/routes/userAuth/SignIn";
import SignUp from "src/routes/userAuth/SignUp";
import NotFound from "../NotFound";
import { setInitUrl } from "../../appRedux/actions";
import { PopupProvider } from "../../util/use-popup";
import { switchLanguage } from 'src/appRedux/actions';

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting";
import { useAuth } from "../../authentication";
import CircularProgress3 from "../../components/CircularProgress3";
import WSContainer from "../WSContainer";

// const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) =>
//   <Route
//     {...rest}
//     render={props =>
//       // authUser
//       //   ?
//       <Component {...props} />
//       // : <Redirect
//       //   to={{
//       //     pathname: '/signin',
//       //     state: {from: location}
//       //   }}
//       // />
//     }
//   />;

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('full-layout');
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove('full-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('boxed-layout');
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('full-layout');
    document.body.classList.add('framed-layout');
  }
};

const setNavStyle = (navStyle) => {
  if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER) {
    document.body.classList.add('full-scroll');
    document.body.classList.add('horizontal-layout');
  } else {
    document.body.classList.remove('full-scroll');
    document.body.classList.remove('horizontal-layout');
  }
};

const App = () => {
  const locale = useSelector(({ settings }) => settings.locale);
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const layoutType = useSelector(({ settings }) => settings.layoutType);
  const themeType = useSelector(({ settings }) => settings.themeType);
  const isDirectionRTL = useSelector(({ settings }) => settings.isDirectionRTL);
  const initURL = useSelector(({ settings }) => settings.initURL);
  const csSelected = useSelector(({ cs }) => cs.csSelected);

  const { authUser, subUser, isLoadingUser } = useAuth();
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {


  }, [])
  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add('rtl');
      document.documentElement.setAttribute('data-direction', 'rtl')
    } else {
      document.documentElement.classList.remove('rtl');
      document.documentElement.setAttribute('data-direction', 'ltr')
    }
  }, [isDirectionRTL]);

  useEffect(() => {
    if (locale)
      document.documentElement.lang = locale.locale;
  }, [locale]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
    } else if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
    }
  }, [themeType]);

  useEffect(() => {
    // console.log("App/index.js[", initURL, "]")
    // console.log("App/index.js location.pathname[", location.pathname  , "]")
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
  });

  useEffect(() => {
    // console.log("=>App.js isLoadingUser check", initURL, initURL.length, isLoadingUser, authUser)
    // console.log("=>App.js location.pathname[", location.pathname, "]")
    // console.log('DEBUG-078', 'subUser', subUser)
    // console.log('DEBUG-078', 'isLoadingUser', isLoadingUser)
    // console.log('DEBUG-078', 'authUser', authUser)

    if (!isLoadingUser) {
      if (!authUser) {
        if (location.pathname.startsWith("/x/") || location.pathname === '/user/signup/' || location.pathname === '/user/forgot-password/') {
        }
        else {
          navigate('/user/signin');
        }
        // navigate('/home');
      } else {
        if (!subUser) {
          if (location.pathname !== '/home' && location.pathname !== '/user/select-user') {
            navigate('/user/select-user')
          }
        }
        else if (subUser && (["/user/signin", "/user/select-user", "/"].includes(initURL))) {
          dispatch(setInitUrl('/home'));
          navigate('/home');
        }
        else {
          dispatch(setInitUrl(location.pathname));
          navigate(location.pathname)
        }
      }
    }
  }, [isLoadingUser, authUser, subUser]);

  useEffect(() => {

    if (subUser?.settings?.locale) {
      dispatch(switchLanguage({ locale: subUser.settings.locale }));
      localStorage.setItem("locale", subUser.settings.locale)
    }
    else {
      const value = localStorage.getItem("locale");

      if (!subUser && value) {
        dispatch(switchLanguage({ locale: value }));
      }
    }



  }, [subUser]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  const currentAppLocale = AppLocale[locale.locale];

  // console.log("currentAppLocale", currentAppLocale)

  return <Fragment>
    <WSContainer />
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={isDirectionRTL ? 'rtl' : 'ltr'}
      theme={{
        token: {
          fontSize: 16,
          fontFamily: 'Pretendard',
          colorPrimary: '#DF043A',
        },
        components: {
          Button: {
            fontSize: 16
          }
        }
      }}
    >
      <IntlProvider
        onError={(e) => console.log("IntlProvider error", e)}
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <PopupProvider>
          <Routes>
            {/* <Route path='/user/signin' element={<SignIn />} />
            <Route path='/user/signup' element={<SignUp />} /> */}
            <Route path="/*" element={<MainApp />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </PopupProvider>
      </IntlProvider>
    </ConfigProvider>
    {isLoadingUser && <CircularProgress3 />}
  </Fragment>
};

export default App;
