import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={7.99984}
      cy={8.00033}
      r={6.33333}
      fill="#DF043A"
      stroke="#FFCFDC"
      strokeWidth={2}
    />
  </svg>
);
export default SVGComponent;
