import antdJP from "antd/locale/ja_JP";
import enMessages from "../locales/ja_JP.json";

const JaLang = {
  messages: {
    ...enMessages
  },
  antd: antdJP,
  locale: 'ja-JP',
};
export default JaLang;
